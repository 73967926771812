import React, { useCallback, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import styles from 'src/styles/productForm';
import { storeProductFormSchema } from "src/formValidation/formValidation";

const ProductForm = ({ data = {}, errorMessage, inProgress, onSave , errors }) => {
  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  useEffect( () => {
    validationError({ errors });
  }, [errors])

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
            window.mNotify.error('Store Product type error occurs!');
        }
  };


  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
    storeProductFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);

  return (

    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Product Type Name"
              error={!!(validationDataError.errors && validationDataError.errors.productTypeName)}
              helperText={validationDataError.errors ? validationDataError.errors.productTypeName : ''}
              value={formData.productTypeName}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productTypeName: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Product Type Description"
              error={!!(validationDataError.errors && validationDataError.errors.productTypeDescription)}
              helperText={validationDataError.errors ? validationDataError.errors.productTypeDescription : ''}
              value={formData.productTypeDescription}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productTypeDescription: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
              <FormControl component="fieldset" fullWidth style={{ textAlign: 'left' }}>
                  <FormLabel component="legend"  >Status</FormLabel>
                  <RadioGroup row aria-label="position"
                      onChange={(e) => {
                          setFormData({
                              ...formData,
                              isActive: Number(e.target.value),
                          });
                      }}
                      value={formData.isActive}
                      name="isActive" defaultValue="top">
                      <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Active"
                      />
                      <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="Inactive"
                      />
                  </RadioGroup>
                </FormControl>
            </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          {/* <Button */}
          {/*   variant="contained" */}
          {/*   color="secondary" */}
          {/*   style={{ margin: '5px' }} */}
          {/*   onClick={(e) => { */}
          {/*     setFormData({ */}
          {/*       productTypeName: '', */}
          {/*       productTypeDescription: '' */}
          {/*     }); */}
          {/*   }} */}
          {/* > */}
          {/*   Reset */}
          {/* </Button> */}

          <p>{errorMessage}</p>
        </Grid>
      </Grid>
      </form>

  );
}

export default ProductForm;
