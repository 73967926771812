import React from 'react';
import Loader from 'src/components/loader';

import {
  USER_TYPE_OWNER,
  // USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import { GlobalContext } from 'src/GlobalContextProvider';

require('./pages/owner/store/select.scss');

function InitModule(WrappedComponent) {
  class InitModules extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        moduleStatus: false,
        module: {
          tax: {
            inProgress: false,
            modulesRoutesDetails: [],
            modulesMenuDetails: [],
          },
          newVendor: {
            inProgress: false,
            modulesRoutesDetails: [],
            modulesMenuDetails: [],
          },
          purchaseOrder:{
            inProgress: false,
            modulesRoutesDetails: [],
            modulesMenuDetails: [],
          },
          quoteOrder:{
            inProgress: false,
            modulesRoutesDetails: [],
            modulesMenuDetails: [],
          }
        },

      };
    }

    componentDidMount() {
      const contextState = this.context;
      if (contextState.user.type === USER_TYPE_OWNER) {
        this.storeModule();
      } else {
        this.context.setChangeStoreModules({
          modules: {
            storeModules: {},
            storeModulesStatus: true,
          },
        });
      }
    }

    storeModule = async () => {
      const contextState = this.context;
      const data = {}
      Object.keys(this.state.module).map(itm => data[itm] = 1 )

      await this.context.setChangeStoreModules({
          modules: {
            ...contextState.modules,
            storeModulesAccess: {
              ...data
            },
          },
        });
      await this.Module();
    };

    Module = async () => {
      const contextState = this.context;
      const states = this.state;
      let initModuleCom = {}

      if (Object.keys(contextState.modules.storeModulesAccess).length) {

        const appData = Object.keys(contextState.modules.storeModulesAccess).map( async function(val, key) {
            if (states.module[val] && states.module[val].modulesRoutesDetails.length === 0 && states.module[val].modulesMenuDetails.length === 0) {

            let moduleData = await import('src/modules/' + val + '/App')
            if (moduleData.default.name === val) {

              return {
                [val] : {
                  inProgress: true,
                  modulesRoutesDetails: [...moduleData.default.routers],
                  modulesMenuDetails: [...moduleData.default.menu],
                }
              }
            }
          }
        })


       const originalAppData = await Promise.all(appData)

       originalAppData.forEach( (item) => {
        initModuleCom = {
          ...initModuleCom,
          ...item
        }
       })

        this.context.setChangeStoreModules({
          modules: {
            ...contextState.modules,
            storeModules: { ...initModuleCom },
            storeModulesStatus: true,
          },
        });

      } else {
        console.log(contextState.modules)
        alert('tes')
        this.context.setChangeStoreModules({
          modules: {
            ...contextState.modules,
            // storeModules: { ...this.state.module }
            storeModulesStatus: true,
          },
        });
      }
    };

    render() {
      const contextState = this.context;
      return !contextState.modules.storeModulesStatus ? (
        <Loader title="Initializing modules" />
      ) : (
        <WrappedComponent {...this.props} state={this.state} />
      );
    }
  }
  InitModules.contextType = GlobalContext;
  return InitModules;
}

export default InitModule;
