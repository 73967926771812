// import { makeStyles } from '@mui/material/styles';

const styles = () => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: 10,
    flexDirection: 'column',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  prefix: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,

      '& span': {
        display: 'flex',
        textTransform: 'capitalize',
      }
    }
  },
});

export default styles;
