const yup = require('yup');

const loginFormSchema = yup.object().shape({
  password : yup.string().required('Password Required'),
  username : yup.string().required('Email Address or Phone Number Required'),
});

const forgotPasswordFormSchema = yup.object().shape({
  username : yup.string().required('Email Address or Phone Number Required'),
});

const verifyOTPFormSchema = yup.object().shape({
  otp : yup.string().length(6).required('6 digit OTP is required'),
});

const resetpasswordFormSchema = yup.object().shape({
  password : yup.string()
    .required('6 digit OTP is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})/,
    'Must Contain 8 Characters(20 max), One Uppercase, One Lowercase, One Number and One Special Case Character'),
});

module.exports = {
  loginFormSchema,
  forgotPasswordFormSchema,
  verifyOTPFormSchema,
  resetpasswordFormSchema,
};
