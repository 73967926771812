import React, { useCallback,useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import styles from 'src/styles/storeForm';
import { CUT_OFF_TIMES } from 'src/constants/index';


import { storeFormSchema } from "src/formValidation/formValidation";

const StoreForm = ({ data = {}, errorMessage, getSearchRcords, inProgress, onSave , errors , t, countryList, stateList, cityList}) => {

  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  // const [json, setJson] = React.useState({});
  const [validationDataError, validationError] = React.useState({});


  useEffect( () => {
    validationError({ errors });
  }, [errors])

  const validateForm = useCallback((formData) => {

    let errors = {};
    let formIsValid = true;
    try {
      storeFormSchema.validateSync(formData, {
        abortEarly: false,
      });
    } catch (err) {
      err.inner.forEach(element => errors[element.path] = element.message);
      formIsValid = false;
    }
    return [formIsValid, errors];

  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Validation error occurs!');
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              error={!!(validationDataError.errors && validationDataError.errors.ownerOrganization)}
              helperText={validationDataError.errors ? t(validationDataError.errors.ownerOrganization) : ''}
              label={t('storeName')}
              value={formData.ownerOrganization}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  ownerOrganization: e.target.value,
                });
              }}
            />

          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              error={!!(validationDataError.errors && validationDataError.errors.email)}
              helperText={validationDataError.errors ? t(validationDataError.errors.email) : ''}
              id="outlined-basic"
              fullWidth
              label="Email"
              value={formData.email}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
            />

          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              error={!!(validationDataError.errors && validationDataError.errors.contact)}
              helperText={validationDataError.errors ? t(validationDataError.errors.contact) : ''}
              label="Phone"
              value={formData.contact}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contact: e.target.value,
                });
              }}
            />

          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Alternate Phone"
              value={formData.emergencyContact}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  emergencyContact: e.target.value,
                });
              }}
            />
          </div>
        </Grid>


        <Grid item xs={12} sm={2}>
          <div className={classes.paper}>
            <Autocomplete
              // freeSolo
              // disableClearable
              id="free-solo-2-Country-search"

              onKeyUp={(e) => {
                getSearchRcords({
                  type: 'country',
                  search: e.target.value,
                  setState: 'countryList',
                  relation: '_'
                });
              }}
              value={countryList && countryList[formData.country] ? countryList[formData.country].name : formData.country && formData.countryName || ''}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(countryList)[e.target.dataset.optionIndex];

                  setFormData({
                    ...formData,
                    country: obj.iso3,
                    state: '',
                    city: '',
                  });
                } else {
                  setFormData({
                    ...formData,
                    country: '',
                    state: '',
                    city: '',
                  });
                }

              }}
              options={Object.values(countryList).map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search Country"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.country)}
                  helperText={validationDataError.errors ? validationDataError.errors.country : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'Search Country',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div className={classes.paper}>
            <Autocomplete
            disabled={ !formData.country ? true : false}
              // freeSolo
              // disableClearable
              id="free-solo-2-state-search"

              onKeyUp={(e) => {
                getSearchRcords({
                  type: 'state',
                  search: e.target.value,
                  country: formData.country,
                  setState: 'stateList',
                  relation: formData.country
                });
              }}
              value={stateList && stateList[formData.state] ? stateList[formData.state].name : formData.state && formData.stateName || ''}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(stateList)[e.target.dataset.optionIndex];

                  setFormData({
                    ...formData,
                    state: obj.state_code,
                    city: '',
                  });
                } else {
                  setFormData({
                    ...formData,
                    state: '',
                    city: '',
                  });
                }

              }}
              options={Object.values(stateList).map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search State"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.state)}
                  helperText={validationDataError.errors ? validationDataError.errors.state : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'Search State',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div className={classes.paper}>
            <Autocomplete
            disabled={ !formData.country && !formData.state ? true : false}
              // freeSolo
              // disableClearable
              id="free-solo-2-city-search"
              onKeyUp={(e) => {
                getSearchRcords({
                  type: 'city',
                  search: e.target.value,
                  country: formData.country,
                  state: formData.state,
                  setState: 'cityList',
                  relation: `${formData.country}${formData.state}`
                });
              }}
              value={cityList && cityList[formData.city] ? cityList[formData.city].name : formData.city && formData.cityName || ''}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(cityList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    city: obj.id,
                  });
                } else {
                  setFormData({
                    ...formData,
                    city: '',
                  });
                }
              }}
              options={Object.values(cityList).map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search City"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.city)}
                  helperText={validationDataError.errors ? validationDataError.errors.city : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'Search City',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Locality"
              value={formData.locality}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  locality: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Zipcode"
              value={formData.zipcode}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  zipcode: e.target.value,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Bank Information Number"
              value={formData.bankInformation}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  bankInformation: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Cut Off Time</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={formData.cutOffTime}
                error={!!(validationDataError.errors && validationDataError.errors.cutOffTime)}
                helperText={validationDataError.errors ? t(validationDataError.errors.cutOffTime) : ''}
                label="Cut Off Time"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cutOffTime: e.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Choose Cut Off Time</em>
                </MenuItem>
                {
                  CUT_OFF_TIMES.map(tme => (
                    <MenuItem key={tme.value} value={tme.value}>{tme.text}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {
              validationDataError.errors && validationDataError.errors.cutOffTime ?
              <FormHelperText id="my-helper-text" style={{ color: "red" }}>{validationDataError.errors.cutOffTime}</FormHelperText>
              : null
              }
          </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{ margin: "5px" }}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: '5px' }}
            onClick={(e) => {
              setFormData({ ...data });
            }}
          >
            Reset
        </Button>
          <p>{errorMessage}</p>
        </Grid>
      </Grid>
    </form>
  );
}

export default StoreForm;
