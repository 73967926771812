import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'react-moment';
// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

// page Title
const pageTitle = 'Price Master'

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: pageTitle,
  },
];

const DatePrefix = 'DD-MM-YYYY'

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ListPriceMaster extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      priceMaster: [],
    };
  }

  componentDidMount() {
    console.log(this.props.match.params && this.props.match.params.masterProductId)
    this.fetchList();
  }

  fetchList = async () => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.PRODUCT_PRICE_MASTER_LIST(this.props.match.params && this.props.match.params.masterProductId));
      if (res.data.success) {
        this.setState({
          inProgress: false,
          priceMaster: res.data.data,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error(`Error while fetching ${pageTitle}`);
    }

    this.setState({
      inProgress: false,
    });
  }

  deleteandRestoreItem = async (data) => {
    let res;
    let status;
    if (data.isActive) {
      res = await axiosInstance.patch(Apiurls.PRODUCT_PRICE_MASTER_EDIT(this.props.match.params && this.props.match.params.masterProductId, data.id));
      status = "Restore"
    } else {
      res = await axiosInstance.delete(Apiurls.PRODUCT_PRICE_MASTER_EDIT(this.props.match.params && this.props.match.params.masterProductId, data.id));
      status = "Delete"
    }

    if (res.data.success) {
      this.fetchList();
      window.mNotify.show(`${status} the ${pageTitle} successfully.`);
    } else {
      window.mNotify.show(`Sorry!.. Could not ${status} the ${pageTitle}`);
    }
  }

  render() {
    const { inProgress, priceMaster } = this.state;
    const { history, match } = this.props;
    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>{pageTitle}</h2>
        <div className={this.props.classes.titleContainer}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductMasterProduct);
            }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductPriceMasterAdd(match.params && match.params.masterProductId));
            }}
          >
            Create New
          </Button>
        </div>
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  {/* <TableCell>Unit Name</TableCell> */}
                  <TableCell>Selling Price</TableCell>
                  <TableCell>Valid From</TableCell>
                  <TableCell>Valid To</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                priceMaster.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <CheckIcon
                        size={12}
                        style={{
                          display: 'inline-block',
                          color: row.isActive ? 'green' : 'grey',
                        }}
                      />
                      <span>{row.sellingPrice}</span>
                    </TableCell>
                    <TableCell>
                      <Moment format={DatePrefix}>
                        {row.validFrom}
                      </Moment>
                    </TableCell>
                    <TableCell>
                      <Moment format={DatePrefix}>
                        {row.validTo}
                      </Moment>
                    </TableCell>
                    <TableCell>

                    {row.isActive ?
                        <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                          <Button
                            onClick={(e)=>{
                              history.push(Urls.ProductPriceMasterEdit(match.params && match.params.masterProductId, row.id));
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Tooltip title="Block">
                          <Button
                          onClick={(e)=>{
                            if (window.confirm(`Are you sure you want to delete ${row.sellingPrice} ?`)) {
                              this.deleteandRestoreItem({
                                id: row.id,
                                isActive: false,
                              });
                            }
                          }}
                          >
                          <BlockIcon />
                          </Button>
                          </Tooltip>
                        </ButtonGroup>
                        :
                         <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                          <Button
                            onClick={(e)=>{
                              if (window.confirm(`Are you sure you want to Restore ${row.sellingPrice} ?`)) {
                                this.deleteandRestoreItem({
                                  id: row.id,
                                  isActive: true,
                                });
                              }
                            }}
                          >
                            <RestoreIcon />
                          </Button>
                        </ButtonGroup>
                      }
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
          </Table>
          {
            inProgress && <CircularProgress />
          }
        </TableContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListPriceMaster));
