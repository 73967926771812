import React from 'react';
// import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

/**
 *
 * @param {Object} props
 * @param {Function} props.t translate function from react-i18n
 * @param {Object} props.i18n i18n object from react-i18n
 * @returns {JSX.Element}
 */
const LanguageSelector = ({
  t,
  i18n,
}) => (
  <>
    {/* <InputLabel>Language</InputLabel> */}
    <Select
      value={i18n.language}
      label={t('language')}
      placeholder="Language"
      onChange={(e) => {
        i18n.changeLanguage(e.target.value)
    }}>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ta">தமிழ்</MenuItem>
    </Select>
  </>
);

export default LanguageSelector;
