import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import { GlobalContext } from 'src/GlobalContextProvider';
import ProductDashboard from './partials/productDashboard';
import ManageMasterProduct from './manageMasterProduct';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0
  },
  pageHead: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  h2: {
    margin: 0,
    flex: 1,
  }
});

class ManageProductDashboard extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      originalData: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
    }
  }

  render() {
    const { history } = this.props;
    const { isLoadingData } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <div className={this.props.classes.pageHead}>
          <MyBreadCrumbs
            items={breadcrumbItems}
            history={history}
          />
          <h2 className={this.props.classes.h2}>Product Dashboard</h2>
          </div>
          {
            isLoadingData ?
              <CircularProgress color="inherit" size={14} /> :
              <ProductDashboard
                history={history}
              />
          }
          {
            isLoadingData ?
              <CircularProgress color="inherit" size={14} /> :
              <ManageMasterProduct isbreadCrumb={false}/>
          }

      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageProductDashboard));
