import React from 'react';

import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import hierarchyENUM from '../../constants/hierarchyENUM.json';

const HierarchyFields = ({ productHierarchyList, data, onHierarchySetData }) => {

  return (
  <Grid container spacing={2}>
  {
    Object.keys(productHierarchyList).map( (Type, key) => (
      <Grid item xs={12} sm={6}>
      <div >
      <FormControl variant="outlined" style={{ width: '100%', textAlign: 'left'}}>
        <InputLabel id="demo-simple-select-outlined-label">{hierarchyENUM.feildsType[Type].toUpperCase()} </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          fullWidth
          id="demo-simple-select-outlined"
          value={data && data.hierarchy && data.hierarchy[Type] && data.hierarchy[Type].hierarchyId}
          onChange={(e) => {
            onHierarchySetData({
              hierarchy: {
                ...data.hierarchy,
                [Type]: {
                  ...data.hierarchy[Type],
                  hierarchyId: e.target.value
                },
              }
            });
          }}
        >
          {
            productHierarchyList[Type].map((val, key) => (
              <MenuItem key={key} value={val.id}>{val.hierarchyValue}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      </div>
    </Grid>
      ))
  }

  </Grid>
  );
}

export default HierarchyFields;
