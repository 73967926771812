import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import ProductMasterProductForm from './partials/productMasterMap';
import Button from '@mui/material/Button';
const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Add Map Product',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: `1rem 0`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  h2: {
    margin: 0,
    marginBottom: '1rem',
  }
});

const EmptyValue = (props) => {
  return {
    productId: null,
    productType: null,
    barcodeNumber: '',
    productName: '',
    productDescription: '',
    HSNCode: '',
    taxCategory: '',
    ownerPrice: null,
    movingAvgPrice: null,
    unitName: '',
    unitId: null,
    isActive: 1,
    hierarchy: {}
  }
}

class ManageMasterProduct extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...EmptyValue(this.props)
      },
      orginalData: {},
      inProgress: false,
      productTypeList: {},
      productUnitList: {},
      productHierarchyList: {},
      cacheMemoize: {},
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      isbreadCrumb: props.isbreadCrumb === false ? false : true,
      errors : {}
    }
  }

  async componentDidMount() {
    await this.getProductMaterialData();
    await this.getProductItems();
  }

  getProductMaterialData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_MAP_EDIT(this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data, tagId: res.data.data.tagId && JSON.parse(res.data.data.tagId).length ? JSON.parse(res.data.data.tagId) : [] },
          isLoadingData: false,
        });
      }
    }
  }

  getProductItems = async () => {
    this.setState({ isLoadingData: true, inProgress: false });
    const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_MAP_ITEMS_LIST);
    if (res.data.success) {
      this.setState({
        productHierarchyList: res.data.data.productHierarchyGroupByHierarchyType || {},
        isLoadingData: false
      });
    } else {
      window.mNotify.error('Error while fetching items list');
    }
  };

  resetState = () => {
    this.setState({
      formData: {},
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {

        productId: data.productId,
        productType: data.productType,
        productTypeName: data.productTypeName,
        productName: data.productName,
        ownerPrice: data.ownerPrice,
        barcodeNumber : data.barcodeNumber,
        productDescription: data.productDescription,
        HSNCode: data.HSNCode,
        taxCategory: data.taxCategory,
        movingAvgPrice: data.movingAvgPrice,
        unitName: data.unitName,
        unitId: data.unitId,
        isActive: data.isActive,
        hierarchy: data.hierarchy
      };

      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_MASTER_MAP_EDIT(orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_MASTER_MAP, body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.ProductMasterProduct);
      }

      if (!res.data.success && res.data.errors) {
        this.setState({ errors: res.data.errors });
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        return;
      }

      if (res.data.isexisting) {
        window.mNotify.show('Master Product already existing', 'danger');
        // return this.props.history.push(Urls.ProductMasterProduct);
      }

      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });

      window.mNotify.error('Error while creating product master product. Try again.');
    }
  }

  getSearchRcords = async (data) => {
    try {

      if (this.state.cacheMemoize[data.state] && this.state.cacheMemoize[data.state][data.search]) {
        this.setState({
          [data.state]: this.state.cacheMemoize[data.state][data.search] || {},
        });
        return
      }
      let res;
      res = await axiosInstance.post(Apiurls.PRODUCT_MASTER_MAP_SEARCH_ITEMS, data);
      if (res.data.success) {
        this.setState({
          cacheMemoize: {
            ...this.state.cacheMemoize,
            [data.state]: {
              ...this.state.cacheMemoize[data.state],
              [data.search]: res.data.data || {},
            },
          },
          [data.state]: res.data.data || {},
        });
      } else {
        this.setState({
          cacheMemoize: {
            ...this.state.cacheMemoize,
            [data.state]: {
              ...this.state.cacheMemoize[data.state],
              [data.search]: {},
            },
          },
          [data.state]: {},
        });
      }
      return ;
      // throw new Error(res.data.error);
    } catch (error) {
      console.error(error);
      this.setState({ inProgress: false });

      return window.mNotify.show('Error while Fetch . Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData, productTypeList, productUnitList, productHierarchyList, isbreadCrumb ,errors} = this.state;

    return (
      <div className={this.props.classes.paper}>
      <div className={this.props.classes.pageHead}>
        {
          isbreadCrumb ?  <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
          /> : ''
        }
        {
        isbreadCrumb ? <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductMasterProduct);
            }}
            style={{ marginRight: '5px' }}
          >
            View Master Products
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductType);
            }}
          >
            View Products
          </Button>
          </div>
      : ''
      }
        </div>

        <Paper className={this.props.classes.paper}>
          <h2 className={this.props.classes.h2}>{formData.id ? 'Edit' : 'Add'} Master Product</h2>
          {
            isLoadingData ?
              <CircularProgress color="inherit" size={14} />
              :
              <ProductMasterProductForm
                data={formData}
                inProgress={inProgress}
                onSave={this.onSave}
                productUnitList={productUnitList}
                getSearchRcords={this.getSearchRcords}
                productTypeList={productTypeList}
                productHierarchyList={productHierarchyList}
                errors={errors}
              />
          }
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageMasterProduct));
