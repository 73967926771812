import { createTheme as createThemeV5 } from '@mui/material/styles';

/**
 * Root theme for the app.
 */
const theme = createThemeV5({
  typography: {
    // fontFamily: 'Poppins, sans-serif',
    // fontFamily: 'PT Sans, sans-serif',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '13.5',
  },
  overrides: {
    MuiCssBaseline: {},
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: '#0a66c2',
      main: '#0a66c2',
      dark: '#0a66c2',
      contrastText: '#fff',
    },
    secondary: {
      "light": "#064788",
      "main": "#064788",
      "dark": "#064788",
      "contrastText": "#fff",
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

export default theme;
