const yup = require('yup');

const phoneRegexExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const loginFormSchema = yup.object().shape({
  password : yup.string().required('passwordRequired'),
  username : yup.string().required('emailAndPhoneRequired'),
});

const storeFormSchema = yup.object().shape({
  cutOffTime: yup.number().typeError('cutOffTimeMustBeNumber').required('cutOffTimeRequired'),
  contact: yup.string().matches(phoneRegexExp, 'contactNumberRequired'),
  email : yup.string().required('emailAddressRequired').email('emailAddressMustBeValid'),
  ownerOrganization: yup.string().required('storeNameRequired'),
});

const storeProductMapFormSchema = yup.object().shape({
  productName: yup.string().required('Product Name is Required'),
  productTypeName: yup.string().required('Product Type is Required'),
  unitName: yup.string().required('Unit is Required'),
  ownerPrice: yup.number().positive().nullable(true).required('Owner Price is Required').typeError('Owner Price Type Must Be a Number'),
  taxCategory: yup.string().min(0, 'Min is 0').max(2, 'max is 2'),
  HSNCode: yup.string().required('HSN Code is Required').min(2, 'Min is 2').max(10, 'max is 10'),
  barcodeNumber: yup.string().required('Barcode Number is Required')
});

const storeProductFormSchema = yup.object().shape({
  productTypeName: yup.string().required('Product Type is Required'),
});

const storeProductUnitFormSchema = yup.object().shape({
  unitName: yup.string().required('Unit Name is Required'),
});

const storeProductHierarchyFormSchema = yup.object().shape({
  industry: yup.number().positive().nullable(true).required('Industry is Required'),
  hierarchyType: yup.number().positive().nullable(true).required('Hierarchy Type is Required'),
  hierarchyValue: yup.string().required('Hierarchy Value is Required'),
  createdBy: yup.number().positive().nullable(true).required('Created By is Required'),
});

const taxSchema = yup.object().shape({
  country: yup.string().required('Country is Required'),
  taxType: yup.string().min(2, 'Min is 2').max(10, 'max is 10').required('Tax Type Required'),
  taxCategory: yup.string().min(0, 'Min is 2').max(2, 'max is 2').required('Tax Category Required'),
  HSNCode: yup.string().min(2, 'Min is 2').max(10, 'max is 10').required('HSN Code Required'),
});

const customerSchema = yup.object().shape({
  customerType: yup.string().required('Customer Type required'),
  name: yup.string().required('Name required'),
  primaryMobile: yup.string().min(7, 'Min is 7').max(10, 'max is 10').required('Primary Mobile required'),
  primaryEmail: yup.string().email('Enter valid email').required('Primary Email required'),
  // secondaryMobile: yup.string().min(7, 'Min is 7').max(10, 'max is 10'),
  // secondaryEmail: yup.string().email('Enter valid email'),
  shippingAddress: yup.array().of(
    yup.object().shape({
      street: yup.string().required('Street required'),
      city: yup.string().required('City required'),
      state: yup.string().required('State required'),
      zip: yup.string().required('Zip required'),
      country: yup.string().required('Country required'),
    })
  ),
  billingAddress: yup.array().of(
    yup.object().shape({
      street: yup.string().required('Street required'),
      city: yup.string().required('City required'),
      state: yup.string().required('State required'),
      zip: yup.string().required('Zip required'),
      country: yup.string().required('Country required'),
    })
  ),
  // defaultBillingAddress: yup.array().of(
  //   yup.object().shape({
  //     street: yup.string().required('Street required'),
  //     city: yup.string().required('City required'),
  //     state: yup.string().required('State required'),
  //     zip: yup.string().required('Zip required'),
  //     country: yup.string().required('Country required'),
  //   })
  // ),

});

const addressSchema = yup.object().shape({
  street: yup.string().required('Street required'),
  city: yup.string().required('City required'),
  state: yup.string().required('State required'),
  zip: yup.string().required('Zip required'),
  country: yup.string().required('Country required'),
});

const vendorSchema = yup.object().shape({
  vendorOrganization: yup.string().required('Vendor Organization is Required'),
  cutOffTime: yup.string().required('Cut-Off Time Required'),
  primaryMobile: yup.string().required('Primary Mobile is Required'),
  primaryEmail: yup.string().required('Primary Email is Required'),
  vendorAddressDetails: yup.object().shape({
    street: yup.string().required('Street required'),
    city: yup.string().required('City required'),
    state: yup.string().required('State required'),
    zip: yup.string().required('Zip required'),
    country: yup.string().required('Country required'),
  }),
});


const purchaseSchema = yup.object().shape({
  orderDate: yup.string().required('Order Date is required'),
  vendorId: yup.string().required('Vendor Name is required'),
  // customerPhone: yup.string().required('Customer Phone required'),
  // customerName: yup.string().required('Customer Name is required'),

  purchaseOrderItems: yup.array().of(
    yup.object().shape({
      productId: yup.string().required('Product Id is required'),
      productName: yup.string().required('Product Name is required'),
      quantity: yup.string().required('Quantity is required'),
      HSNCode: yup.string().required('HSN Code is required'),
      price: yup.string().required('Price is required'),
    })
  ),
});

const pushPurchaseSchema = yup.object().shape({
  productId: yup.string().required('Product Id required'),
  productName: yup.string().required('Product Name required'),
  quantity: yup.number().positive().nullable(true).required('quantity required'),
});

const billingSchema = yup.object().shape({
  deliveryDate: yup.string().required('Delivery Date is required'),
  customerId: yup.string().required('Customer Name is required'),
  // customerPhone: yup.string().required('Customer Phone required'),
  // customerName: yup.string().required('Customer Name is required'),
  purchaseOrderNo: yup.string().required('purchase Order No is required'),
  billingitems: yup.array().of(
    yup.object().shape({
      productId: yup.string().required('Product Id is required'),
      productName: yup.string().required('Product Name is required'),
      quantity: yup.string().required('Quantity is required'),
      HSNCode: yup.string().required('HSN Code is required'),
      price: yup.string().required('Price is required'),
    })
  ),
});

const pushBillingSchema = yup.object().shape({
  productId: yup.string().required('Product Id required'),
  productName: yup.string().required('Product Name required'),
  //productDescription: yup.string().required('Product Description required'),
  quantity: yup.number().positive().nullable(true).required('quantity required'),
});

module.exports = {
  loginFormSchema,
  storeFormSchema,
  storeProductMapFormSchema,
  storeProductFormSchema,
  storeProductUnitFormSchema,
  storeProductHierarchyFormSchema,
  taxSchema,
  customerSchema,
  addressSchema,
  vendorSchema,
  purchaseSchema,
  pushPurchaseSchema,
  billingSchema,
  pushBillingSchema
};
