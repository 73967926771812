const yup = require('yup');


export const storeProductPriceMasterFormSchema = yup.object().shape({
  productId: yup.string().required('Master Product is Required'),
  sellingPrice: yup.number().positive().nullable(true).required('Selling Price is Required'),
  validFrom: yup.string().required('Valid From is Required'),
  validTo: yup.string().required('Valid To is Required'),
});

export const storeProductDiscountMasterFormSchema = yup.object().shape({
  productId: yup.string().required('Master Product is Required'),
  discountType: yup.string().required('Discount Type is Required'),
  discountValue: yup.number().positive().nullable(true).required('Discount Value is Required'),
  validFrom: yup.string().required('Valid From is Required'),
  validTo: yup.string().required('Valid To is Required'),
});



export default {
  storeProductPriceMasterFormSchema,
  storeProductDiscountMasterFormSchema,
};
