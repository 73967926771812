import React, { useState, useEffect } from 'react';
// import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// import Grid from '@mui/material/Grid';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import {
  USER_TYPE_OWNER,
  USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';

import NegotiationReject from './negotiationReject';
import styles from 'src/styles/negotiationBox';

const NegotiationHistory = ({itemId, questionId, negotiationQuestionList}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);

  const [inProgress, setInProgress] = useState(true);
  const [negotiationHistory, setNegotiationHistory] = useState({});
  const [formData, setFormData] = React.useState({
    hisId: '',
    status: '',
    answer: '',
    itemId: itemId,
    questionId: questionId,
  });

  useEffect(() => {
    fetchNegotiationHistory();
    // eslint-disable-next-line
  }, [setInProgress])

  const fetchNegotiationHistory = async () => {
    const res = await axiosInstance.post(Apiurls.NEGOTIATION_LIST, {itemId: itemId} );
    // const data = res.data;
    if (res.data.success) {
      setNegotiationHistory(res.data)
      setInProgress(false);
    }
  }

  const negotiationRejectedResponse = (datas) => {
    setFormData({
      ...formData,
      ...datas,
    })
    setInProgress(false)
  }


//   const negotiationResponse = async (data) => {
//     setInProgress(true);
//     const res = await axiosInstance.post(Apiurls.NEGOTIATION_RESPONSE(data.hisId), { status:data.status });
//
//     if (res.data.success) {
//         window.mNotify.error('Your Negotiation has been Accepted');
//         setInProgress(false);
//         fetchNegotiationHistory()
//       }
//       else{
//         window.mNotify.error('Your Negotiation has been Not Accepted');
//       }
//   }

  const onSave = async (data) => {

    const res = await axiosInstance.post(Apiurls.NEGOTIATION_RESPONSE(data.hisId), { status:data.status, answer: data.answer });
    if(res.data.success)
    {
      window.mNotify.error('Your Negotiation has been Responded');
      setFormData({
      ...formData,
       hisId: '',
      })
      fetchNegotiationHistory()
    }
    else
    {
      window.mNotify.error('Please fill out this field');
    }
  };

  const Question = data => <b>{data}</b>
  const Option_1 = data => <b>{data.answer}</b>
  const Option_2 = data => <><b>{data.answer} </b></>
  const CONTENT_TYPE = data => {
        if(data.requestedBy === USER_TYPE_VENDOR)
        {
          if((JSON.parse(data.response).action === false) && (JSON.parse(data.response).action !== undefined))
            {
               return ' has been reject the ';
            }
          if(JSON.parse(data.response).action === undefined)
            {
               return ' want change the ';
            }
            if((JSON.parse(data.response).action === true) && (JSON.parse(data.response).action !== undefined))
            {
               return ' has been accepted the ';
            }
            return ' has been changed the ';
        }
        if(data.requestedBy === USER_TYPE_OWNER)
        {
           if((JSON.parse(data.response).action === false) && (JSON.parse(data.response).action !== undefined))
            {
               return ' has been reject the ';
            }
          if((JSON.parse(data.response).action === true) && (JSON.parse(data.response).action !== undefined))
            {
               return ' has been accepted the ';
            }
          return ' want change the ';
        }
      }

  const List = ({data}) => {
    return (
      <>
        {
        Object.keys(data).map((Lvals, Lkeys)=> (
           <Item data={data[Lvals]} length={Object.keys(data).length}/>
          ))
        }

      </>
    )
  }

  const Item = ({data, length}) => {
    return (
      <>
        <TableRow keys={data.id}>
          <TableCell><b>{data.sequenceId}</b>)  <b>{data.requestedBy}</b> {CONTENT_TYPE(data)} {Question(negotiationQuestionList[questionId].question)} is <mark>{(data.requestedBy === USER_TYPE_VENDOR) ? Option_1(data) : Option_2(data)}</mark>
           {formData.hisId===data.id ? <NegotiationReject data={formData} previousNegotiation={data} QuestionId={questionId} negotiationQuestionList={negotiationQuestionList} onSave={onSave}/> : null }
    </TableCell>
          <TableCell>
            <ButtonGroup  disableElevation size="small" variant="outlined" color="secondary">
               {
                    (data.requestedBy !== USER_TYPE_OWNER) && (length === data.sequenceId) && (JSON.parse(data.response).action === undefined)? <ButtonGroup  disableElevation size="small" variant="outlined" color="secondary">
                    <Button  onClick={(e)=>{
                      onSave({
                        hisId: data.id,
                        status: true
                      })
                    }} className={classes.buttonsize} >  Accepted  </Button>
                    <Button  onClick={(e)=>{
                      negotiationRejectedResponse({
                          hisId: data.id,
                          status: false,
                          negotiation: {
                            answer: ''
                          }
                        })
                    }} className={classes.buttonsize} >  Rejected  </Button>
                  </ButtonGroup> : <>
                  {(JSON.parse(data.response).action === false) && (JSON.parse(data.response).action !== undefined) ? <span className={classes.spanDanger}>{data.requestedBy} Rejected</span> : null }
                  {(JSON.parse(data.response).action === true) && (JSON.parse(data.response).action !== undefined)? <span className={classes.spanSuccess}>{data.requestedBy} Accepted</span> : null }
                  {(JSON.parse(data.response).action === undefined)? <span className={classes.spanPrimary}>{data.requestedBy} Initiated</span> : null }

                  </>}
              </ButtonGroup>
          </TableCell>
        </TableRow>
      </>
    )
  }

  const HistoryTables = ()=> {

    return (
      <>
          <TableContainer >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                Object.keys(negotiationHistory.data[questionId]).map((vals, keys)=> (
                   <List data={negotiationHistory.data[questionId][vals]} />
                  ))
              }
              </TableBody>
            </Table>
          </TableContainer>
      </>
      )
  }

  return (
      <>
          {  (!inProgress) && (negotiationHistory.data[questionId] !== undefined) ?  <HistoryTables />: null}
      </>
  );
}

export default NegotiationHistory;
