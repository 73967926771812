import DashboardIcon from '@mui/icons-material/Dashboard';
import OwnerIcon from '@mui/icons-material/Person';
import VendorIcon from '@mui/icons-material/PersonOutline';
import StoreIcon from '@mui/icons-material/Store';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PrintIcon from '@mui/icons-material/Print';

const Iconpsackage = {
  DashboardIcon: DashboardIcon,
  OwnerIcon: OwnerIcon,
  VendorIcon: VendorIcon,
  StoreIcon: StoreIcon,
  AddIcon: AddIcon,
  ExpandLess: ExpandLess,
  ExpandMore: ExpandMore,
  AppsIcon: AppsIcon,
  ListIcon: ListIcon,
  PeopleAltIcon: PeopleAltIcon,
  HomeWorkIcon: HomeWorkIcon,
  RecordVoiceOverIcon: RecordVoiceOverIcon,
  AddShoppingCartIcon: AddShoppingCartIcon,
  ReceiptIcon:ReceiptIcon,
  SettingsIcon:SettingsIcon,
  ReportIcon:ReportIcon,
  MoneyIcon:MoneyIcon,
  AccountBoxIcon:AccountBoxIcon,
  EventNoteIcon:EventNoteIcon,
  AssessmentIcon:AssessmentIcon,
  LocalAtmIcon: LocalAtmIcon,
  MonetizationOn: MonetizationOn,
  ArticleIcon: ArticleIcon,
  AssignmentIcon: AssignmentIcon,
  NoteAltIcon: NoteAltIcon,
  PersonOutlineIcon: PersonOutlineIcon,
  PhoneIphoneIcon: PhoneIphoneIcon,
  PrintIcon: PrintIcon
}
export default Iconpsackage;
