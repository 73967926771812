import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class myOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      vendor: {},
      products: {},
      brands: {}
    }
  }

  componentDidUpdate() {
    this.vendorFinds();
  }

  vendorFinds() {
    const vendorGroup = (array, key) => {
      return array.reduce((result, currentValue) => {
        if (![currentValue[key]]) {
          return null;
        }
        (result[currentValue[key]] = result[currentValue[key]] || [])
        .push(
          currentValue
        );
        return result;
      }, {});
    };
    const vendorGroupBy = vendorGroup(this.props.dataFromDate.products, 'vendorId')
    Object.values(vendorGroupBy)
      .map((ListRows, idx) => {
        if (this.state.vendor[ListRows[0].vendorId] === undefined) {
          this.getVendorDetails(ListRows[0].vendorId);

          vendorGroupBy[ListRows[0].vendorId].map(
            (productDetails, keys) => {

              if (this.state.brands[productDetails.brandId] === undefined) {
                this.getBrandDetails(productDetails.brandId);
              }

              if (this.state.products[productDetails.productId] === undefined) {
                this.getProductDetails(productDetails.productId);
              }
              return null;
            }
          )
        }
        return null;
      })
  }

  getProductDetails(Id) {
    if (Id > 0) {
      fetch('/api/store/product/' + Id, {
          method: 'get',
        })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          //alert(json['0'].productDescription);
          this.setState({
            products: {
              ...this.state.products,
              [Id]: json['0'].productDescription
            }
          })
          //return json['0'].productDescription
        });
    }
  }

  getBrandDetails(Id) {
    if (Id > 0) {
      fetch('/api/store/brand/' + Id, {
          method: 'get',
        })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          this.setState({
            brands: {
              ...this.state.brands,
              [Id]: json['0'].brandName
            }
          })
          //return json['0'].productDescription
        });
    }
  }


  getVendorDetails(Id) {
    if (Id > 0) {
      fetch('/api/store/vendor/' + Id, {
          method: 'get',
        })
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          this.setState({
            vendor: {
              ...this.state.vendor,
              [Id]: json['0'].vendorOrganization,
            },
          });
        });
    }
  }

  subMyOrders() {
    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        if (![currentValue[key]]) {
          return null;
        }
        (result[currentValue[key]] = result[currentValue[key]] || [])
        .push(
          currentValue
        );
        return result;
      }, {});
    };
    const personGroupedBy = groupBy(this.props.dataFromDate.products, 'vendorId');

    //  console.log(Object.values(personGroupedBy));
    return (
      <TableContainer >
            <Table className={this.props.classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell>Product Details</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Single Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Request Delivery Date</TableCell>
                        <TableCell>Budget</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                Object.values(personGroupedBy).map((productRows, idx) =>  (
                    <TableRow>
                    <TableCell>{idx+Number(1)}</TableCell>
                    <TableCell>{this.state.vendor[productRows[0].vendorId]}</TableCell>
                    <TableCell>
                        {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>
                            {this.state.products[productDetails.productId]}
                        </p>
                        ))}
                    </TableCell>
                    <TableCell>
                        {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>
                            {this.state.brands[productDetails.brandId]}
                        </p>
                        ))}
                    </TableCell>
                    <TableCell>
                        {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>Rs.{productDetails.singlePrice}</p>
                         ))}
                    </TableCell>
                    <TableCell>
                        {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>{productDetails.productQuantity}</p>
                         ))}
                    </TableCell>
                    <TableCell>
                        {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>
                        <Moment format="DD/MM/YYYY HH:mm A" date={productDetails.requestDeliveryDate} />
                        </p>
                         ))}
                    </TableCell>
                    <TableCell>
                    {personGroupedBy[productRows[0].vendorId].map(
                        (productDetails, keys) =>  (
                        <p>{productDetails.budget}</p>
                         ))}
                    </TableCell>
                    </TableRow>
                ))
                }
                </TableBody>
                </Table>
            </TableContainer>
    )
  }

  render() {
    return (
      <div>
                <Grid container spacing={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <h2>Your Orders</h2>
                        </Grid>
                        { this.subMyOrders() }
                    </Grid>
                </Grid>
            </div>
    );
  }
}

export default withStyles(useStyles)(myOrders);
