import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  formControl: {
    textAlign: 'left',
    width: '100%'
  }
}));

export default styles;
