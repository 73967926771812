import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import ProductPriceForm from './partials/productPriceForm';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Master Products',
    url: Urls.ProductMasterProduct,
  },
  {
    title: 'Product Prices Add'
  },
];
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ManageMasterProductPrice extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        price : 0.00
      },
      orginalData: {},
      inProgress: false,
      storeVendorList: [],
      isLoadingData: !!(this.props.match && this.props.match.params.id),
    }
  }

  componentDidMount() {
    this.getProductVendors();
    this.getProductPriceData();
  }

  getProductPriceData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_PRICE_EDIT(this.props.match.params.masterProductId, this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    }
  }

  getProductVendors = async () => {
    this.setState({ isLoadingData: true, inProgress: false });
    const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_PRICE_VENDOR_LIST);
    console.log(res.data.data)
    if (res.data.success) {
      this.setState({
        storeVendorList: res.data.data,
      });
      this.setState({ isLoadingData: false });
    } else {
      window.mNotify.error('Error while fetching vendor list');
    }
  };

  resetState = () => {
    this.setState({
      formData: {},
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        vendorId: data.vendorId,
        price: data.price,
        masterProductId: this.props.match.params.masterProductId,
      };
      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_MASTER_PRICE_EDIT(this.props.match.params.masterProductId,orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_MASTER_PRICE(this.props.match.params.masterProductId), body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.ProductMasterProductPrice(this.props.match.params.masterProductId));
      }

      if (res.data.isexisting) {
        window.mNotify.show('Master product price existing', 'danger');
        return this.props.history.push(Urls.ProductMasterProductPrice(this.props.match.params.masterProductId));
      }

      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });

      window.mNotify.error('Error while creating product master product. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData , storeVendorList } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>{formData.id ? 'Edit' : 'Add'} Product Price</h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <ProductPriceForm
              data={formData}
              inProgress={inProgress}
              onSave={this.onSave}
              storeVendorList={storeVendorList}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageMasterProductPrice));
