const yup = require('yup');

export const storeProductFormSchema = yup.object().shape({
  productName: yup.string().required('Product is Required'),
});

export const storeProductBrandFormSchema = yup.object().shape({
  brandName: yup.string().required('Brand Name is Required'),
});

export const storeProductSizeFormSchema = yup.object().shape({
  sizeName: yup.string().required('Size Name is Required'),
});

export const storeProductColourFormSchema = yup.object().shape({
  colourName: yup.string().required('Colour Name is Required'),
});

export const storeProductFitFormSchema = yup.object().shape({
  fitName: yup.string().required('Fit Name is Required'),
});

export const storeProductMaterialFormSchema = yup.object().shape({
  materialName: yup.string().required('Material Name is Required'),
});

export const storeProductTaggingFormSchema = yup.object().shape({
  tagName: yup.string().required('Tag Name is Required'),
});

export const storeProductMapFormSchema = yup.object().shape({
  productName: yup.string().required('Product Name is Required'),
  brandName: yup.string().required('Brand Name is Required'),
  fitName : yup.string().required('Style or Design or Fit Name is Required'),
  ownerPrice: yup.number().positive().nullable(true).required('Owner Price is Required'),
  barcodeNumber: yup.string().required('Barcode Number is Required')
});

export const storeProductPriceFormSchema = yup.object().shape({
  vendorId: yup.string().required('Vendor Name is Required'),
});

export const storeProductImageFormSchema = yup.object().shape({
  files: yup.array().required('Product images is Required'),
});

export default {
  storeProductFormSchema,
  storeProductBrandFormSchema,
  storeProductSizeFormSchema,
  storeProductColourFormSchema,
  storeProductFitFormSchema,
  storeProductMaterialFormSchema,
  storeProductTaggingFormSchema,
  storeProductMapFormSchema,
  storeProductPriceFormSchema,
  storeProductImageFormSchema
};
