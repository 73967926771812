import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import DiscountMasterForm from './partials/discountMasterForm';
import Button from '@mui/material/Button';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Add Discount Master',
  },
];

const DatePrefix = 'YYYY-MM-DD';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

const EmptyValue = (props) => {
  return {
    productId: props.match && props.match.params.masterProductId,
    discountValue: null,
    discountType: '',
    validFrom: new Date(),
    validTo: new Date(),
    isActive: 1,
  }
}

class ManageDiscountMaster extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...EmptyValue(this.props)
      },
      orginalData: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
    }
  }

  componentDidMount() {
    this.getDiscountMasterData();
  }

  getDiscountMasterData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_DISCOUNT_MASTER_EDIT(this.props.match.params.productId, this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    }
  }

  resetState = () => {
    // console.log(EmptyValue(this.props))
    this.setState({
      formData: {
        ...EmptyValue(this.props)
      }
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        productId: data.productId,
        discountValue: data.discountValue,
        discountType: data.discountType,
        validFrom: data.validFrom,
        validTo: data.validTo,
        isActive: data.isActive,
      };
      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_DISCOUNT_MASTER_EDIT(body.productId, orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_DISCOUNT_MASTER(body.productId), body);
      }

      if (res.data.success) {
        window.mNotify.success(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        this.setState({ inProgress: false });
        return this.props.history.push(Urls.ProductDiscountMaster(body.productId));
      }
      if (res.data.isexisting) {
        this.setState({ inProgress: false });
        return window.mNotify.error('Product Discount Master already existing', 'danger');
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });
      window.mNotify.error('Error while creating Discount Master. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <div className={this.props.classes.titleContainer}>
        <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductMasterProduct);
            }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductDiscountMaster(this.props.match.params && this.props.match.params.masterProductId));
            }}
            style={{ marginRight: '5px' }}
          >
             View Discount Master
          </Button>


        </div>
        <h2>{formData.id ? 'Edit' : 'Add'} Discount Master </h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <DiscountMasterForm
              data={formData}
              inProgress={inProgress}
              onSave={this.onSave}
              resetState={this.resetState}
              DatePrefix={DatePrefix}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageDiscountMaster));
