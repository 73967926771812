import React from 'react';
// import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// import { GlobalContext } from 'src/GlobalContextProvider';

import styles from 'src/styles/negotiationBox';

const NegotiationQuestion = ({QuestionList, selecteQuestion, Data}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);
  const NegoQuestList = () => {    return (
      <>
        {
          QuestionList.success ? <div className={classes.root}>
          <Grid container spacing={3}>
            {
              Object.keys(QuestionList.data).map((vals, keys) => (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Button className={ Data.questionId === QuestionList.data[vals].id ? classes.Active : classes.Grid }
                    onClick={(e)=>{
                      selecteQuestion(QuestionList.data[vals].id)
                    }}>{QuestionList.data[vals].question}</Button>
                </Grid>
                )
              )
            }
          </Grid>
        </div> : null
      }
      </>
    )
  };

  return (
    <>
      <NegoQuestList />
    </>
  );
}

export default NegotiationQuestion;
