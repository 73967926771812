import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import styles from 'src/styles/productBrandForm';
import { storeProductPriceFormSchema } from "../validation/formValidation";

const ProductPriceForm = ({ data = {}, inProgress, onSave, storeVendorList }) => {

  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Store Master Product Price error occurs!');
    }
  };

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeProductPriceFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);


  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <Autocomplete
              value={Object(storeVendorList[formData.vendorId])}
              options={Object.values(storeVendorList)}
              getOptionLabel={option => (option.vendorOrganization ? option.vendorOrganization : "")}
              autoSelect={true}
              onChange={(e, setValue) => {
                setFormData({
                  ...formData,
                  vendorId: setValue !== null ? setValue.vendorId : '',
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Vendor"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.vendorId)}
                  helperText={validationDataError.errors ? validationDataError.errors.vendorId : ''}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Product Price"
              error={!!(validationDataError.errors && validationDataError.errors.price)}
              helperText={validationDataError.errors ? validationDataError.errors.price : ''}
              value={formData.price}
              variant="outlined"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  price: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: '5px' }}
            onClick={(e) => {
              setFormData({ ...data });
            }}
          >
            Reset
          </Button>

        </Grid>

      </Grid>
    </form>
  );
}

export default ProductPriceForm;
