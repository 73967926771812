
/**
 * Finds if the current window size is true or false
 * TODO: Handle window for mobile application
 * @returns {Boolean} True if thw width matches desktop, false otherwise
 */
 const isDesktop = () => window.innerWidth >= 1040;

 /**
  * Sets class on body based on sidebar open/closed status
  * @param {Boolean} isOpen True if sidebar is open, false otherwise
  */
 const setBodyClassForSidebarStatus = (isOpen) => {
   if (isOpen) {
     document.body.classList.add('sidebar-open');
     document.body.classList.remove('sidebar-close');
   } else  {
     document.body.classList.add('sidebar-close');
     document.body.classList.remove('sidebar-open');
   }
 };

 export default {
   isDesktop,
   setBodyClassForSidebarStatus,
 };
