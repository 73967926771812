import React from 'react';
import {
  withRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { GlobalContext } from 'src/GlobalContextProvider';
import DashboardLayout from 'src/dashboardLayout';

import Logout from 'src/pages/authentication/logout';
import axiosInstance from 'src/http';
import initAxiosInterceptors from 'src/http/interceptors';
import Urls from 'src/constants/urls';
import {
  USER_TYPE_OWNER,
  USER_TYPE_VENDOR,
  USER_TYPE_ADMIN,
} from 'src/constants/index';

import Index from 'src/pages/index';
import AddOwner from 'src/pages/owner/addOwner';
import ListStores from 'src/pages/owner/listStores';
import ManageStore from 'src/pages/owner/manageStore';

//Owners
import ListOrders from 'src/pages/owner/listOrders';
import ManageOrder from 'src/pages/owner/manageOrder';
import ListOrderItems from 'src/pages/owner/listOrderItems';

//Vendors
import MyOrders from 'src/pages/vendor/vendorOrders';
import MyOrdersItems from 'src/pages/vendor/listOrderItems';

import ManageVendor from 'src/pages/owner/manageVendor';
import AddMCategory from 'src/pages/master/AddMCategory';
import AddMSubCategory from 'src/pages/master/AddMSubCategory';
import orderRequest from 'src/pages/orders/orderRequest';
// import vendorOrders from 'src/pages/vendor/vendorOrders';
import ListVendor from 'src/pages/owner/listVendors';
import ManageOwner from 'src/pages/owner/manageOwner';
// import InitProduct from './initProduct';
// import InitNewProduct from './initNewProduct';
import RootRouter from 'src/router/root';
import Product from 'src/products';
import NewProduct from 'src/newProducts/App';

import './App.css';
import Loader from './components/loader';
import { withTranslation } from 'react-i18next';
// Lazy loaded routes
const AuthenticationLayout = React.lazy(() => import('src/pages/authentication/layout'));
const StoreLayout = React.lazy(() => import('src/pages/owner/store/layout'));

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inProgress: true,
    }
  }

  componentDidMount() {
    this.doOneTimeInit();
  }

  doOneTimeInit = async () => {
    await this.addAxiosInterceptor();
    this.setState({
      inProgress: false,
    });
  }
  /**
   * Initializes the interceptors to listen to 401 failures
   */
  addAxiosInterceptor = () => {
    initAxiosInterceptors(this.context, axiosInstance);
  }

  getAdminRoutes = () => {
    const contextState = this.context;
    if (contextState.user.type === USER_TYPE_ADMIN) {
      const routes = [
        {
          url: Urls.Dashboard,
          component: Index,
        },
        {
          url: Urls.OwnerAdd,
          component: AddOwner,
        },
        {
          url: Urls.StoreVendorAdd,
          component: ManageVendor,
        },
        {
          url: Urls.StoreVendorPrefix,
          component: ListVendor,
        },
        {
          url: Urls.StoreVendorEdit(':id'),
          component: ManageVendor,
        },
        {
          url: Urls.CategoryAdd,
          component: AddMCategory,
        },
        {
          url: Urls.SubCategoryAdd,
          component: AddMSubCategory,
        },
        {
          url: Urls.StoreAdd,
          component: ManageStore,
        },
        {
          url: Urls.Store,
          component: ListStores,
        },
        {
          url: Urls.OrderAdd,
          component: orderRequest,
        },
      ];
      routes.defaultIndex = 0;

      return routes;
    }
    return [];
  }

  getOwnerRoutes = () => {
    const contextState = this.context;
    const routesForOutSideFolders = [];
    const allOutSideFoldersName = {
      // products: {
      //   storeProducts: "productsRoutesDetails"
      // },
      modules: {
        storeModules: "modulesRoutesDetails"
      },
      newFeatures: {
        storeNewFeatures: "newFeaturesRoutesDetails"
      },
      features: {
        storeFeatures: "featuresRoutesDetails"
      },
      connectors: {
        storeConnectors: "connectorsRoutesDetails"
      }
    };

    /**
     * Here New Product sidebar start
    */
    // const routesForNewProduct = [];
    // if (contextState.newProducts && contextState.newProducts.storeProductsStatus && contextState.newProducts.storeProducts.inProgress) {
    //   routesForOutSideFolders.push(...contextState.newProducts.storeProducts.productsRoutesDetails);
    // }
    /**
     * Here New Product sidebar end
    */

    Object.keys(allOutSideFoldersName).forEach((index) => {
      let indexKey = allOutSideFoldersName[index]
      Object.keys(indexKey).forEach((item) => {
        if (Object.keys(contextState[index][item]).length) {
          Object.keys(contextState[index][item]).forEach((val) => {
            let extraSideBar = contextState[index][item][val]
            if (!!extraSideBar[indexKey[item]] && !!extraSideBar[indexKey[item]].length) {
              routesForOutSideFolders.push(...extraSideBar[indexKey[item]]);
            }
          })
        }
      })
    });

    if (contextState.user.type === USER_TYPE_OWNER) {
      const routes = [
        {
          url: Urls.Dashboard,
          component: Index,
        },
        {
          url: Urls.CategoryAdd,
          component: AddMCategory,
        },
        {
          url: Urls.SubCategoryAdd,
          component: AddMSubCategory,
        },
        {
          url: Urls.StoreAdd,
          component: ManageStore,
        },
        {
          url: Urls.StoreEdit(':id'),
          component: ManageStore,
        },
        {
          url: Urls.Store,
          component: ListStores,
        },
        {
          url: Urls.StoreVendorAdd,
          component: ManageVendor,
        },
        {
          url: Urls.StoreVendorPrefix,
          component: ListVendor,
        },
        {
          url: Urls.StoreVendorEdit(':id'),
          component: ManageVendor,
        },
        {
          url: Urls.Order,
          component: ListOrders,
        },
        {
          url: Urls.OrderAdd,
          component: ManageOrder,
        },
        {
          url: Urls.OrderEdit(':id'),
          component: ManageOrder,
        },
        {
          url: Urls.OrderItems(':id'),
          component: ListOrderItems,
        },
        {
          url: Urls.OwnerProfileEdit,
          component: ManageOwner,
        },
        ...Product.routers,
        ...NewProduct.routers,
        ...(routesForOutSideFolders),
      ];
      routes.defaultIndex = 0;

      return routes;
    }
    return [];
  }

  getVendorRoutes = () => {
    const contextState = this.context;
    if (contextState.user.type === USER_TYPE_VENDOR) {
      const routes = [
        {
          url: Urls.Dashboard,
          component: Index,
        },
        {
          url: Urls.MyOrder,
          component: MyOrders,
        },
        {
          url: Urls.MyOrderItems(':id'),
          component: MyOrdersItems,
        },

      ];
      routes.defaultIndex = 0;
      return routes;
    }

    return [];
  }

  getRoutes = (name, items) => {
    if (!items.length) {
      return null;
    }

    return (
      <Switch>
        {
          items.map(r => {
            return (
              <Route
                exact
                key={r.url}
                path={r.url}
                component={r.component}
              />
            )
          })
        }
        {
          (items.defaultIndex !== undefined) &&
          <Route
            path="*"
            render={() => (
              <Redirect to={items[items.defaultIndex].url} />
            )}
          />
        }
      </Switch>
    );
  }

  render() {

    if (this.state.inProgress) {
      return (<Loader title={this.props.t('pleaseWait')} />);
    }
    const isLoggedIn = !!(this.context.user && this.context.user.id);
    const isStoreSelected = !!this.context.currentStore;

    let loggedOutRoutes;
    let storeSelectionRoutes;
    let loggedInRoutes;

    if (!isLoggedIn) {
      loggedOutRoutes = (
        <Switch>
          <Route component={AuthenticationLayout}></Route>
          <Redirect to={Urls.Login} />
        </Switch>
      );
    }

    if (isLoggedIn) {
      if (isStoreSelected || this.context.user.type === USER_TYPE_VENDOR) {
        loggedInRoutes = (
          <>
            {this.getRoutes(USER_TYPE_ADMIN, this.getAdminRoutes())}
            {this.getRoutes(USER_TYPE_OWNER, this.getOwnerRoutes())}
            {this.getRoutes(USER_TYPE_VENDOR, this.getVendorRoutes())}
          </>
        );
      }

      if (!isStoreSelected && this.context.user.type === USER_TYPE_OWNER) {
        storeSelectionRoutes = (
          <Switch>
            <Route
              path={Urls.StoreBase}
              component={StoreLayout}
            />
            <Redirect to={Urls.StoreBase} />
          </Switch>
        );
      }
    }
    return (
      <>
        {/* <RootRouter
          isLoggedIn={isLoggedIn}
        /> */}
        <Switch>
          {/* Routes rendered always first */}
          <Route exact path={Urls.Logout} component={Logout}></Route>

          {/* conditional routes below */}
          {loggedOutRoutes}
          {storeSelectionRoutes}

          {
            (isStoreSelected || this.context.user.type === USER_TYPE_VENDOR) ?
              <DashboardLayout>
              {loggedInRoutes}
            </DashboardLayout> : null
          }
        </Switch>
      </>
    );
  }
}

App.contextType = GlobalContext;
export default withRouter(withTranslation()(App));
