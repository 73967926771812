import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const useStyles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  });

class AddVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message:'',
            formData: {
                MCategoryName: '',
                MCategoryStatus: 1
              },
            ownerNameError:false
        }
    }

    ResetStates = () => {
        this.setState({
            formData: {
                MCategoryName: '',
                MCategoryStatus: '1'
            },
        });
    }
    async onSubmit(e)
    {
        e.preventDefault();
        try {
                await fetch('/api/mcategory' ,{
                method : 'post',
                headers : {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                },
                body : JSON.stringify({
                    name : this.state.formData.MCategoryName,
                    isActive : this.state.formData.MCategoryStatus,
                    id : ""
                })
            }).then((response) => response.json()).then((data) => {
                if(data.success === true){
                    window.mNotify.show(data.message, 'success');
                    this.setState({
                        message : data.message,
                    });
                    this.ResetStates();
                }
                else{
                    window.mNotify.show('Sorry!.. Master Category details has been added unsuccessfully');
                    this.setState({
                        message : 'Sorry!.. Master Category details has been added unsuccessfully',
                    })
                }
            })
        }
        catch (error)
        {
            console.log(error);
        }
    }

    render()
    {
        return (
            <div>
             <Breadcrumbs aria-label="breadcrumb">
            <Link
                color="inherit"
                href="/"
                onClick={
                    (e) => {
                        this.props.history.push('/');
                    }
                }
                underline="hover">
            Home
            </Link>
            <Typography >Manage Master Category</Typography>
            <Typography color="textPrimary">Add Master Category</Typography>
            </Breadcrumbs>
              <h2>Add Master Category</h2>
              <form onSubmit={this.onSubmit.bind(this)} className={this.props.classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            required
                            id="outlined-basic"
                            fullWidth
                            value={this.state.formData.MCategoryName}
                            label="Category Name"
                            variant="outlined"
                            onChange={(e)=>{
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        MCategoryName: e.target.value,
                                    },
                                })
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                    <FormControl component="fieldset" fullWidth style={{textAlign: 'left'}}>
                        <FormLabel component="legend"  >Status</FormLabel>
                        <RadioGroup row aria-label="position"
                        onChange={(e)=>{

                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    MCategoryStatus: e.target.value,
                                },
                            })
                        }}
                        value="1"
                         name="MCategoryStatus" defaultValue="top">
                            <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Active"
                            />
                            <FormControlLabel
                            value="0"

                            control={<Radio color="primary" />}
                            label="Inactive"
                            />
                        </RadioGroup>
                    </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} align="center">
                        <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
                        Add
                        </Button>
                        <Button variant="contained" color="secondary" style={{margin: "5px"}}
                        onClick={(e)=>{
                            this.ResetStates();
                        }}>
                        Reset
                        </Button>
                        <p>{this.state.message}</p>
                </Grid>

            </Grid>
            </form>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(AddVendor));
