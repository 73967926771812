import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import styles from 'src/styles/productForm';
import ImageList from './imageList'
import ManageExistImageChooseList from './manageExistImageChooseList'

const ImageForm = ({inProgress, relatedImage, onUpdate}) => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onMerge = async (data) => {
    onUpdate([ ...data, ...Object.keys(relatedImage)])
  }

  const onDelete = async (data) => {
    onUpdate(data)
  }

  return (
    <form className={classes.root} disabled={inProgress}
    >
      <Grid container >
        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="button" onClick={() => { setOpen(true) }} className={classes.danger} style={{margin: "5px"}}>Map Existing Images</Button>
        </Grid>
        {inProgress ? <CircularProgress color="inherit" size={25} style={{ color: "blue"}} /> :
          <ImageList itemData={relatedImage} inProgress={inProgress} onDelete={onDelete} /> }
        {open ? <ManageExistImageChooseList onMerge={onMerge} onClose={onClose} /> : '' }
      </Grid>
    </form>
  );
}

export default ImageForm;
