import React from 'react';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import styles from '../styles/productDashboard';
import Urls from '../constants/urls';

const ProductDashboard = ({ history }) => {
  const classes = styles();

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Master Product"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMasterProductAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMasterProduct);
              }}
              >
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Products"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductAdd);
                }}
              >
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                  history.push(Urls.Product);
                }}>
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Brands"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                history.push(Urls.ProductBrandAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                history.push(Urls.ProductBrand);
              }}
              >
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Style/Design/Fit"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductFitAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
              onClick={(e) => {
                history.push(Urls.ProductFit);
              }}>
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Sizes"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductSizeAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                history.push(Urls.ProductSize);
              }}>
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Colors"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductColourAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
              onClick={(e) => {
                history.push(Urls.ProductColour);
              }}>
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Material"
                subheader=""
              />

            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>

              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMaterialAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMaterial);
              }}>
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Tagging"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                history.push(Urls.ProductTaggingAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                history.push(Urls.ProductTagging);
              }}
              >
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
}

export default ProductDashboard;
