import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

import styles from 'src/styles/productBrandForm';
import { storeProductDiscountMasterFormSchema } from "../validation/formValidation";

const PriceMasterForm = ({ data = {}, errorMessage, inProgress, onSave , history, resetState, DatePrefix }) => {
  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Product Price Master error occurs!');
    }
  };

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeProductDiscountMasterFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <FormControl variant="outlined"  fullWidth align="left" className="login-selection" id="demo-Customer-type">
            <InputLabel id="demo-Customer-select-label">
               Select Discount Type
            </InputLabel>
            <Select
              labelId="demo-Customer-select-label"
              error={
                !!(
                  validationDataError.errors &&
                  validationDataError.errors.discountType
                )
              }
              helperText={
                validationDataError.errors
                  ? validationDataError.errors.discountType
                  : ''
              }
              onChange={(e) => {
                setFormData({
                  ...formData,
                  discountType: e.target.value,
                });
              }}
              // defaultValue={'IN'}
              value={formData.discountType}
            >
            {/* <MenuItem value="">None</MenuItem> */}

              <MenuItem value="Percentage">Percentage</MenuItem>
              <MenuItem value="Amount">Amount</MenuItem>


            </Select>
            { validationDataError.errors ? <FormHelperText id="my-helper-text" style={{ color: "red" }}>{validationDataError.errors.discountType}</FormHelperText> : '' }
          </FormControl>

          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
                <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Discount Value"
                    type="number"
                    error={!!(validationDataError.errors && validationDataError.errors.discountValue)}
                    helperText={validationDataError.errors ? validationDataError.errors.discountValue : ''}
                    value={formData.discountValue}
                    variant="outlined"
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        discountValue: e.target.value,
                      });
                    }}/>
            </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  label="Valid From"
                  variant="outlined"
                  placeholder={DatePrefix}
                  format={DatePrefix}
                  error={!!(validationDataError.errors && validationDataError.errors.validFrom)}
                  helperText={validationDataError.errors ? validationDataError.errors.validFrom : ''}
                  value={formData.validFrom}
                  minDate={new Date()}

                  onChange={ (dte) => {
                    setFormData({
                      ...formData,
                      validFrom: dte,
                    });
                  } }
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  label="Valid To"
                  placeholder={DatePrefix}
                  format={DatePrefix}
                  error={!!(validationDataError.errors && validationDataError.errors.validTo)}
                  helperText={validationDataError.errors ? validationDataError.errors.validTo : ''}
                  value={formData.validTo}
                  minDate={formData.validFrom}

                  onChange={ (dte) => {
                    setFormData({
                      ...formData,
                      validTo: dte,
                    });
                  } }
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
              <FormControl component="fieldset" fullWidth style={{ textAlign: 'left' }}>
                  <FormLabel component="legend"  >Status</FormLabel>
                  <RadioGroup row aria-label="position"
                      onChange={(e) => {
                          setFormData({
                              ...formData,
                              isActive: Number(e.target.value),
                          });
                      }}
                      value={formData.isActive}
                      name="isActive" defaultValue="top">
                      <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Active"
                      />
                      <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="Inactive"
                      />
                  </RadioGroup>
                </FormControl>
            </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} style={{color: "white"}}/>}
            {data.id ? 'Update' : 'Create'}
          </Button>
          {/* <Button */}
          {/*   variant="contained" */}
          {/*   color="secondary" */}
          {/*   style={{ margin: '5px' }} */}
          {/*   onClick={(e) => { */}
          {/*     setFormData({ */}
          {/*       ...formData, */}
          {/*       discountValue: null, */}
          {/*       discountType: '', */}
          {/*       validFrom: new Date(), */}
          {/*       validTo: new Date(), */}
          {/*     }); */}
          {/*     // resetState() */}
          {/*   }} > */}
          {/*   Reset */}
          {/* </Button> */}
          <p>{errorMessage}</p>
        </Grid>
      </Grid>
    </form>
  );
}

export default PriceMasterForm;
