import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontSharp from '@mui/icons-material/StorefrontSharp';

import {
  USER_TYPE_OWNER,
  // USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import { GlobalContext } from 'src/GlobalContextProvider';
import Urls from 'src/constants/urls';
import Lib from 'src/lib';
import SideBar from 'src/sidebar';
import styles from 'src/styles/topbarStyles';

const Topbar = (props) => {
  const classes = styles();
  const { t } = useTranslation();
  const contextValue = React.useContext(GlobalContext);
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(Lib.isDesktop());
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
  const userMenuAnchor = React.useRef();

  React.useEffect(() => {
    if (!Lib.isDesktop()) {
      setIsSidebarOpen(false);
    }
  }, [pathname, Lib.isDesktop]);

  // set the right class to body for first time when sidebar is mounting.
  React.useEffect(() => {
    Lib.setBodyClassForSidebarStatus(isSidebarOpen);
  }, []);

  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
    Lib.setBodyClassForSidebarStatus(!isSidebarOpen);
  };

  return <>
    <AppBar
      position="absolute"
      className={clsx(classes.appBar)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleMenu}
          className={clsx(classes.menuButton)}
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          mPower.
        </Typography>
        {/* <IconButton color="inherit"> */}
        {/*   <Badge badgeContent={5} color="secondary"> */}
        {/*     <UserIcon /> User/}
        {/*   </Badge> */}
        {/* </IconButton> */}
        <IconButton
          color="inherit"
          onClick={(e) => {
            userMenuAnchor.current = e.target;
            setIsUserMenuOpen(!isUserMenuOpen);
          }}
          size="large">
          <SettingsIcon />
        </IconButton>
        <Menu
          anchorEl={userMenuAnchor.current}
          keepMounted
          open={isUserMenuOpen}
          onClose={() => { setIsUserMenuOpen(false); }}
        >
          {
            (contextValue.user.type === USER_TYPE_OWNER && contextValue.stores.length > 1) ? <MenuItem
              onClick={() => {
                contextValue.setStore(null);
              }}
            >
              <StoreIcon />
              {t('changeStore')}
            </MenuItem> : null
          }

          {
            contextValue.user.type === USER_TYPE_OWNER ? <MenuItem
              onClick={() => {
                props.history.push(Urls.StoreAdd);
              }}
            >
              <StorefrontSharp />
              {t('addStore')}
            </MenuItem> : null
          }

          <MenuItem
            onClick={() => {
              setIsUserMenuOpen(false);
              props.history.push(Urls.OwnerProfileEdit);
            }}
          >
            <PersonIcon />
            {t('profile')}
          </MenuItem>
          <MenuItem
            onClick={() => {
                setIsUserMenuOpen(false);
                props.history.push(Urls.Logout);
            }}
          >
            <ExitToAppIcon />
            {t('logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
    <Drawer
      anchor="left"
      variant="persistent"
      open={isSidebarOpen}
      onClose={toggleMenu}
    >
      {isSidebarOpen && <div className={classes.appBarSpacer} />}
      <List className={classes.drawerPaper}>
        <SideBar />
      </List>
    </Drawer>
  </>;
}

export default withRouter(Topbar);
