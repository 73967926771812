import Url from './constants/urls';

const Sidebar = [
  {
    id: 'new-product-root',
    text: 'New Product',
    icon: 'AppsIcon',
    url: Url.ProductDashboard,
  },
]

export default Sidebar;

