import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  formControl: {
    textAlign: 'left',
    width: '100%',
  },
  selectEmpty: {
    width: '100%',
  },
  canvas: {
    width: '100%',
    height: 'auto',
    objectfit: 'cover',
    objectposition: 'center'
  },
  video : {
    display: 'hidden',
    height: '0px',
    width: '100%',
    textAlign: 'center'
  },
  scannerIcon : {
    position: 'absolute',
    right: '63px',
    top: '17px',
    cursor: 'pointer',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default styles;
