import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import { GlobalContext } from 'src/GlobalContextProvider';
import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import Apiurls from 'src/constants/apiUrls';
import VendorForm from './partials/vendorForm';

const breadcrumbItems = [
    {
        url: Urls.Dashboard,
        title: 'Dashboard',
    },
    {
        title: 'Vendor',
        url: Urls.Store,
    },
    {
        title: 'Add',
    },
];

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class ManageVendor extends Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            originalData: {},
            inProgress: false,
            isLoadingData: !!(this.props.match && this.props.match.params.id),
        }
    }

    componentDidMount() {
        this.getStoreVendor();
      }

      getStoreVendor = async () => {
        if (this.props.match && this.props.match.params.id) {
          this.setState({ isLoadingData: true, inProgress: false });
          const res = await axiosInstance.get(Apiurls.STORE_VENDOR_EDIT(this.props.match.params.id));
          if (res.data.success) {
            this.setState({
              originalData: res.data.data,
              formData: { ...res.data.data },
              isLoadingData: false,
            });
          }
        }
      }

    ResetStates = () => {
        this.setState({
            formData: {},
        });
    }

    onSave = async (data) => {
        try {

            this.setState({ inProgress: true });
            const { originalData } = this.state;
            const body = {
                vendorOrganization: data.vendorOrganization,
                address: data.address,
                contact: data.contact,
                emergencyContact: data.emergencyContact,
                cutOffTime: data.cutOffTime,
                whatsapp: data.whatsapp,
                email: data.email
            };

            let res;
            if (originalData.id) {
                res = await axiosInstance.put(Apiurls.STORE_VENDOR_EDIT(originalData.id), body);
            } else {
                res = await axiosInstance.post(Apiurls.STORE_VENDOR, body);
            }

            if (res.data.success) {
                window.mNotify.show(originalData.id ? 'Updated successfully' : 'Created successfully', 'success');
                return this.props.history.push(Urls.StoreVendorPrefix);
            }
            throw new Error(res.data.error);
        } catch (error) {
            console.log(error);
            this.setState({ inProgress: false });
            window.mNotify.error('Error while creating vendor. Try again.');
        }
    }

    render() {
        const { history } = this.props;
        const { formData, inProgress, isLoadingData } = this.state;
        return (
            <div className={this.props.classes.paper}>
                <MyBreadCrumbs
                    items={breadcrumbItems}
                    history={history}
                />
                <h2>Add Vendor</h2>
                {
                    isLoadingData ?
                        <CircularProgress color="inherit" size={14} />
                        :
                        <VendorForm
                            data={formData}
                            inProgress={inProgress}
                            onSave={this.onSave}
                        />
                }
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(ManageVendor));
