import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

require('./copyright.scss');

function AppLoggedInFooter() {
  return (
    <div className="app-logged-in-footer">
      <Typography
        variant="body2"
        color="textSecondary"
      >
        <b>mPower.</b> {__APP_NAME__}
      </Typography>
    </div>
  );
}

export default AppLoggedInFooter;
