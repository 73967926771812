import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import { GlobalContext } from 'src/GlobalContextProvider';
import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import Apiurls from 'src/constants/apiUrls';
// import OwnerForm from './partials/ownerForm';
import UserForm from './partials/userForm';
import LoadingIndicator from 'src/components/loader/indicator';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
});

class ManageOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      originalData: {},
      inProgress: false,
      isLoadingData: false,
    }
  }

  componentDidMount() {
    this.editOwnerDetails();
  }

  editOwnerDetails = async () => {
    if (this.context.owner && this.context.owner.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.OWNER);
      if (res.data.success && res.data.data.id) {
        let addressData = JSON.parse(res.data.data.ownerAddress || '{}');
        let entries = Object.entries(addressData);
        entries.forEach(([prop, val]) => res.data.data[prop] = val);
        this.setState({
          originalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    } else {
      //return to login page
      this.props.history.push(Urls.Logout);
    }
  }

  resetStates = () => {
    this.setState({
      formData: {},
    });
  }

  onUserSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const res = await axiosInstance.put(Apiurls.OWNER, data);
      if (res.data.success) {
        window.mNotify.success('Updated successfully');
        return this.props.history.goBack();
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      window.mNotify.error('Error while updating owner. Try again.');
    }
    this.setState({ inProgress: false });
  }


  render() {
    const { formData, inProgress, isLoadingData } = this.state;
    return (
      <div className={this.props.classes.paper}>
        {
          isLoadingData ?
            <LoadingIndicator />
            :
            <>
              <UserForm
                data={formData}
                inProgress={inProgress}
                onSave={this.onUserSave}
              />
              {/* <OwnerForm
                data={formData}
                inProgress={inProgress}
                onSave={this.onSave}
              /> */}
            </>
        }
      </div>
    );
  }
}
ManageOwner.contextType = GlobalContext;
export default withRouter(withStyles(useStyles)(ManageOwner));
