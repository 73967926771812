import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';

import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

const breadcrumbItems = [
    {
        url: Urls.Dashboard,
        title: 'Dashboard',
    },
    {
        title: 'Vendor',
    },
];

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

class ListVendor extends Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            inProgress: true,
            vendors: [],
        };
    }

    componentDidMount() {
        this.fetchVendor();
    }

    fetchVendor = async () => {
        try {
            this.setState({
                inProgress: true,
            });
            const res = await axiosInstance.get(Apiurls.STORE_VENDOR_LIST);
            if (res.data.success) {
                this.setState({
                    inProgress: false,
                    vendors: res.data.data,
                });
                return;
            }
            throw new Error(res.data.message);
        } catch (ex) {
            window.mNotify.error('Error while fetching vendors');
        }

        this.setState({
            inProgress: false,
        });
    }

    deleteStoreVendor = async (id) => {
        const res = await axiosInstance.delete(Apiurls.STORE_VENDOR_EDIT(id));
        if (res.data.success) {
            this.fetchVendor();
            window.mNotify.show('Deleted the vendor successfully.');
        } else {
            window.mNotify.show('Sorry!.. Could not delete the vendor');
        }
    }

    render() {
        const { inProgress, vendors } = this.state;
        const { history } = this.props;

        return (
            <div className={this.props.classes.paper}>
                <MyBreadCrumbs
                    items={breadcrumbItems}
                    history={history}
                />
                <h2>Vendor</h2>
                <div className={this.props.classes.titleContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            history.push(Urls.StoreVendorAdd);
                        }}
                    >
                        Create New
          </Button>
                </div>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Organization Name</TableCell>
                                <TableCell>Vendor UID</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Whats App</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                vendors.map((row, key) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <CheckIcon
                                                size={12}
                                                style={{
                                                    display: 'inline-block',
                                                    color: row.isActive ? 'green' : 'grey',
                                                }}
                                            />
                                            <span>{row.vendorOrganization}</span>
                                        </TableCell>
                                        <TableCell>
                                            {row.vendorId}
                                        </TableCell>
                                        <TableCell>
                                            {row.address}
                                        </TableCell>
                                        <TableCell>
                                            {row.contact}
                                        </TableCell>
                                        <TableCell>
                                            {row.email}
                                        </TableCell>
                                        <TableCell>
                                            {row.whatsapp}
                                        </TableCell>
                                        <TableCell>
                                            <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                                                <Button
                                                    onClick={(e) => {
                                                        history.push(Urls.StoreVendorEdit(row.id));
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    onClick={(e) => {
                                                        if (window.confirm(`Are you sure you want to delete ${row.vendorOrganization} ?`)) {
                                                            this.deleteStoreVendor(row.id);
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    {
                        inProgress && <CircularProgress />
                    }
                </TableContainer>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(ListVendor));
