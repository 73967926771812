import Sidebar from './sidebar';
import Url from './constants/urls';
import ApiUrls from './constants/apiUrls';

import ListProductType from './listProductType';
import ManageTypeProduct from './manageTypeProduct';

import ListProductUnit from './listProductUnit';
import ManageProductUnit from './manageProductUnit';

import ListProductHierarchy from './listProductHierarchy';
import ManageProductHierarchy from './manageProductHierarchy';

import ListPriceMaster from './listPriceMaster';
import ManagePriceMaster from './managePriceMaster';

import ListDiscountMaster from './listDiscountMaster';
import ManageDiscountMaster from './manageDiscountMaster';

import ManageMasterProduct from './manageMasterProduct';
import ListProductMasterMap from './ListProductMasterMap';

import ManageProductDashboard from './manageProductDashboard';

const Routes = [
  {
    url: Url.ProductDashboard,
    component: ManageProductDashboard,
  },
  {
    url: Url.ProductType,
    component: ListProductType,
  },
  {
    url: Url.ProductTypeAdd,
    component: ManageTypeProduct,
  },
  {
    url: Url.ProductTypeEdit(':id'),
    component: ManageTypeProduct,
  },

  {
    url: Url.ProductPriceMaster(':masterProductId'),
    component: ListPriceMaster,
  },
  {
    url: Url.ProductPriceMasterAdd(':masterProductId'),
    component: ManagePriceMaster,
  },
  {
    url: Url.ProductPriceMasterEdit(':masterProductId', ':id'),
    component: ManagePriceMaster,
  },

  {
    url: Url.ProductDiscountMaster(':masterProductId'),
    component: ListDiscountMaster,
  },
  {
    url: Url.ProductDiscountMasterAdd(':masterProductId'),
    component: ManageDiscountMaster,
  },
  {
    url: Url.ProductDiscountMasterEdit(':masterProductId', ':id'),
    component: ManageDiscountMaster,
  },

  {
    url: Url.ProductUnit,
    component: ListProductUnit,
  },
  {
    url: Url.ProductUnitAdd,
    component: ManageProductUnit,
  },
  {
    url: Url.ProductUnitEdit(':id'),
    component: ManageProductUnit,
  },
  {
    url: Url.ProductHierarchy,
    component: ListProductHierarchy,
  },
  {
    url: Url.ProductHierarchyAdd,
    component: ManageProductHierarchy,
  },
  {
    url: Url.ProductHierarchyEdit(':id'),
    component: ManageProductHierarchy,
  },
  {
    url: Url.ProductMasterProduct,
    component: ListProductMasterMap,
  },
  {
    url: Url.ProductMasterProductAdd,
    component: ManageMasterProduct,
  },
  {
    url: Url.ProductMasterProductEdit(':id'),
    component: ManageMasterProduct,
  }
]

const App = {
  name: 'New Products',
  routers: Routes,
  apiUrls: ApiUrls,
  menu: Sidebar,
};

export default App;
