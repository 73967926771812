import React from 'react';

import Loader from 'src/components/loader';

import {
  USER_TYPE_OWNER,
  // USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import axiosInstance from 'src/http';

// TODO: Move connector to on demand
function InitConnector(WrappedComponent) {
  class InitConnectors extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        connectorStatus: false,
        connector: {
          configuration: {
            inProgress: false,
            connectorsRoutesDetails: [],
            connectorsMenuDetails: [],
          },
        },
      };
    }

    async componentDidMount() {
      const contextState = this.context;
      if (contextState.user.type === USER_TYPE_OWNER) {
        await this.storeConnector();
      } else {
        this.context.setChangeStoreConnectors({
          connectors: {
            storeConnectors: {},
            storeConnectorsStatus: true,
          },
        });
      }
    }

    storeConnector = async () => {
      const contextState = this.context;
      const res = await axiosInstance.get(Apiurls.STORE_CONNECTOR_LIST);
      if (res.data.data.length !== 0) {
        this.context.setChangeStoreConnectors({
          connectors: {
            ...contextState.connectors,
            storeConnectorsAccess: {
              ...JSON.parse(res.data.data[0].connectorSetting),
            },
          },
        });
      }
      this.Connector();
    };

    Connector = () => {
      const contextState = this.context;

      if (Object.keys(contextState.connectors.storeConnectorsAccess).length) {
        Object.keys(contextState.connectors.storeConnectorsAccess).map(async (val, key) => {
            if (this.state.connector[val].connectorsRoutesDetails.length === 0 && this.state.connector[val].connectorsMenuDetails.length === 0) {
              await import('src/connectors/' + val + '/App').then( (Connector) => {

                if (Connector.default.name === val) {
                  this.setState({
                    connector: {
                      ...this.state.connector,
                      [val]: {
                        inProgress: true,
                        connectorsRoutesDetails: [...Connector.default.routers],
                        connectorsMenuDetails: [...Connector.default.menu],
                      },
                    },
                    connectorStatus: true,
                  });
                  //this.forceUpdate();
                }
              });

              contextState.setChangeStoreConnectors({
                connectors: {
                  ...contextState.connectors,
                  storeConnectors: { ...this.state.connector },
                  storeConnectorsStatus: true,
                },
              });
            }
          }
        );
      } else {
        this.context.setChangeStoreConnectors({
          connectors: {
            ...contextState.connectors,
            storeConnectorsStatus: true,
          },
        });
      }
    };

    render() {
      const contextState = this.context;
      return !contextState.connectors.storeConnectorsStatus ? (
        <Loader title="Initializing Connectors" />
      ) : (
        <WrappedComponent {...this.props} state={this.state} />
      );
    }
  }
  InitConnectors.contextType = GlobalContext;
  return InitConnectors;
}

export default InitConnector;
