import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import { GlobalContext } from 'src/GlobalContextProvider';

import ImageForm from './partials/imageForm';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ManageUploadFiles extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
    };
  }

  onUpdate = async (data) => {
    try {
      this.setState({ inProgress: true });
      this.props.onUpdate(data)
    } catch (error) {
      console.error(error);
    }
  }

  render() {

      const { inProgress } = this.state;
      const { relatedImage } = this.props;

    return (<>
    {
      inProgress ?
      <CircularProgress color="inherit" size={25} style={{ color: "blue"}} />
      : <ImageForm
          inProgress={inProgress}
          onUpdate={this.onUpdate}
          relatedImage={relatedImage}
      />
    }

    </>);
  }
}

export default withRouter(withStyles(useStyles)(ManageUploadFiles));
