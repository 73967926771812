import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';


import MyOrders from './myOrders';
import { GlobalContext } from 'src/GlobalContextProvider';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class addStore extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      action: 'ADD',
      condition: {
        storeId: '',
        storeName: '',
        brandId: '',
        vendorPrice: [],
      },
      formData: {
        storeId: '',
        orderStatus: 1,
        orderResponseStatus: 1,
        isActive: 1,
        products: [],
      },
      storeOptions: [],
      productOption: [],

    }
  }

  componentDidMount() {
    this.fetchStoreOptions();
    this.getCurrentStoreId();
  }

  getCurrentStoreId() {
    const contextState = this.context;
    this.setState({
      formData: {
        ...this.state.formData,
        storeId: contextState.storeId,
        products: [{
          vendorId: '',
          productId: '',
          brandId: '',
          productQuantity: '',
          requestDeliveryDate: '',
          budget: '',
          singlePrice: '',
          remarks: ''
        }]
      }
    });
    this.setState({
      condition: {
        ...this.state.condition,
        storeId: contextState.storeId,
        vendorPrice: [{
          brandId: '',
          proId: '',
          productVendorsPriceOption: [],
          storeBrandOption: []
        }]
      },
    });
    this.fetchProductOptions(contextState.storeId);
  }

  fetchProductVendorsPriceOptions(idx) {
    const contextState = this.context;
    //alert(idx);
    fetch('/api/store/product/price/getall', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          storeId: this.state.condition.storeId,
          brandId: this.state.condition.vendorPrice[idx].brandId,
          productId: this.state.condition.vendorPrice[idx].proId,
        })
      })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        //console.log(json.message);
        const vendorPriceOption = [...this.state.condition.vendorPrice];
        const vendorPricesOptions = { ...vendorPriceOption[idx] };
        vendorPricesOptions.productVendorsPriceOption = json.message;
        vendorPriceOption[idx] = vendorPricesOptions;
        this.setState({
          condition: {
            ...this.state.condition,
            storeId: contextState.storeId,
            vendorPrice: [{
              brandId: '',
              proId: '',
              productVendorsPriceOption: [],
              storeBrandOption: []
            }]
          },
        }, () => {
          this.fetchStoreDetails(contextState.storeId);
          this.fetchProductOptions(contextState.storeId);
        });
      })
  }


  fetchStoreDetails(getId) {
    //console.log(getId);
    fetch('/api/store/' + getId, {
        method: 'get'
      })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({
          condition: {
            ...this.state.condition,
            storeName: json[0].ownerOrganization,
          }
        })
        //console.log(json[0].id);
      });
  }

  fetchStoreOptions() {
    fetch('/api/store/getall')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        //console.log(json);
        this.setState({ storeOptions: json.values })
      });
  }

  fetchBrandOptions(value) {
    fetch('/api/store/product/brand-and-price', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          proId: value.proId,
          storeId: value.storeId,
        })
      })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.message);

        const vendorPriceOption = [...this.state.condition.vendorPrice];
        const vendorPricesOptions = { ...vendorPriceOption[value.row] };
        vendorPricesOptions.storeBrandOption = data.message;
        vendorPriceOption[value.row] = vendorPricesOptions;
        this.setState({
          condition: {
            ...this.state.condition,
            vendorPrice: vendorPriceOption
          }
        });

        //this.setState({storeBrandOption: data.message})
      });
  }

  fetchProductOptions(storeId) {
    fetch('/api/store/product/getall', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          storeId: storeId,
        })
      })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ productOption: data.message })
      });
  }

  getBrandAndPriceDetails(value) {
    if (value.storeId !== '' && value.productId !== '' && value.brandId !== '' && value.vendorId !== '') {
      fetch('/api/store/product/brand', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            storeId: value.storeId,
            productId: value.productId,
            brandId: value.brandId,
            vendorId: value.vendorId,
          })
        })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          let brandDetails = json.message[0];
          //Amount Update
          const products = [...this.state.formData.products];
          const product = { ...products[value.rows] };
          product.singlePrice = brandDetails.price;
          products[value.rows] = product;
          this.setState({
            formData: {
              ...this.state.formData,
              products: products
            },
          });
        });
    }
  }

  addEmptyStateSet() {
    const products = [...this.state.formData.products];
    products.push({
      vendorId: '',
      productId: '',
      brandId: '',
      productQuantity: '',
      requestDeliveryDate: '',
      budget: '',
      singlePrice: '',
      remarks: ''
    });
    const vendorPrice = [...this.state.condition.vendorPrice];
    vendorPrice.push({
      vendorId: '',
      proId: '',
      productVendorsPriceOption: [],
      storeBrandOption: []

    });
    this.setState({
      condition: {
        ...this.state.condition,
        vendorPrice: vendorPrice
      },
      formData: {
        ...this.state.formData,
        products: products
      },
    });

  }
  addMoreButton() {
    return (
      <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        (e) => {
                            this.addEmptyStateSet();
                        }
                    }
                    style={{margin: "5px"}}
                    >  + Add More
                    </Button>
                </Grid>
            </Grid>
    )
  }
  getOrderStructure() {
    return (
      this.state.formData.products.map((productRows, idx) =>
        <Box component="span" m={1}>
                <Paper className={this.props.classes.paper}>
                <Grid container spacing={1} key={idx}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Box pl={1} pr={1} pt={1} pb={1}>
                    <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={productRows.productId}
                            label="Product"
                            onChange={(e) => {
                                //product ID
                                const products = [...this.state.formData.products];
                                const product = { ...products[idx] };
                                product.productId = e.target.value;
                                product.singlePrice = '';
                                product.brandId = '';
                                product.productQuantity = '';
                                product.vendorId = '';
                                products[idx] = product;
                                //vendorPrice
                                const setProductArr = [...this.state.condition.vendorPrice];
                                const setProductSingleArr = { ...setProductArr[idx] };
                                setProductSingleArr.proId = e.target.value;
                                setProductArr[idx] = setProductSingleArr;
                                this.setState({
                                    condition: {
                                        ...this.state.condition,
                                        vendorPrice: setProductArr
                                    },
                                    formData:{
                                        ...this.state.formData,
                                        products: products
                                    },
                                }, ()=>{
                                    this.fetchBrandOptions({
                                        "row":idx,
                                        proId:e.target.value,
                                        storeId: this.state.condition.storeId
                                    });
                                });

                            }}
                            >
                            <MenuItem value="">
                            <em>Choose Product</em>
                            </MenuItem>
                            {this.state.productOption.map((productOption, key) => <MenuItem
                            value={productOption.id} key={key} >{productOption.productDescription}
                            </MenuItem>) }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="Product Quantity"
                            value={productRows.productQuantity}
                            variant="outlined"
                            onChange={(e)=>{
                                const setProductQuantityArr = [...this.state.formData.products];
                                const setProductQuantitySingleArr = { ...setProductQuantityArr[idx] };
                                setProductQuantitySingleArr.productQuantity = e.target.value;
                                setProductQuantityArr[idx] = setProductQuantitySingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: setProductQuantityArr
                                    },
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Box pl={1} pr={1} pt={1} pb={1}>
                    <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={productRows.brandId}
                            label="Brand"
                            onChange={(e) => {
                                //product ID
                                const products = [...this.state.formData.products];
                                const product = { ...products[idx] };
                                product.brandId = e.target.value;
                                product.singlePrice = '';
                                product.vendorId = '';
                                products[idx] = product;
                                //vendorPrice
                                const setProductArr = [...this.state.condition.vendorPrice];
                                const setProductSingleArr = { ...setProductArr[idx] };
                                setProductSingleArr.brandId = e.target.value;
                                setProductArr[idx] = setProductSingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: products
                                    },
                                    condition: {
                                        ...this.state.condition,
                                        vendorPrice: setProductArr
                                    },
                                },()=>{
                                    this.fetchProductVendorsPriceOptions(idx);
                                });
                            }}
                            >
                            <MenuItem value="">
                            <em>Choose Product</em>
                            </MenuItem>
                            {this.state.condition.vendorPrice[idx].storeBrandOption.map((brandOption, key) => <MenuItem
                            value={brandOption.brandId} key={key} >
                                {brandOption.brandName}
                            </MenuItem>) }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Box pl={1} pr={1} pt={1} pb={1}>
                        <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Vendors</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={productRows.vendorId}
                            label="Vendors"
                            onChange={(e) => {
                                //product ID
                                const setVendorArr = [...this.state.formData.products];
                                const setVendorSingleArr = { ...setVendorArr[idx] };
                                setVendorSingleArr.vendorId = e.target.value;
                                setVendorArr[idx] = setVendorSingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: setVendorArr
                                    },
                                },()=>{
                                    this.getBrandAndPriceDetails({
                                        storeId: this.state.condition.storeId,
                                        productId:this.state.formData.products[idx].productId,
                                        brandId:this.state.formData.products[idx].brandId,
                                        vendorId:this.state.formData.products[idx].vendorId,
                                        rows:idx
                                    });
                                });
                            }}
                            >
                            <MenuItem value="">
                            <em>Choose Vendor</em>
                            </MenuItem>
                            { this.state.condition.vendorPrice[idx].productVendorsPriceOption.map((productVendorsPriceOption, key) => <MenuItem
                            value={productVendorsPriceOption.VendorId} key={key} >
                            {productVendorsPriceOption.vendorOrganization} - Rs.{productVendorsPriceOption.price} -       {productVendorsPriceOption.unit} </MenuItem>) }

                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="datetime-local"
                            label="Request Delivery Date"
                            type="datetime-local"
                            fullWidth
                            value={productRows.requestDeliveryDate}
                            variant="outlined"
                            onChange={(e)=>{
                                //product ID
                                const setRequestDeliveryDateArr = [...this.state.formData.products];
                                const setRequestDeliveryDateSingleArr = { ...setRequestDeliveryDateArr[idx] };
                                setRequestDeliveryDateSingleArr.requestDeliveryDate = e.target.value;
                                setRequestDeliveryDateArr[idx] = setRequestDeliveryDateSingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: setRequestDeliveryDateArr
                                    },
                                });
                            }}
                            className={this.props.classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="Budget"
                            value={productRows.budget}
                            variant="outlined"
                            onChange={(e)=>{
                                const setBudgetArr = [...this.state.formData.products];
                                const setBudgetSingleArr = { ...setBudgetArr[idx] };
                                setBudgetSingleArr.budget = e.target.value;
                                setBudgetArr[idx] = setBudgetSingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: setBudgetArr
                                    },
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            label="remarks"
                            value={productRows.remarks}
                            variant="outlined"
                            onChange={(e)=>{
                                const setRemarksArr = [...this.state.formData.products];
                                const setRemarksSingleArr = { ...setRemarksArr[idx] };
                                setRemarksSingleArr.remarks = e.target.value;
                                setRemarksArr[idx] = setRemarksSingleArr;
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: setRemarksArr
                                    },
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    <Box  mt={2} >
                      <Fab size="small"
                        color="secondary"
                        aria-label="add"
                        onClick={
                            (e)=>{
                                //remove state formData
                                var products = [...this.state.formData.products];
                                // const product = { ...products[idx] };
                                products.splice(idx, 1);

                                //remove state condition
                                var removeVendorPriceArr = [...this.state.condition.vendorPrice];
                                removeVendorPriceArr.splice(idx, 1);
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        products: products
                                    },
                                    condition:{
                                        ...this.state.condition,
                                        vendorPrice: removeVendorPriceArr
                                    }
                                });
                            }
                        }
                        className={this.props.classes.margin}
                        >
                        <DeleteIcon fontSize="small" />
                        </Fab>
                    </Box>
                </Grid>
            </Grid>
            </Paper>
            </Box>
      )
    )
  }


  ResetStates = () => {
    this.setState({
      storeId: '',
      action: 'ADD',
      condition: {
        storeId: '',
        vendorPrice: [{
          brandId: '',
          proId: '',
          productVendorsPriceOption: [],
          storeBrandOption: []
        }]
      },
      formData: {
        storeId: '',
        orderStatus: 1,
        orderResponseStatus: 1,
        isActive: 1,
        products: [{
          vendorId: '',
          productId: '',
          brandId: '',
          productQuantity: '',
          requestDeliveryDate: '',
          budget: '',
          singlePrice: '',
          remarks: ''
        }]
      },
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    try {
      await fetch('/api/order-request', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            storeId: this.state.formData.storeId,
            orderStatus: this.state.formData.orderStatus,
            orderResponseStatus: this.state.formData.orderResponseStatus,
            isActive: this.state.formData.isActive,
            products: this.state.formData.products,
            storeUpdateId: ""
          })
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            window.mNotify.show(data.message, 'success');
            this.setState({
              message: data.message,
            });
            this.ResetStates();
          } else {
            window.mNotify.show('Sorry!.. Store details has been added unsuccessfully');
            this.setState({
              message: 'Sorry!.. Store details has been added unsuccessfully',
            })
          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
            <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="/"
              onClick={
                  (e) => {
                      this.props.history.push('/');
                  }
              }
              underline="hover">
            Dashboard
            </Link>
            <Typography >Orders</Typography>
            <Typography color="textPrimary">Order Request</Typography>
            </Breadcrumbs>
              <h2>Order Request</h2>
              <form onSubmit={this.onSubmit.bind(this)} className={this.props.classes.root}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>

                    <Box pl={2} pr={2} pt={2} pb={2}>
                        <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Store</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.formData.storeId}
                            label="Store"
                            >
                            <MenuItem value={this.state.condition.storeId} selected>{this.state.condition.storeName}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                </Grid>
                {this.getOrderStructure()}

                {this.addMoreButton()}
                <MyOrders dataFromDate = {this.state.formData} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} align="center">
                                <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
                                {this.state.action}
                                </Button>
                                <Button variant="contained" color="secondary" style={{margin: "5px"}}
                                onClick={(e)=>{
                                    this.ResetStates();
                                }}>
                                Reset
                                </Button>
                                <p>{this.state.message}</p>
                        </Grid>
                </Grid>


            </form>
            </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(addStore));
