import React from 'react';
import { LOCAL_STORAGE_LOGIN_OBJECT } from 'src/constants/index';

export const GlobalContext = React.createContext();

const CONTEXT_INITIAL_STATE = {
  currentStore: null,
  features: {
    storeFeaturesAccess: {},
    storeFeatures: {},
    storeFeaturesStatus: false,
  },
  newFeatures: {
    storeNewFeaturesAccess: {},
    storeNewFeatures: {},
    storeNewFeaturesStatus: false,
  },
  modules: {
    storeModulesAccess: {},
    storeModules: {},
    storeModulesStatus: false,
  },
  connectors: {
    storeConnectorsAccess: {},
    storeConnectors: {},
    storeConnectorsStatus: false,
    connectorConfig: {}
  },
  user: {
    type: '',
  },
  stores: {},
  vendor: {},
  config: {
    industrialType: __INDUSTRY_TYPE__,
    industrialHierarchy: __INDUSTRY_HIERARCHY__,
  },
};

class GlobalContextContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // changeStore: this.changeStore,
      // clearState: this.clearState,
      loginUser: this.loginUser,
      updateOwnerMeta: this.updateOwnerMeta,
      setStore: this.setStore,
      setVendor: this.setVendor,
      setChangeStoreNewFeatures: this.setChangeStoreNewFeatures,
      setChangeStoreFeatures: this.setChangeStoreFeatures,
      setChangeStoreModules: this.setChangeStoreModules,
      setChangeStoreConnectors: this.setChangeStoreConnectors,
      logout: this.logout,
      updateStores: this.updateStores,
      ...CONTEXT_INITIAL_STATE,
    };

    const storedUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_LOGIN_OBJECT) || '{}'
    );
    if (storedUser && storedUser.user && storedUser.user.id) {
      this.state = {
        ...this.state,
        ...storedUser,
      };
    }
  }

  componentDidUpdate() {
    localStorage.setItem(
      LOCAL_STORAGE_LOGIN_OBJECT,
      JSON.stringify({
        user: this.state.user,
        vendor: this.state.vendor,
        owner: this.state.owner,
        stores: this.state.stores,
        currentStore: this.state.currentStore,
        features: this.state.features,
        newFeatures: this.state.newFeatures,
        modules: this.state.modules,
        constructors: this.state.constructors,
      })
    );
  }

  loginUser = (data) => {
    this.setState(
      {
        user: data.user,
      },
      () => {
        this.setVendor(data[this.state.user.type]);
      }
    );
  };

  logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_LOGIN_OBJECT);
    this.setState({
      ...CONTEXT_INITIAL_STATE,
    });
  };

  updateOwnerMeta = (meta) => {
    const newState = {
      owner: meta.owner,
      stores: this.formatStores(meta.stores),
    };
    newState.currentStore = newState.stores[this.state.currentStore] ? this.state.currentStore : null;
    this.setState(newState);
  }

  /**
   * Sets the current store by ID. In case allstores is passed in, it will be set into the state
   * @param {String} id Id of the store. This id should be present in the second argument or in the state `stores`.
   * @param {Array} [allStores] All stores
   */
  setStore = (id, allStores) => {
    const newAllStores = allStores ? this.formatStores(allStores) : this.state.stores;
    this.setState({
      stores: newAllStores,
      currentStore: newAllStores[id] ? id : null,
    });
  };

  setVendor = (data) => {
    this.setState({
      vendor: { ...data },
    });
  };

  setChangeStoreModules = (data) => {
    this.setState({
      modules: { ...data.modules },
    });
  }

  setChangeStoreFeatures = (data) => {
    this.setState({
      features: { ...data.features },
    });
  };

  setChangeStoreNewFeatures = (data) => {
    this.setState({
      newFeatures: { ...data.newFeatures },
    });
  };

  setChangeStoreConnectors = (data) => {
    this.setState({
      connectors: {
        ...this.state.connectors,
        ...data.connectors
      },
    });
  };

  formatStores = (stores) => {
    const newStores = {};
    stores.forEach((store) => {
      newStores[store.id] = store;
    });
    return newStores;
  };

  updateStores = (stores) => {
    const newStores = this.formatStores(stores);

    this.setState({
      stores: newStores,
      currentStore: newStores[this.state.currentStore] ? this.state.currentStore : null,
    });
  }

  render() {

    return (
      <GlobalContext.Provider value={this.state}>
        <React.Fragment key={this.state.user ? `user-${this.state.user.id}` : 'not-logged-in'}>
          {this.props.children}
        </React.Fragment>
      </GlobalContext.Provider>
    );
  }
}

export default GlobalContextContainer;
