import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const useStyles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  });

class AddVendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message:'',
            formData: {
                MSubCategoryCatID: '',
                MSubCategoryName: '',
                MSubCategoryStatus: 1
              },
              options: [],
            ownerNameError:false
        }
    }

    componentDidMount(){
        this.fetchOptions();
    }

    fetchOptions(){
        fetch('/api/mcategory/getall')
        .then((res) => {
            return res.json();
        }).then((json) => {
            //console.log(json.values);
            this.setState({options: json.values})
        });
    }

    ResetStates = () => {
        this.setState({
            formData: {
                MSubCategoryCatID: '',
                MSubCategoryName: '',
                MSubCategoryStatus: '1'
            },
        });
    }


    async onSubmit(e)
    {
        e.preventDefault();
        try {
                await fetch('/api/msubcategory' ,{
                method : 'post',
                headers : {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                },
                body : JSON.stringify({
                    categoryId : this.state.formData.MSubCategoryCatID,
                    name : this.state.formData.MSubCategoryName,
                    isActive : this.state.formData.MSubCategoryStatus,
                    id : ""
                })
            }).then((response) => response.json()).then((data) => {
                if(data.success === true){
                    window.mNotify.show(data.message, 'success');
                    this.setState({
                        message : data.message,
                    });
                    this.ResetStates();
                }
                else{
                    window.mNotify.show('Sorry!.. Master Sub Category details has been added unsuccessfully');
                    this.setState({
                        message : 'Sorry!.. Master Sub Category details has been added unsuccessfully',
                    })
                }
            })
        }
        catch (error)
        {
            console.log(error);
        }
    }

    render()
    {
        return (
            <div>
             <Breadcrumbs aria-label="breadcrumb">
            <Link
                color="inherit"
                href="/"
                onClick={
                    (e) => {
                        this.props.history.push('/');
                    }
                }
                underline="hover">
            Home
            </Link>
            <Typography >Manage Masters</Typography>
            <Typography color="textPrimary">Sub Category</Typography>
            </Breadcrumbs>
              <h2>Add Sub Category</h2>
              <form onSubmit={this.onSubmit.bind(this)} className={this.props.classes.root}>

            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                    <Box pl={2} pr={2} pt={2} pb={2}>
                        <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>

                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.formData.MSubCategoryCatID}
                            label="Category"
                            onChange={(e) => {
                            this.setState({
                            formData: {
                            ...this.state.formData,
                            MSubCategoryCatID: e.target.value,
                            },
                            });
                            }}
                            >
                            <MenuItem value="">
                            <em>Choose Category</em>
                            </MenuItem>

                            { this.state.options.map((option, key) => <MenuItem value={option.id} key={key} >{option.name}</MenuItem>) }

                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            required
                            id="outlined-basic"
                            fullWidth
                            value={this.state.formData.MSubCategoryName}
                            label="Category Name"
                            variant="outlined"
                            onChange={(e)=>{
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        MSubCategoryName: e.target.value,
                                    },
                                })
                            }}
                        />
                    </div>
                </Grid>



                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                    <FormControl component="fieldset" fullWidth style={{textAlign: 'left'}}>
                        <FormLabel component="legend"  >Status</FormLabel>
                        <RadioGroup row aria-label="position"
                        onChange={(e)=>{

                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    MSubCategoryStatus: e.target.value,
                                },
                            })
                        }}
                        value="1"
                         name="MSubCategoryStatus" defaultValue="top">
                            <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Active"
                            />
                            <FormControlLabel
                            value="0"

                            control={<Radio color="primary" />}
                            label="Inactive"
                            />
                        </RadioGroup>
                    </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} align="center">
                        <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
                        Add
                        </Button>
                        <Button variant="contained" color="secondary" style={{margin: "5px"}}
                        onClick={(e)=>{
                            this.ResetStates();
                        }}>
                        Reset
                        </Button>
                        <p>{this.state.message}</p>
                </Grid>



            </Grid>
            </form>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(AddVendor));
