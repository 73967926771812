import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import { GlobalContext } from 'src/GlobalContextProvider';
import ProductDashboard from './partials/productDashboard';
import ManageMasterProduct from './manageMasterProduct';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ManageProductDashboard extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      originalData: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
    }
  }
  // async componentDidMount() {
  //   await this.productAsync();
  // }
  // async productAsync() {
  //   const res = await axiosInstance.get(Apiurls.PRODUCT);
  // }
  render() {
    const { history } = this.props;
    const { isLoadingData } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>Product Dashboard</h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} /> :
            <ProductDashboard
              history={history}
            />
        },
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} /> :
            <ManageMasterProduct isbreadCrumb={false}/>
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageProductDashboard));
