import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  error: {
    borderColor: 'red',
  },

}));

export default styles;
