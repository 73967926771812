import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';


import styles from 'src/styles/vendorForm';
import { vendorFormSchema } from "../validation/formValidation";

const VendorForm = ({ data = {}, errorMessage, inProgress, onSave }) => {

    const classes = styles();

    const [formData, setFormData] = React.useState({ ...data });
    const [validationDataError, validationError] = React.useState({});

    const validateForm = useCallback((formData) => {

      let errors = {};
      let formIsValid = true;
      try {
        vendorFormSchema.validateSync(formData, {
          abortEarly: false,
        });
      } catch (err) {
        err.inner.forEach(element => errors[element.path] = element.message);
        formIsValid = false;
      }
      return [formIsValid, errors];

    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const [isValid, errors] = validateForm(formData);
        validationError({ errors });
        if (isValid) {
          onSave({
              ...data,
              ...formData
          });
        } else {
            window.mNotify.error('Validation error occurs!');
        }
    };

    return (
        <form onSubmit={onSubmit}
            className={classes.root}
            disabled={inProgress}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            error={!!(validationDataError.errors && validationDataError.errors.vendorOrganization)}
                            helperText={validationDataError.errors ? validationDataError.errors.vendorOrganization : ''}
                            fullWidth
                            value={formData.vendorOrganization}
                            label="Vendor Organization"
                            variant="outlined"
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    vendorOrganization: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            error={!!(validationDataError.errors && validationDataError.errors.address)}
                            helperText={validationDataError.errors ? validationDataError.errors.address : ''}
                            fullWidth
                            value={formData.address}
                            label="Owner Address"
                            variant="outlined"
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    address: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            error={!!(validationDataError.errors && validationDataError.errors.contact)}
                            helperText={validationDataError.errors ? validationDataError.errors.contact : ''}
                            fullWidth
                            type="number"
                            value={formData.contact}
                            label="Contact No"
                            variant="outlined"
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    contact: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            error={!!(validationDataError.errors && validationDataError.errors.emergencyContact)}
                            helperText={validationDataError.errors ? validationDataError.errors.emergencyContact : ''}
                            fullWidth
                            type="number"
                            value={formData.emergencyContact}
                            label="Emergency Contact"
                            variant="outlined"
                            onChange={(e) => {

                                setFormData({
                                    ...formData,
                                    emergencyContact: e.target.value,
                                });

                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                        <InputLabel >
                            Cut Off Time
                    </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={formData.cutOffTime}
                            label="Cut Off Time"
                            error={!!(validationDataError.errors && validationDataError.errors.cutOffTime)}
                            helperText={validationDataError.errors ? validationDataError.errors.cutOffTime : ''}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    cutOffTime: e.target.value,
                                });
                            }}
                            >
                            <MenuItem value="" >
                                <em>Choose Cut Off Time</em>
                            </MenuItem>
                            <MenuItem value="1800">30 mins</MenuItem>
                            <MenuItem value="2700">45 mins</MenuItem>
                            <MenuItem value="3600">1 hour</MenuItem>
                            <MenuItem value="7200">2 hour</MenuItem>
                        </Select>
                    </FormControl>
                    {
                    validationDataError.errors && validationDataError.errors.cutOffTime ?
                    <FormHelperText id="my-helper-text" style={{ color: "red" }}>{validationDataError.errors.cutOffTime}</FormHelperText>
                    : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            error={!!(validationDataError.errors && validationDataError.errors.whatsapp)}
                            helperText={validationDataError.errors ? validationDataError.errors.whatsapp : ''}
                            fullWidth
                            label="Whatsapp"
                            type="number"
                            value={formData.whatsapp}
                            variant="outlined"
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    whatsapp: e.target.value,
                                });

                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            error={!!(validationDataError.errors && validationDataError.errors.email)}
                            helperText={validationDataError.errors ? validationDataError.errors.email : ''}
                            label="email"
                            type="email"
                            value={formData.email}
                            variant="outlined"
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    email: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.paper}>
                        <FormControl component="fieldset" fullWidth style={{ textAlign: 'left' }}>
                            <FormLabel component="legend"  >Status</FormLabel>
                            <RadioGroup row aria-label="position"
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        MCategoryStatus: e.target.value,
                                    });
                                }}
                                value="1"
                                name="MCategoryStatus" defaultValue="top">
                                <FormControlLabel
                                    value="1"
                                    control={<Radio color="primary" />}
                                    label="Active"
                                />
                                <FormControlLabel
                                    value="0"
                                    control={<Radio color="primary" />}
                                    label="Inactive"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} align="center">
                    <Button variant="contained" type="submit" color="primary" style={{ margin: "5px" }}>
                        {inProgress && <CircularProgress size={12} />}
                        {data.id ? 'Update' : 'Create'}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: '5px' }}
                        onClick={(e) => {
                            setFormData({ ...data });
                        }}
                    >
                        Reset
          </Button>
                    <p>{errorMessage}</p>
                </Grid>

            </Grid>
        </form>);

}
export default VendorForm;
