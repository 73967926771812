import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

//UI Language
import { withTranslation } from 'react-i18next';

import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import axiosInstance from 'src/http';

import Urls from 'src/constants/urls';

import {
  forgotPasswordFormSchema,
  verifyOTPFormSchema,
  resetpasswordFormSchema,
} from './formValidation';
import LoadingIndicator from 'src/components/loader/indicator';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      otpSent: false,
      otpVerified: false,
      // TODO: Remove this after email/sms implementation
      tempMessage: ''
    };
  }

  onValidateForm = (formData) => {
    let errors = {};
    let formIsValid = true;

    try {
      if (this.state.otpSent) {
        verifyOTPFormSchema.validateSync(formData, {
          abortEarly: true,
        });
      } else if (this.state.otpVerified) {
        resetpasswordFormSchema.validateSync(formData, {
          abortEarly: true,
        });
      } else {
        forgotPasswordFormSchema.validateSync(formData, {
          abortEarly: true,
        });
      }
    } catch (err) {
      err.inner.forEach(element => errors[element.path] = element.message);
      formIsValid = false;
    }
    return [formIsValid, errors];
  };

  onSubmit = async (e, contextState) => {
    e.preventDefault();

    // is submit already in progress..?? Then get out
    if (this.state.inProgress) {
      return;
    }
    try {
      const [isValid, errors] = await this.onValidateForm(this.state.formData);

      // Form Schema error set in state
      this.setState({ formErrors: errors });

      if (isValid) {
        this.setState({ inProgress: true });
        // TODO: refactor
        // maintain this order of if/else
        if (this.state.otpVerified) {
          const res = await axiosInstance.post(Apiurls.ResetPassword, this.state.formData);
          if (res.data.success) {
            window.mNotify.show('Password changed successfully !', { variant: 'success' });
            this.props.history.push(Urls.Login);
            return;
          } else {
            window.mNotify.show(res.data.message || 'Failed to reset your password. Please try again!', { variant: 'error' });
            this.setState({ inProgress: false });
          }
        } else if (this.state.otpSent) {
          const res = await axiosInstance.post(Apiurls.ForgotPasswordVerifyOTP, this.state.formData);
          if (res.data.success) {
            this.setState({
              inProgress: false,
              otpVerified: true,
            });
            // contextState.loginUser(res.data);
          } else {
            window.mNotify.show(res.data.message || 'No such email/phone exists', { variant: 'error' });
            this.setState({ inProgress: false });
          }
        } else {
          const res = await axiosInstance.post(Apiurls.ForgotPasswordSendOTP, this.state.formData);
          if (res.data.success) {
            this.setState({
              tempMessage: `Oooo..! OTP is ${res.data.otp}`,
              otpSent: true,
              otpVerified: false,
              inProgress: false,
            });
          } else {
            window.mNotify.show('No such email/phone exists', { variant: 'error' });
            this.setState({ inProgress: false });
          }
        }
      } else {
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        this.setState({ inProgress: false });
      }
    } catch (ex) {
      console.error(ex);
      window.mNotify.show(this.props.t('somethingwentwrong'), { variant: 'error' });
      this.setState({ inProgress: false });
    }
  };

  render() {
    const { t } = this.props;
    const { inProgress, formErrors, otpSent, tempMessage, otpVerified } = this.state;

    return (
      <Grid className="login-container">
        <h2>{t('forgotPasswordForm')}</h2>
        <GlobalContext.Consumer>
          {(contextState) => (
            <form
              onSubmit={(e) => this.onSubmit(e, contextState)}
              className="login-form"
            >
              <TextField
                disabled={otpSent}
                error={
                  (formErrors && formErrors.username)
                }
                helperText={formErrors ? formErrors.username : ''}
                type="text"
                onChange={(e) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      username: e.target.value,
                    },
                  });
                }}
                fullWidth
                value={this.state.username}
                id="email"
                name="username"
                autoComplete="email"
                label={t('emailOrPhone')}
              />
              {
                otpSent &&
                <TextField
                  disabled={otpVerified}
                  error={
                    (formErrors && formErrors.otp)
                  }
                  helperText={formErrors ? formErrors.otp : ''}
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        otp: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  value={this.state.otp}
                  id="otp"
                  name="otp"
                  autoComplete="onetime-code"
                  label={t('otp')}
                />
              }
              {
                otpVerified &&
                <TextField
                  error={
                    (formErrors && formErrors.password)
                  }
                  helperText={formErrors ? formErrors.password : ''}
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        password: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  value={this.state.password}
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  label={t('password')}
                />
              }
              <div className="actions-row">
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                  disabled={inProgress}
                  endIcon={inProgress && <LoadingIndicator />}
                >
                  {t(otpSent ? (otpVerified ? 'setPassword' : 'verifyOTP') : 'sendOTP')}
                </Button>
                <Link component={RouterLink} to={Urls.Login} underline="hover">{t('loginForm')}</Link>
              </div>
              {tempMessage && <><br/>{tempMessage}</>}
            </form>
          )}


          </GlobalContext.Consumer>
      </Grid>
    );
  }
}

export default withTranslation()(ForgotPassword);
