import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Unit',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ListProductUnit extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      productUnit: [],
    };
  }

  componentDidMount() {
    this.fetchProductUnit();
  }

  fetchProductUnit = async (ownerId) => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.PRODUCT_UNIT_LIST);
      if (res.data.success) {
        this.setState({
          inProgress: false,
          productUnit: res.data.data,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error('Error while fetching product unit');
    }

    this.setState({
      inProgress: false,
    });
  }

  deleteProductUnit = async (data) => {

    let res;
    let status;
    if (data.isActive) {
      res = await axiosInstance.patch(Apiurls.PRODUCT_UNIT_EDIT(data.id));
      status = "Restore"
    } else {
      res = await axiosInstance.delete(Apiurls.PRODUCT_UNIT_EDIT(data.id));
      status = "Delete"
    }

    if (res.data.success) {
      this.fetchProductUnit();
      window.mNotify.show(`${status} the product unit successfully.`);
    } else {
      window.mNotify.show(`Sorry!.. Could not ${status} the product unit`);
    }
  }

  render() {
    const { inProgress, productUnit } = this.state;
    const { history } = this.props;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>Units</h2>
        <div className={this.props.classes.titleContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductUnitAdd);
            }}
          >
            Create New
          </Button>
        </div>
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Unit Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                productUnit.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <CheckIcon
                        size={12}
                        style={{
                          display: 'inline-block',
                          color: row.isActive ? 'green' : 'grey',
                        }}
                      />
                      <span>{row.unitName}</span>
                    </TableCell>
                    <TableCell>
                      {row.unitDescription}
                    </TableCell>
                    <TableCell>
                      {row.isActive ?
                        <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                          <Button
                            onClick={(e)=>{
                              history.push(Urls.ProductUnitEdit(row.id));
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Tooltip title="Block">
                            <Button
                            onClick={(e)=>{
                            if (window.confirm(`Are you sure you want to delete ${row.unitName} ?`)) {
                            this.deleteProductUnit({
                                    id: row.id,
                                    isActive: false,
                                  });
                            }
                            }}
                            >
                            <BlockIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                        :
                         <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                          <Button
                            onClick={(e)=>{
                              if (window.confirm(`Are you sure you want to Restore ${row.unitName} ?`)) {
                                this.deleteProductUnit({
                                  id: row.id,
                                  isActive: true,
                                });
                              }
                            }}
                          >
                            <RestoreIcon />
                          </Button>
                        </ButtonGroup>
                      }
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
          </Table>
          {
            inProgress && <CircularProgress />
          }
        </TableContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListProductUnit));
