const urls = {};

urls.Login = '/login';
urls.Logout = '/logout';

urls.Product = `/product`;
urls.ProductAdd = `${urls.Product}/add`;
urls.ProductEdit = id => `${urls.Product}/${id}`;

urls.ProductPricePrefix = `${urls.Product}/price`;
urls.ProductPriceAdd = `${urls.ProductPricePrefix}/add`;

urls.ProductBrand = `/brand`;
urls.ProductBrandAdd = `${urls.ProductBrand}/add`;
urls.ProductBrandEdit = id => `${urls.ProductBrand}/${id}`;

urls.ProductSize = `/size`;
urls.ProductSizeAdd = `${urls.ProductSize}/add`;
urls.ProductSizeEdit = id => `${urls.ProductSize}/${id}`;

urls.ProductColour = `/colour`;
urls.ProductColourAdd = `${urls.ProductColour}/add`;
urls.ProductColourEdit = id => `${urls.ProductColour}/${id}`;

urls.ProductFit = `/fit`;
urls.ProductFitAdd = `${urls.ProductFit}/add`;
urls.ProductFitEdit = id => `${urls.ProductFit}/${id}`;

urls.ProductMaterial = `/material`;
urls.ProductMaterialAdd = `${urls.ProductMaterial}/add`;
urls.ProductMaterialEdit = id => `${urls.ProductMaterial}/${id}`;

urls.ProductTagging = `/tagging`;
urls.ProductTaggingAdd = `${urls.ProductTagging}/add`;
urls.ProductTaggingEdit = id => `${urls.ProductTagging}/${id}`;

urls.ProductMasterProduct = `/products/map`;
urls.ProductMasterProductAdd = `${urls.ProductMasterProduct}/add`;
urls.ProductMasterProductEdit = id => `${urls.ProductMasterProduct}/${id}`;

urls.ProductMasterProductPrice = masterProductId =>`/products/${masterProductId}/price`;
urls.ProductMasterProductPriceAdd = masterProductId => `/products/${masterProductId}/price/add`;
urls.ProductMasterProductPriceEdit = (masterProductId , id) => `/products/${masterProductId}/price/${id}`;

urls.ProductMasterProductImage = masterProductId => `/products/${masterProductId}/image`;
urls.ProductMasterProductImageAdd = masterProductId => `/products/${masterProductId}/image/add`;
urls.ProductMasterProductImageEdit = (masterProductId , id) => `/products/${masterProductId}/image/${id}`;

urls.ProductDashboard = `${urls.Product}/dashboard`;

export default urls;
