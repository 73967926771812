export const API_BASE = '/api';
export const Login = `${API_BASE}/auth/login`;
export const Register = `${API_BASE}/auth/register`;

export const ForgotPasswordVerifyOTP = `${API_BASE}/auth/forgot-password/verify-otp`;
export const ForgotPasswordSendOTP = `${API_BASE}/auth/forgot-password/send-otp`;
export const ResetPassword = `${API_BASE}/auth/reset-password`;

export const USER_META = id => `${API_BASE}/user/meta`;

export const STORE = `${API_BASE}/store`;
export const STORE_EDIT = id => `${STORE}/${id}`;
export const STORE_LIST = `${STORE}/getall`;

export const COUNTRY = `${API_BASE}/country`;
export const COUNTRY_EDIT = id => `${COUNTRY}/${id}`;
export const COUNTRY_LIST = `${COUNTRY}/getall`;

//Negotiation
export const NEGOTIATION = `${API_BASE}/negotiation`;
export const NEGOTIATION_RESPONSE = id => `${NEGOTIATION}/${id}/response`;
export const NEGOTIATION_LIST = `${NEGOTIATION}/getall`;

export const STORE_NEGOTIATION = `${STORE}/negotiation`;
export const STORE_NEGOTIATION_BRAND = `${STORE_NEGOTIATION}/brand/getall`;
export const STORE_NEGOTIATION_QUESTION = `${STORE_NEGOTIATION}/question`;
export const STORE_NEGOTIATION_QUESTION_LIST = `${STORE_NEGOTIATION_QUESTION}/getall`;

export const STORE_FEATURE = `${STORE}/feature`;
export const STORE_FEATURE_EDIT = id => `${STORE_FEATURE}/${id}`;
export const STORE_FEATURE_LIST = `${STORE_FEATURE}/getall`;

export const STORE_CONNECTOR = `${STORE}/connector`;
export const STORE_CONNECTOR_EDIT = id => `${STORE_CONNECTOR}/${id}`;
export const STORE_CONNECTOR_LIST = `${STORE_CONNECTOR}/getall`;

export const STORE_VENDOR = `${STORE}/vendor`;
export const STORE_VENDOR_EDIT = id => `${STORE_VENDOR}/${id}`;
export const STORE_VENDOR_LIST = `${STORE_VENDOR}/getall`;

export const ORDER = `${API_BASE}/order`;
export const ORDER_EDIT = id => `${ORDER}/${id}`;
export const ORDER_LIST = `${ORDER}/getall`;

export const ORDER_RESPONSE = `${API_BASE}/order-response`;
export const ORDER_RESPONSE_EDIT = id => `${ORDER_RESPONSE}/${id}`;
export const ORDER_ITEM_RESPONSE = id => `${ORDER_RESPONSE_EDIT(id)}/items/getall`;
export const ORDER_RESPONSE_LIST = `${ORDER_RESPONSE}/getall`;

export const ORDER_ITEMS_SEARCH_LIST = `${API_BASE}/order/search/items`;
export const ORDER_ITEM_EDIT = (orderId, id) => `${ORDER_EDIT(orderId)}/${id}`;
export const ORDER_ITEM_STATUS = `${API_BASE}/order/item-status`;

export const OWNER = `${API_BASE}/owner`;
export const OWNER_EDIT = id => `${OWNER}/${id}`;


const urls = {
  Login,
  Register,
  ForgotPasswordSendOTP,
  ForgotPasswordVerifyOTP,
  ResetPassword,
  USER_META,

  STORE,
  STORE_EDIT,
  STORE_LIST,

  COUNTRY,
  COUNTRY_EDIT,
  COUNTRY_LIST,

  NEGOTIATION,
  NEGOTIATION_RESPONSE,
  NEGOTIATION_LIST,

  STORE_NEGOTIATION,
  STORE_NEGOTIATION_BRAND,
  STORE_NEGOTIATION_QUESTION,
  STORE_NEGOTIATION_QUESTION_LIST,

  STORE_FEATURE,
  STORE_FEATURE_EDIT,
  STORE_FEATURE_LIST,

  STORE_CONNECTOR,
  STORE_CONNECTOR_EDIT,
  STORE_CONNECTOR_LIST,

  STORE_VENDOR,
  STORE_VENDOR_EDIT,
  STORE_VENDOR_LIST,

  ORDER,
  ORDER_RESPONSE,
  ORDER_ITEM_RESPONSE,
  ORDER_RESPONSE_LIST,
  ORDER_EDIT,
  ORDER_LIST,
  ORDER_ITEM_EDIT,
  ORDER_ITEMS_SEARCH_LIST,
  ORDER_ITEM_STATUS,

  OWNER,
  OWNER_EDIT
};

export default urls;
