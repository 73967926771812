import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

const NotiStackInterface = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const showNotification = enqueueSnackbar;
    const showNotificationSuccess = (message) => enqueueSnackbar(message, { variant: 'success' });
    const showNotificationError = (message) => enqueueSnackbar(message, { variant: 'error' });
    const showNotificationWarning = (message) => enqueueSnackbar(message, { variant: 'warning' });
    const showNotificationInfo = (message) => enqueueSnackbar(message, { variant: 'info' });

    window.mNotify = {
      show: showNotification,
      success: showNotificationSuccess,
      error: showNotificationError,
      warn: showNotificationWarning,
      info: showNotificationInfo,
    };

    return (() => {
      delete window.mNotify;
    });
  }, [enqueueSnackbar]);

  return null;
};

const NotiStackWrapper = () => {
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      iconVariant={{
        success: '🎉 ',
        error: '❗ ',
        warning: '💡 ',
        info: '',
    }}
    >
      <NotiStackInterface />
    </SnackbarProvider>
  );
};

export default NotiStackWrapper;
