import React, { useCallback, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import styles from 'src/styles/productBrandForm';
import { storeProductMapFormSchema } from "../validation/formValidation";

const ProductMasterMap = ({ data = {}, inProgress, onSave, productList , brandList , sizeList , colourList , fitList , materialList, tagList}) => {

  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  useEffect(() => {

      if (formData.proId && productList[formData.proId] && formData.brandId && brandList[formData.brandId] && formData.sizeId && sizeList[formData.sizeId] && formData.colourId && colourList[formData.colourId] && formData.fitId && fitList[formData.fitId] && formData.materialId && materialList[formData.materialId]) {


        setFormData({
          ...formData,
          proId: formData.proId,
          productName: productList[formData.proId].productName,
          brandId: formData.brandId,
          brandName: brandList[formData.brandId].brandName,
          sizeId: formData.sizeId,
          sizeName: sizeList[formData.sizeId].sizeName,
          colourId: formData.colourId,
          colourName: colourList[formData.colourId].colourName,
          fitId: formData.fitId,
          fitName: fitList[formData.fitId].fitName,
          materialId: formData.materialId,
          materialName: materialList[formData.materialId].materialName,
        });
      }
      // eslint-disable-next-line
    }, [formData.id]);

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Store Master Product error occurs!');
    }
  };

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeProductMapFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);


  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={2}>

      <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Barcode Number"
              error={!!(validationDataError.errors && validationDataError.errors.barcodeNumber)}
              helperText={validationDataError.errors ? validationDataError.errors.barcodeNumber : ''}
              value={formData.barcodeNumber}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  barcodeNumber: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>

            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-productName"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  proId: null,
                  productName: e.target.value,
                });
              }}
              value={formData.proId && productList[formData.proId] ? productList[formData.proId].productName : formData.productName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(productList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    proId: obj.proId,
                    productName: obj.productName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    proId: null,
                    productName: '',
                  });
                }

              }}
              options={Object.values(productList).map((option) => option.productName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Product Name"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.productName)}
                  helperText={validationDataError.errors ? validationDataError.errors.productName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-Brand"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  brandId: null,
                  brandName: e.target.value,
                });
              }}
              value={formData.brandId && brandList[formData.brandId] ? brandList[formData.brandId].brandName : formData.brandName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(brandList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    brandId: obj.brandId,
                    brandName: obj.brandName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    brandId: null,
                    brandName: '',
                  });
                }
              }}
              options={Object.values(brandList).map((option) => option.brandName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Brand Name"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.brandName)}
                  helperText={validationDataError.errors ? validationDataError.errors.brandName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-Brand"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  fitId: null,
                  fitName: e.target.value,
                });
              }}
              value={formData.fitId && fitList[formData.fitId] ? fitList[formData.fitId].fitName : formData.fitName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(fitList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    fitId: obj.fitId,
                    fitName: obj.fitName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    fitId: null,
                    fitName: '',
                  });
                }
              }}
              options={Object.values(fitList).map((option) => option.fitName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Style or Design or Fit Name"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.fitName)}
                  helperText={validationDataError.errors ? validationDataError.errors.fitName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />

          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-Brand"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  sizeId: null,
                  sizeName: e.target.value,
                });
              }}
              value={formData.sizeId && sizeList[formData.sizeId] ? sizeList[formData.sizeId].sizeName : formData.sizeName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(sizeList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    sizeId: obj.sizeId,
                    sizeName: obj.sizeName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    sizeId: null,
                    sizeName: '',
                  });
                }
              }}
              options={Object.values(sizeList).map((option) => option.sizeName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Size"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.sizeName)}
                  helperText={validationDataError.errors ? validationDataError.errors.sizeName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>


        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
          <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-Brand"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  colourId: null,
                  colourName: e.target.value,
                });
              }}
              value={formData.colourId && colourList[formData.colourId] ? colourList[formData.colourId].colourName : formData.colourName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(colourList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    colourId: obj.colourId,
                    colourName: obj.colourName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    colourId: null,
                    colourName: '',
                  });
                }
              }}
              options={Object.values(colourList).map((option) => option.colourName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Colour"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.colourName)}
                  helperText={validationDataError.errors ? validationDataError.errors.colourName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>

            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-Brand"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  materialId: null,
                  materialName: e.target.value,
                });
              }}
              value={formData.materialId && materialList[formData.materialId] ? materialList[formData.materialId].materialName : formData.materialName}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(materialList)[e.target.dataset.optionIndex];
                  setFormData({
                    ...formData,
                    materialId: obj.materialId,
                    materialName: obj.materialName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    materialId: null,
                    materialName: '',
                  });
                }
              }}
              options={Object.values(materialList).map((option) => option.materialName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Colour"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.materialName)}
                  helperText={validationDataError.errors ? validationDataError.errors.materialName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />

          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Owner Price"
              error={!!(validationDataError.errors && validationDataError.errors.ownerPrice)}
              helperText={validationDataError.errors ? validationDataError.errors.ownerPrice : ''}
              value={formData.ownerPrice}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  ownerPrice: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <FormControl fullWidth >
            <InputLabel
              id="demo-multiple-chip-label"
              style={{ paddingLeft: "10px", marginTop: "-7px", marginLeft: "7px" }} >
                Tag
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={formData.tagId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    tagId: [...e.target.value],
                  });
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={tagList[value] && tagList[value].tagId} label={tagList[value] && tagList[value].tagName} />
                    ))}
                  </Box>
              )}>
                {Object.values(tagList).map((tag) => (
                  <MenuItem
                    key={tag.tagId}
                    value={tag.tagId}
                  >
                    {tag.tagName}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: '5px' }}
            onClick={(e) => {
              setFormData({ ...data });
            }}
          >
            Reset
          </Button>

        </Grid>

      </Grid>
    </form>
  );
}

export default ProductMasterMap;
