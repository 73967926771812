import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'react-moment';
// import moment from 'moment';

// Tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
// import CheckIcon from '@mui/icons-material/CheckCircle';

import NegotiationBox from './negotiationBox'

import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Order',
    url: Urls.Order,
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chipMarLet: {
    marginLeft: '7px',
    fontSize:'10px',
    padding: '1px'
  },
  chipMarLetAccepted: {
    marginLeft: '7px',
    fontSize:'10px',
    padding: '1px',
    backgroundColor: '#27aa36'
  },
  buttonsize: {
    fontSize:'10px',
    padding: '5px'
  }
});

class ListOrderItems extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      negotiation: {
        status: false,
        itemId: '',
      },
      order: null,
      items: [],
      getMoreDetails: {
        vendors: [],
        products: [],
        brands: []
      },
    };
  }

  componentDidMount() {
    this.fetchOrderItems();
  }

  fetchOrderItems = async () => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.ORDER_EDIT(this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          inProgress: false,
          order: res.data.data.order,
          items: res.data.data.items,
          getMoreDetails:{
            ...this.state.getMoreDetails,
            vendors: res.data.data.vendorsByIdCollection,
            products: res.data.data.productsByIdCollection,
            brands: res.data.data.brandsByIdCollection,
          }
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error('Error while fetching orders');
    }

    this.setState({
      inProgress: false,
    });
  }

  acceptedItem = async(data) => {
    const res = await axiosInstance.post(Apiurls.ORDER_ITEM_STATUS, data);
    if (res.data.success) {
      window.mNotify.show('Order item has been '+data.name+' successfully.');
      this.fetchOrderItems();
    } else {
      window.mNotify.show('Sorry!.. Could not '+data.name+' the Order item ');
    }
  }

  deleteItemProduct = async (id) => {
    const { order } = this.state;
    const res = await axiosInstance.delete(Apiurls.ORDER_ITEM_EDIT(order.id, id));
    if (res.data.success) {
      this.fetchOrderItems();
      window.mNotify.show('Deleted the order item successfully.');
    } else {
      window.mNotify.show('Sorry!.. Could not delete the product');
    }
  }

  changeStatus = (status) => {
    this.setState({
      negotiation: {
        ...this.state.negotiation,
        status: status,
      },
    });
  }

  render() {
    const contextState = this.context;
    const { inProgress, order, items, getMoreDetails } = this.state;
    const { history } = this.props;
    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>Order {order ? order.orderId : ''}</h2>
        {/* <div className={this.props.classes.titleContainer}> */}
        {/*   <Button */}
        {/*     color="primary" */}
        {/*     variant="contained" */}
        {/*     onClick={() => { */}
        {/*       history.push(Urls.ProductAdd); */}
        {/*     }} */}
        {/*   > */}
        {/*     Create New */}
        {/*   </Button> */}
        {/* </div> */}
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Brands</TableCell>
                  <TableCell>quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Delivery Date</TableCell>
                  <TableCell>Budget</TableCell>
                  <TableCell>Product Status</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
              {
                items.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {
                        getMoreDetails.vendors[row.vendorId].vendorOrganization
                      }
                      {
                        (row.productStatus===0 && row.cutOffTimeStauts!==2  )? <Chip className={this.props.classes.chipMarLet} size="small" color="primary" label="Initiated" icon={<DoneIcon />} />:null
                      }
                      {
                        row.productStatus===1? <Chip className={this.props.classes.chipMarLetAccepted} size="small" color="primary" label="Accepted" icon={<DoneIcon />} />:null
                      }
                      {
                        (row.productStatus===2 || (row.cutOffTimeStauts===2 && row.productStatus!==1) )? <Chip className={this.props.classes.chipMarLet} size="small" color="secondary" icon={<ClearIcon />} label="Rejected" />:null
                      }

                    </TableCell>
                    <TableCell>
                      {
                        contextState.config.industrialHierarchy === 'hierarchy2' ? getMoreDetails.products[row.proId].productName : getMoreDetails.products[row.proId].productDescription
                      }
                    </TableCell>
                    <TableCell>
                      {
                         getMoreDetails.brands[row.brandId].brandName
                      }
                    </TableCell>
                    <TableCell>
                      {row.quantity} - (Qty)
                    </TableCell>
                    <TableCell>
                      Rs.{row.singlePrice}
                    </TableCell>
                    <TableCell>
                      <Moment format="DD/MM/YYYY hh:mm A" date={row.requestDeliveryDate} />
                    </TableCell>
                    <TableCell>
                      Rs.{row.budget}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                        {
                          (row.productStatus===0 || row.productStatus===2) && (row.cutOffTimeStauts !== 2)?<Button
                          className={this.props.classes.buttonsize}
                          onClick={(e)=>{
                            this.acceptedItem({
                              id: row.id,
                              orderId: order.id,
                              name: 'Accepted',
                              itemStatus: 1,
                              productResponseStatus:1,
                              vendorCutOffTime: getMoreDetails.vendors[row.vendorId].cutOffTime,
                              storeCutOffTime: contextState.stores[contextState.currentStore].cutOffTime,
                            });
                          }}>Accepted </Button>:null
                        }
                        {
                          (row.productStatus===0 || row.productStatus===1) && (row.cutOffTimeStauts !== 2)?<Button
                          className={this.props.classes.buttonsize}
                          onClick={(e)=>{
                            this.acceptedItem({
                              id: row.id,
                              orderId: order.id,
                              name: 'Rejected',
                              itemStatus: 2,
                              productResponseStatus:1,
                              vendorCutOffTime: getMoreDetails.vendors[row.vendorId].cutOffTime,
                              storeCutOffTime: contextState.stores[contextState.currentStore].cutOffTime,
                            });
                          }}>Rejected</Button>:null
                          }
                          {
                            ((row.productStatus===0) && (row.cutOffTimeStauts !== 2))?<Button
                           className={this.props.classes.buttonsize}
                          onClick={(e)=>{
                            this.setState({
                              negotiation: {
                                ...this.state.negotiation,
                                status: !this.state.negotiation.status,
                                itemId: row.id,
                              },
                            });
                          }}>Negotiation </Button>:null
                          }
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
          </Table>
          {
            inProgress && <CircularProgress />
          }
        </TableContainer>
        {this.state.negotiation.status ?
          <NegotiationBox negotiation={this.state.negotiation} changeStatus={this.changeStatus}/> : null }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListOrderItems));
