import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DropzoneArea } from 'react-mui-dropzone'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';

import { storeImageFormSchema } from "../validation/formValidation";
import styles from 'src/styles/productForm';
import axiosInstance from 'src/http';
import Apiurls from '../constants/apiUrls';

const ManageExistImageChooseList = ({onMerge, onClose}) => {
  const classes = styles();
  const [formData, setFormData] = React.useState({});
  // const [validationDataError, validationError] = React.useState({});
  const [imageCollection, setImageCollection] = React.useState({});
  const [newImage, setNewImage] = React.useState(true);
  const [selectedImage, setSelectedImage] = React.useState({});
  const [inProgress, setInProgress] = React.useState(true);
  const [offSet, setOffSet] = React.useState(0);
  const countPerPages = 3;
  const [imageCount, setImageCount] = React.useState(0);

  const [enableAddBtn, setEnableAddBtn] = React.useState([]);
  const [enableUpdateBtn, setEnableUpdateBtn] = React.useState(false);

  const [morphImage, setMorphImage] = React.useState([]);


  React.useEffect(() => {
    fetchAllImage();
    // eslint-disable-next-line
  }, [offSet]);

  React.useEffect(() => {
    setEnableUpdateBtn(!!Object.keys(selectedImage).length);
    if (morphImage.length) {
      onMerge(morphImage)
    }
    // eslint-disable-next-line
  }, [selectedImage, morphImage]);

    const onSubmit = (e) => {
    e.preventDefault();
    const [isValid] = validateForm(formData);
    // validationError({ errors });
    if (isValid && formData.files.length > 0) {
      onSave({ ...formData });
    } else {
      window.mNotify.error('Store image error occurs!');
    }
  };

  const validateForm = React.useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeImageFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);

  const fetchAllImage = async () => {
    try {
      setInProgress(true);
      const res = await axiosInstance.post(Apiurls.FILE_UPLOAD_ALL_IMAGE, {
        offSet, countPerPages
      });
      if (res.data.success) {
        setImageCollection(res.data.existingData)
        setImageCount(res.data.imageCount)
        setInProgress(false);

      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnAddImage = (files) => {
    let imgData = [];
    setEnableAddBtn(!!files.length)
    for (const file of files) {
      const currentFile = file;
      const myFileItemReader = new FileReader()
      myFileItemReader.addEventListener("load", () => {
        const myResult = myFileItemReader.result;
        imgData.push({
          path: myResult,
          name: currentFile.name,
          size: currentFile.size,
          type : currentFile.type
        });
      }, false)
      myFileItemReader.readAsDataURL(currentFile)
    }
    setFormData({
      ...formData,
      files: imgData,
    });
  }

  const onSave = async (data) => {
    try {
      setInProgress(true);
      const body = {
        data,
      };
      let res = await axiosInstance.post(Apiurls.FILE_UPLOAD, body);
      if (res.data.success) {
        setInProgress(false);
        setMorphImage(Object.keys(res.data.uploadFileImagesDetails))
      }
    } catch (error) {
      console.error(error);
      await setInProgress(false);
      window.mNotify.error('Error while creating images. Try again.');
    }
  }

  return ( <>
    <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        onClose={() => { onClose(false) }}
      >
        <DialogTitle>Choose Existing Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>

          </Grid>
          </DialogContentText>
          <Box noValidate component="form" sx={{ display: 'flex', flexDirection: 'column', width: 'fit-content', }}>
            <DialogContentText>
            {
              inProgress ?
              <CircularProgress color="inherit" align="center" size={25} style={{ color: "blue", margin: '0 auto' }} />
              : <>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button variant="contained" type="button"
                    onClick={() => { setNewImage(true); setSelectedImage([]) }}
                    color="primary" style={{margin: "5px"}}
                    >New Images</Button>
                  <Button variant="contained" type="button"
                    onClick={() => { setNewImage(false); setEnableAddBtn([]) }}
                    className={classes.danger} style={{margin: "5px"}}
                  >Existing Images</Button>

                </Grid>
              </Grid>

                {
                  newImage ?
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.paper}>
                      {inProgress ?
                        <CircularProgress color="inherit" size={25} style={{ color: "blue" }} /> :
                        <DropzoneArea
                          dropzoneText='Click and upload your image'
                          showPreviews={false}
                          showPreviewsInDropzone={true}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          previewChipProps={{ classes: { root: classes.previewChip } }}
                          previewText="Selected Image"
                          multiple
                          acceptedFiles={['image/*']}
                          filesLimit={10}
                          maxFileSize={10000000}
                          onChange={handleOnAddImage}
                          showAlerts={['error', 'info']}
                        />}
                      </div>
                      <Grid item xs={12} align="center">
                      <Button variant="contained" type="button"
                    onClick={(e) => { onSubmit(e) }}
                   className={enableAddBtn ? classes.success : ''} disabled={enableAddBtn ? false : true} style={{margin: "5px"}}>Add Images</Button>
                   </Grid>
                    </Grid>
                  </Grid>
                :
                <>
                  <Grid container spacing={2}>
                        {
                          Object.values(imageCollection).map( (item) => (
                             <Grid
                             item xs={1}
                             onClick={() => {
                              if(!selectedImage[item.id]) {
                                setSelectedImage({
                                  ...selectedImage,
                                  [item.id]: item
                                })
                              } else {
                                let newSelectedImage = selectedImage;
                                delete newSelectedImage[item.id];
                                setSelectedImage({
                                  ...newSelectedImage,
                                })
                               }
                             }}
                             key={item.id} >
                              <img alt={item.id} src={item.url} className={selectedImage[item.id] ? classes.selected : classes.images} />

                            </Grid>
                            ))
                        }
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Pagination
                      variant="outlined"
                      limit={countPerPages}
                      offset={offSet}
                      total={imageCount}
                      onClick={ (event, offset, value) => {
                      setOffSet(offset)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                   <Button variant="contained" type="button"
                    onClick={() => {
                    setMorphImage(Object.keys(selectedImage)) }}
                   className={enableUpdateBtn ? classes.success : ''} disabled={enableUpdateBtn ? false : true} style={{margin: "5px"}}>Update Images</Button>
                   </Grid>
                  </>
                }
              </>
            }

            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose(false) }}>Close</Button>
        </DialogActions>
      </Dialog>
      </>);
}

export default ManageExistImageChooseList;
