import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';
import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import StoreForm from './partials/storeForm';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Store',
    url: Urls.Store,
  },
  {
    title: 'Add',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ManageStore extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ownerOrganization: '',
        contact: '',
        cutOffTime: '',
      },
      orginalData: {},
      cacheMemoize: {},
      countryList: {},
      stateList: {},
      cityList: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      errors : {}
    }
  }

  componentDidMount() {
    this.getStoreData();
  }

  getStoreData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.STORE_EDIT(this.props.match.params.id));
      if (res.data.success && res.data.data.id) {
        let addressData = JSON.parse(res.data.data.address);
        let entries = Object.entries(addressData);
        entries.forEach(([prop, val]) => res.data.data[prop] = val);
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    }
  }

  resetState = () => {
    this.setState({
      formData: {},
    });
  }

  getSearchRcords = async (data) => {
    try {
      if (this.state.cacheMemoize[`${data.relation}${data.setState}`] && this.state.cacheMemoize[`${data.relation}${data.setState}`][data.search]) {
        this.setState({
          [data.setState]: this.state.cacheMemoize[`${data.relation}${data.setState}`][data.search] || {},
        });
        return
      }
      let res;
      res = await axiosInstance.post(Apiurls.COUNTRY, data);
      if (res.data.success) {
        this.setState({
          cacheMemoize: {
            ...this.state.cacheMemoize,
            [`${data.relation}${data.setState}`]: {
              ...this.state.cacheMemoize[`${data.relation}${data.setState}`],
              [data.search]: res.data.data || {},
            },
          },
          [data.setState]: res.data.data || {},
        });
      } else {
        this.setState({
          cacheMemoize: {
            ...this.state.cacheMemoize,
            [`${data.relation}${data.setState}`]: {
              ...this.state.cacheMemoize[`${data.relation}${data.setState}`],
              [data.search]: {},
            },
          },
          [data.setState]: {},
        });
      }
      return ;
      throw new Error(res.data.error);
    } catch (error) {
      console.error(error);
      this.setState({ inProgress: false });

      return window.mNotify.show('Error while Fetch . Try again.');
    }
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        ownerOrganization: data.ownerOrganization,
        address: JSON.stringify({
          street: data.street,
          locality: data.locality,
          city: data.city,
          state: data.state,
          country: data.country,
          zipcode: data.zipcode,
        }),
        contact: data.contact,
        email: data.email,
        emergencyContact: data.emergencyContact,
        bankInformation: data.bankInformation,
        cutOffTime: data.cutOffTime,
        storeStatus: 2,
      };

      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.STORE_EDIT(orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.STORE, body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.Store);
      }
      if (!res.data.success && res.data.errors) {
        this.setState({ errors: res.data.errors });
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        return;
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });
      window.mNotify.error('Error while creating store. Try again.');
    }
  }

  render() {
    const { t , history } = this.props;
    const { formData, inProgress, isLoadingData, countryList, stateList, cityList, errors } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>{formData.id ? t('editStore') : t('addNewStore')}</h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <StoreForm
              data={formData}
              getSearchRcords={this.getSearchRcords}
              inProgress={inProgress}
              onSave={this.onSave}
              errors={errors}
              t={t}
              countryList={countryList}
              stateList={stateList}
              cityList={cityList}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(withTranslation()(ManageStore)));
