import React, { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import Topbar from 'src/topbar';
import AppLoggedInFooter from 'src/components/appLoggedInFooter';
import DashboardLayoutStyles from 'src/styles/dashboardLayout';
import InitModule from './initModule';
import InitNewFeature from './initFeature';
import InitFeature from './initNewFeature';
import InitConnector from './initConnector';

class DashboardLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerMenu: window.innerWidth >= 1040,
      userMenuOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState({ drawerMenu: !this.state.drawerMenu });
  }

  render() {
    const { children } = this.props;
    // const isDekstop = window.innerWidth >= 1040;

    return (
        <div className={this.props.classes.root}>
          <Topbar />
          <div className={this.props.classes.container}>
            <div
              className={clsx(
                this.props.classes.contentContainer,
                "page-content-container"
                )}
               >
              {children}
            </div>
            <AppLoggedInFooter />
          </div>
        </div>
    );
  }
}

export default withStyles(DashboardLayoutStyles)(withRouter(InitConnector(InitFeature(InitNewFeature(InitModule(DashboardLayout))))));
