import { topbarHeight } from './topbarStyles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    height: '100%',
    paddingTop: '56px',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    minHeight: '100%',
  },
  contentContainer: {
    flex: 1,
    paddingTop: `${topbarHeight + 10}px`,
  },
  contentContainerDesktop: {
    // paddingLeft: `${drawerWidth + 10}px`,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
});

export default styles;
