const API_BASE = '/api';

const PRODUCT = `${API_BASE}/new-product`;
const PRODUCT_EDIT = id => `${PRODUCT}/${id}`;
const PRODUCT_LIST = `${PRODUCT}/getall`;

const PRODUCT_TYPE = `${PRODUCT}/type`;
const PRODUCT_TYPE_EDIT = id => `${PRODUCT_TYPE}/${id}`;
const PRODUCT_TYPE_LIST = `${PRODUCT_TYPE}/getall`;

const PRODUCT_UNIT = `${PRODUCT}/unit`;
const PRODUCT_UNIT_EDIT = id => `${PRODUCT_UNIT}/${id}`;
const PRODUCT_UNIT_LIST = `${PRODUCT_UNIT}/getall`;

const PRODUCT_HIERARCHY = `${PRODUCT}/hierarchy`;
const PRODUCT_HIERARCHY_EDIT = id => `${PRODUCT_HIERARCHY}/${id}`;
const PRODUCT_HIERARCHY_LIST = `${PRODUCT_HIERARCHY}/getall`;

const PRODUCT_MASTER_MAP = `${PRODUCT}/master`;
const PRODUCT_MASTER_MAP_EDIT = id => `${PRODUCT_MASTER_MAP}/${id}`;
const PRODUCT_MASTER_MAP_LIST = `${PRODUCT_MASTER_MAP}/getall`;
const PRODUCT_MASTER_MAP_ITEMS_LIST = `${PRODUCT_MASTER_MAP}/search/items`;
const PRODUCT_MASTER_COUNT = `${PRODUCT_MASTER_MAP}/last-insert-id`;

const PRODUCT_PRICE_MASTER = masterProductId => `${PRODUCT_MASTER_MAP_EDIT(masterProductId)}/price`;
const PRODUCT_PRICE_MASTER_LIST = masterProductId => `${PRODUCT_PRICE_MASTER(masterProductId)}/getall`;
const PRODUCT_PRICE_MASTER_EDIT = (masterProductId, id) => `${PRODUCT_PRICE_MASTER(masterProductId)}/${id}`;

const PRODUCT_DISCOUNT_MASTER = masterProductId => `${PRODUCT_MASTER_MAP_EDIT(masterProductId)}/discount`;
const PRODUCT_DISCOUNT_MASTER_LIST = masterProductId => `${PRODUCT_DISCOUNT_MASTER(masterProductId)}/getall`;
const PRODUCT_DISCOUNT_MASTER_EDIT = (masterProductId, id) => `${PRODUCT_DISCOUNT_MASTER(masterProductId)}/${id}`;

const PRODUCT_MASTER_MAP_SEARCH_ITEMS = `${PRODUCT_MASTER_MAP}/search/items`;


const apiUrls = {
  PRODUCT,
  PRODUCT_LIST,
  PRODUCT_EDIT,

  PRODUCT_TYPE,
  PRODUCT_TYPE_EDIT,
  PRODUCT_TYPE_LIST,

  PRODUCT_UNIT,
  PRODUCT_UNIT_EDIT,
  PRODUCT_UNIT_LIST,

  PRODUCT_HIERARCHY,
  PRODUCT_HIERARCHY_EDIT,
  PRODUCT_HIERARCHY_LIST,

  PRODUCT_PRICE_MASTER,
  PRODUCT_PRICE_MASTER_EDIT,
  PRODUCT_PRICE_MASTER_LIST,

  PRODUCT_DISCOUNT_MASTER,
  PRODUCT_DISCOUNT_MASTER_LIST,
  PRODUCT_DISCOUNT_MASTER_EDIT,

  PRODUCT_MASTER_MAP,
  PRODUCT_MASTER_MAP_EDIT,
  PRODUCT_MASTER_MAP_LIST,
  PRODUCT_MASTER_MAP_ITEMS_LIST,
  PRODUCT_MASTER_COUNT,

  PRODUCT_MASTER_MAP_SEARCH_ITEMS,
};

export default apiUrls;
