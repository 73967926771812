import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';

// Tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import CheckIcon from '@mui/icons-material/CheckCircle';

import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    url: Urls.MyOrder,
    title: 'My Orders List',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ListOrders extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      orders: [],
    };
  }

  componentDidMount() {
    this.fetchOrders();
  }

  fetchOrders = async () => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.ORDER_RESPONSE_LIST);
      if (res.data.success) {
        this.setState({
          inProgress: false,
          orders: res.data.data,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error('Error while fetching orders');
    }

    this.setState({
      inProgress: false,
    });
  }


  render() {
    const { inProgress, orders } = this.state;
    const { history } = this.props;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>My Orders List</h2>

        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  {/* <TableCell>Response</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                orders.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.orderId}
                    </TableCell>
                    <TableCell>
                      {row.orderStatus}
                    </TableCell>
                    {/* <TableCell> */}
                    {/*   {row.responseStatus} */}
                    {/* </TableCell> */}
                    <TableCell>
                      <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                        <Button
                          onClick={(e)=>{
                            history.push(Urls.MyOrderItems(row.id));
                          }}
                        >
                            Items
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
          </Table>
          {
            inProgress && <CircularProgress />
          }
        </TableContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListOrders));
