/* eslint-diable  react-hooks/exhaustive-deps:0 */
import React, { useState, useEffect } from 'react';
// import { withStyles } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

// import Moment from 'react-moment';


import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';

import styles from 'src/styles/negotiationBox';

const NegotiationRejects = ({data, previousNegotiation, QuestionId, negotiationQuestionList, onSave}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);
  const [inProgress, setInProgress] = useState(true);
  const [VendorProductBrands, setVendorProductBrands] = useState({});

  const [formData, setFormData] = React.useState(data);

  const fetchVendorProductBrands = async () => {
      setInProgress(true);
      const res = await axiosInstance.post(Apiurls.STORE_NEGOTIATION_BRAND, {
        itemId: formData.itemId
      });
      // const data = res.data;
      if (res.data.success) {
        setVendorProductBrands(res.data)
        setInProgress(false);
      }
  }

  useEffect(() => {
    fetchVendorProductBrands();
    // eslint-disable-next-line
  }, [setInProgress]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.questionId) {
      onSave({
        ...formData,
        itemId: formData.itemId,
      });
    } else{
      window.mNotify.error('Please fill out this field');
    }
  };

  return (
      <>
        <br /><br />
        {
          QuestionId !== 2 ? (
            <TextField
              id="datetime-local"
              type={negotiationQuestionList[QuestionId].dataType}
              label={negotiationQuestionList[QuestionId].question}
              variant="outlined"
              className={classes.textField}
              value={formData.answer}
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  answer: e.target.value,
                })
              }}

              InputLabelProps={{
                shrink: true,
              }}
            />
          ): <>{
          !inProgress ? <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Chooes Brands</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.answer}
          onChange={(e)=>{
            setFormData({
              ...formData,
             answer: e.target.value,
            })
          }}
          label="Chooes Brands"
        >
          <MenuItem value="">
            <em>Chooes Brands</em>
          </MenuItem>
          {
            Object.keys(VendorProductBrands.data).map((vals, keys) => (
              <MenuItem value={VendorProductBrands.data[vals].brandId} key={vals}>{VendorProductBrands.data[vals].title}</MenuItem>
            ))
          }

        </Select>
      </FormControl> : <div className={classes.root}>  <CircularProgress />  </div>
        }
        </>
        }
        <Button  className={classes.saveButton} onClick={onSubmit} color="primary"> Save </Button>
      </>
  );
}

export default NegotiationRejects;
