const yup = require('yup');

export const userFormSchema = yup.object().shape({
  firstname: yup.string().required('FirstName Required'),
  lastname: yup.string().required('LastName Required'),
  email: yup.string().required('Email Address Required').email('Email Address must be a valid email'),
  phone: yup.string().required('Phone number Required'),
});

export const ownerFormSchema = yup.object().shape({
  ownerPhone: yup.string().required('Contact Number Required'),
  ownerEmail: yup.string().required('Email Address Required').email('Email Address must be a valid email'),
  ownerName: yup.string().required('First Name Required'),
});

export const storeFormSchema = yup.object().shape({
  cutOffTime: yup.string().required('Cut Off Time Required'),
  contact: yup.string().required('Contact Number Required'),
  email : yup.string().required('Email Address Required').email('Email Address must be a valid email'),
  ownerOrganization: yup.string().required('Store Name Required'),
});

export const vendorFormSchema = yup.object().shape({
  // cutOffTime: yup.string().required('Cut Off Time Required'),
  address: yup.string().required('Address Required'),
  contact: yup.string().required('Contact Number Required'),
  // emergencyContact: yup.string().required('Emergency Contact Number Required'),
  // whatsapp: yup.string().required('Whatsapp Number Required'),
  // email : yup.string().required('Email Address Required').email('Email Address must be a valid email'),
  vendorOrganization: yup.string().required('Vendor Name Required'),
});

export const orderFormSchema = yup.array(
  yup.object({
    priceId: yup.string().required('Product is Required'),
    quantity: yup.string().required('Quantity is Required'),
    budget: yup.string().required('Required'),
    requestDeliveryDate: yup.string().required('Request Delivery Date is Required'),
    //productRemarks: yup.string().required('Required'),
  }),
);

export const storeProductFormSchema = yup.object().shape({
  productDescription: yup.string().required('Product is Required'),
  unit: yup.string().required('unit is Required'),
});

export const storeProductBrandFormSchema = yup.object().shape({
  brandName: yup.string().required('brand Name is Required'),
  barcodeNumber: yup.string().required('barcodeNumber is Required'),
});

export default {
  userFormSchema,
  ownerFormSchema,
  storeFormSchema,
  vendorFormSchema,
  orderFormSchema,
  storeProductFormSchema,
  storeProductBrandFormSchema,
};
