import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';

// import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'react-moment';

// Tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import CheckIcon from '@mui/icons-material/CheckCircle';

// import Urls from 'src/constants/urls';
// import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
// import Apiurls from 'src/constants/apiUrls';
// import MyBreadCrumbs from 'src/components/Breadcrumbs';

// const breadcrumbItems = [
//   {
//     url: Urls.Dashboard,
//     title: 'Dashboard',
//   },
// ];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ListOrdersOverView extends Component {

  static contextType = GlobalContext;

  render() {
    const { dataFromDate } = this.props;

    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        if (![currentValue[key]]) {
          return null;
        }
        (result[currentValue[key]] = result[currentValue[key]] || [])
        .push(
          currentValue
        );
        return result;
      }, {});
    };
    const personGroupedBy = groupBy(dataFromDate, 'vendorId');

    return (
      <div className={this.props.classes.paper}>
        <h2>Overview</h2>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12} align="center">
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Product Details</TableCell>
                </TableRow>
              </TableHead>
               <TableBody >
              {
                Object.values(personGroupedBy).map((productRows, idx) =>  (
                  productRows[0].vendorId?
                  <TableRow  key={productRows[0].vendorId}>
                    <TableCell>{idx+1}</TableCell>
                    <TableCell>
                      {productRows[0].vendorName}
                    </TableCell>
                    <TableCell>
                      <TableContainer >
                        <Table>
                          <TableBody >
                            {
                              personGroupedBy[productRows[0].vendorId].map(
                                (productDetails, keys) =>  (
                            <TableRow key={productDetails.proTitle}>
                              <TableCell>
                                {keys+1}) {productDetails.proTitle}
                              </TableCell>
                              <TableCell>
                                {
                                  productDetails.quantity?
                                  <p>{productDetails.quantity} - (Qty)</p>:""
                                  }
                              </TableCell>
                              <TableCell>
                                {
                                  productDetails.requestDeliveryDate?<Moment format="DD/MM/YYYY hh:mm A" date={productDetails.requestDeliveryDate} />:''
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  productDetails.budget?
                                  <p>Rs.{productDetails.budget} - (Unit Price)</p>:""
                                  }
                                    </TableCell>
                                <TableCell>
                                {
                                  productDetails.quantity ?
                                  <p>Rs.{productDetails.quantity * productDetails.budget} - (Total Price)</p>:""
                                }
                              </TableCell>
                            </TableRow>
                            ) ) }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>: ''
                ))
              }
              </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListOrdersOverView));
