const urls = {};

urls.Login = '/login';
urls.Logout = '/logout';

urls.Product = `/new-product`;
urls.ProductAdd = `${urls.Product}/add`;
urls.ProductEdit = id => `${urls.Product}/${id}`;

urls.ProductType = `${urls.Product}/type`;
urls.ProductTypeAdd = `${urls.ProductType}/add`;
urls.ProductTypeEdit = id => `${urls.ProductType}/${id}`;

urls.ProductUnit = `${urls.Product}/unit`;
urls.ProductUnitAdd = `${urls.ProductUnit}/add`;
urls.ProductUnitEdit = id => `${urls.ProductUnit}/${id}`;

urls.ProductHierarchy = `${urls.Product}/hierarchy`;
urls.ProductHierarchyAdd = `${urls.ProductHierarchy}/add`;
urls.ProductHierarchyEdit = id => `${urls.ProductHierarchy}/${id}`;

urls.ProductPriceMaster = masterProductId =>`${urls.ProductEdit(masterProductId)}/price`;
urls.ProductPriceMasterAdd = masterProductId => `${urls.ProductPriceMaster(masterProductId)}/add`;
urls.ProductPriceMasterEdit = (masterProductId , id) => `${urls.ProductPriceMaster(masterProductId)}/${id}`;

urls.ProductDiscountMaster = masterProductId =>`${urls.ProductEdit(masterProductId)}/discount`;
urls.ProductDiscountMasterAdd = masterProductId => `${urls.ProductDiscountMaster(masterProductId)}/add`;
urls.ProductDiscountMasterEdit = (masterProductId , id) => `${urls.ProductDiscountMaster(masterProductId)}/${id}`;

urls.ProductMasterProduct = `${urls.Product}/map`;
urls.ProductMasterProductAdd = `${urls.ProductMasterProduct}/add`;
urls.ProductMasterProductEdit = id => `${urls.ProductMasterProduct}/${id}`;

urls.ProductDashboard = `${urls.Product}/dashboard`;

export default urls;
