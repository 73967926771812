import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  table: {
  },
  gridTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      margin: 0,
    }
  }
}));
