import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend'; // fallback http load

i18n
  .use(HttpApi)
  .use(initReactI18next).init({
    debug: NODE_ENV !== 'production',
    fallbackLng: 'en',
    supportedLngs: ['en', 'ta'],
    interpolation: {
      escapeValue: true // react already saves from xss
    },
  });

export default i18n;
