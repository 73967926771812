import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import ProductUnitForm from './partials/productUnitForm';
import Button from '@mui/material/Button';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Add Unit',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

const EmptyValue = (props) => {
  return {
    unitDescription: '',
    unitName: '',
    isActive: 1,
  }
}

class ManageProductUnit extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...EmptyValue(this.props)
      },
      orginalData: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      errors : {}
    }
  }

  async componentDidMount() {
    await this.getProductUnitData();
  }

  getProductUnitData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_UNIT_EDIT(this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    }
  }

  resetState = () => {
    this.setState({
      formData: {
        ...EmptyValue(this.props)
      },
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        unitName: data.unitName,
        unitDescription: data.unitDescription,
        isActive: data.isActive,
      };
      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_UNIT_EDIT(orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_UNIT, body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.ProductUnit);
      }
      if (!res.data.success && res.data.errors) {
        this.setState({ errors: res.data.errors });
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        return;
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });
      window.mNotify.error('Error while creating product. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData , errors } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <div className={this.props.classes.titleContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductUnit);
            }}
            style={{ marginRight: '5px' }}
          >
             View Unit
          </Button>


        </div>
        <h2>{formData.id ? 'Edit' : 'Add'} Unit</h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <ProductUnitForm
              data={formData}
              inProgress={inProgress}
              onSave={this.onSave}
              errors={errors}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageProductUnit));
