import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';


import ListOrdersOverView from '../listOrdersOverview';
import { orderFormSchema } from "../validation/formValidation";

import styles from 'src/styles/productOrderForm';

const ProductOrderForm = ({ data = [], order, errorMessage, inProgress, onSave, searchableList }) => {
  const classes = styles();

  const [formData, setFormData] = React.useState([...data]);
  const [validationDataError, validationError] = React.useState({});
  const [orders] = React.useState(order);

  React.useEffect(() => {
    if (!formData.length) {

      addNewItem();
    }
  }, []); // eslint-disable-line

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      orderFormSchema.validateSync(formData, {
        abortEarly: false,
      });
    } catch (err) {
      err.inner.forEach(element => errors[element.path] = element.message);
      formIsValid = false;
    }
    return [formIsValid, errors];

  }, []);

  const addNewItem = () => {
    setFormData([
      ...formData,
      {
        brandId: '',
        proTitle: '',
        productId: '',
        vendorId: '',
        vendorName: '',
        priceId: '',
        quantity: '',
        requestDeliveryDate: '',
        productStatus: 0,
        productResponseStatus: 0,
        singlePrice: '',
        budget: '',
        productRemarks: ''
      },
    ]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ ...errors });

    if (isValid) {
      if (orders.id > 0) {
        onSave({
          orders: orders,
          formData: [...formData]
        });
      } else {
        onSave([...formData]);
      }
    } else {
      window.mNotify.error('Validation error occurs!');
    }
  };

  const getItem = (lineItem, idx, onChange, values = {}) => {

    return (
      <Grid
        xs={12}
        container
        spacing={1}
      >
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Box pl={1} pr={1} pt={1} pb={1}>
          <div className={classes.paper}>
            <Autocomplete
              value={searchableList[values.priceId]}
              options={Object.values(searchableList)}
              // error={!!(validationData[idx] && validationData[idx].priceId)}
              className={classes.error}
              helperText={(validationDataError['['+idx+'].priceId'] !== undefined) ? validationDataError['['+idx+'].priceId'] : ''}
              getOptionLabel={option => (option.title ? option.title : "")}
              autoSelect={true}
              onChange={(e, setValue)=>{
                onChange({
                  ...values,
                  brandId: setValue !== null ? setValue.brandId : '',
                  proTitle: setValue !== null ? setValue.title : '',
                  productId: setValue !== null ? setValue.productId : '',
                  priceId: setValue !== null ?setValue.priceId : '',
                  vendorId: setValue !== null ? setValue.vendorId : '',
                  vendorName: setValue !== null ? setValue.vendorName : '',
                  singlePrice: setValue !== null ? setValue.singlePrice : '',
                  budget : setValue !== null ? setValue.singlePrice : '',
                });
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.error}
                  {...params}
                  fullWidth
                  label="Product"
                  variant="outlined"
                />
              )}
            />
            {
              (validationDataError['['+idx+'].priceId'] !== undefined) ?
              <FormHelperText id="my-helper-text" style={{ color: "red" }}>{validationDataError['['+idx+'].priceId']}</FormHelperText>
              : null
            }
          </div>
        </Box>
      </Grid>
      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
        <Box pl={1} pr={1} pt={1} pb={1}>
        <div className={classes.paper}>
          <TextField
            id="outlined-basic"
            fullWidth

            error={!!(validationDataError['['+idx+'].quantity'] !== undefined)}
            helperText={(validationDataError['['+idx+'].quantity'] !== undefined) ? validationDataError['['+idx+'].quantity'] : ''}
            type="number"
            label="Quantity"
            value={values.quantity}
            variant="outlined"
            onChange={(e)=>{
              onChange({
                ...values,
                quantity: e.target.value,
              })
            }}
          />
          </div>
          </Box>
      </Grid>
      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
        <Box pl={1} pr={1} pt={1} pb={1}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              error={!!(validationDataError['['+idx+'].budget'] !== undefined)}
              helperText={(validationDataError['['+idx+'].budget'] !== undefined) ? validationDataError['['+idx+'].budget'] : ''}
              type="number"
              label="Unit Price"
              value={values.budget}
              variant="outlined"
              onChange={(e)=>{
                onChange({
                  ...values,
                  budget: e.target.value,
                })
              }}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <Box pl={1} pr={1} pt={1} pb={1}>
        <div className={classes.paper}>
          <TextField
            id="datetime-local"
            label="Delivery Date"
            type="datetime-local"
            fullWidth
            error={!!(validationDataError['['+idx+'].requestDeliveryDate'] !== undefined)}
            helperText={(validationDataError['['+idx+'].requestDeliveryDate'] !== undefined) ? validationDataError['['+idx+'].requestDeliveryDate'] : ''}
            value={moment(values.requestDeliveryDate).format("YYYY-MM-DDTHH:mm")}
            variant="outlined"
            onChange={(e)=>{
              onChange({
                ...values,
                requestDeliveryDate: e.target.value,
              })
            }}
          />
          </div>
          </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <Box pl={1} pr={1} pt={1} pb={1}>
          <div className={classes.paper}>
              <TextField
                id="outlined-basic"
                fullWidth
                error={!!(validationDataError['['+idx+'].productRemarks'] !== undefined)}
                helperText={(validationDataError['['+idx+'].productRemarks'] !== undefined) ? validationDataError['['+idx+'].productRemarks'] : ''}
                type="text"
                label="Remarks"
                value={values.productRemarks}
                variant="outlined"
                onChange={(e)=>{
                  onChange({
                    ...values,
                    productRemarks: e.target.value,
                  })
                  }}
              />
          </div>
        </Box>
      </Grid>
      {

        idx !== 0 ?
      <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>

        <Box pl={1} pr={1} pt={1} pb={1}>
          <Fab size="small"
            color="secondary"
            aria-label="add"
            onClick={(e)=>{
              const newFormData = [...formData];
              newFormData.splice(idx, 1);
              setFormData( newFormData );
            }}
          >
            <DeleteIcon fontSize="small" />
          </Fab>
        </Box>
      </Grid>
      :''
        }
      {

        formData.length === idx+1 ?
      <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
        <Box pl={1} pr={1} pt={1} pb={1}>
          <Fab size="small"
            color="primary"
            aria-label="add"
            onClick={(e)=>{
              addNewItem();
            }}
          >
            <AddCircleOutlineIcon  />
          </Fab>
        </Box>
      </Grid>:''
    }
    </Grid>
    )
  }

  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} align="center">
        {
          formData.map((f, idx) => getItem(f, idx, (val) => {
            const newFormData = [...formData];
            newFormData[idx] = val;
            setFormData(newFormData);
          }, formData[idx]))

        }
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {orders.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: '5px' }}
            onClick={(e) => {
              setFormData({ ...data });
            }}
          >
            Reset
          </Button>
          <p>{errorMessage}</p>
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <ListOrdersOverView dataFromDate = {formData} />
        </Grid>
      </Grid>
    </form>


  );
}

export default ProductOrderForm;
