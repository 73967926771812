let app = null;

if (__INDUSTRY_TYPE__ === 'textile') {
  if (__INDUSTRY_HIERARCHY__ === 'hierarchy1') {
    app = require('./textile/hierarchy1/App').default;
  } else if (__INDUSTRY_HIERARCHY__ === 'hierarchy2') {
    app = require('./textile/hierarchy2/App').default;
  } else {
    throw new Error('No Such industry Found. Please check you env for right industry');
  }
} else if (__INDUSTRY_TYPE__ === 'restaurant') {
  if (__INDUSTRY_HIERARCHY__ === 'hierarchy1') {
    app = require('./restaurant/hierarchy1/App').default;
  } else {
    throw new Error('No Such industry Found. Please check you env for right industry');
  }
}

export default app;
