import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import StoreIcon from '@mui/icons-material/Store';
// import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse'
import { GlobalContext } from 'src/GlobalContextProvider';
import Urls from 'src/constants/urls';
import IconPackages from 'src/iconPackages';
import Product from 'src/products';
import NewProduct from 'src/newProducts/App';

import {
  USER_TYPE_ADMIN,
  USER_TYPE_VENDOR,
  USER_TYPE_OWNER,
} from 'src/constants/index';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  childMenu: {
    paddingLeft: 20,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openMenus: {},
      owneropen: false,
      vendoropen: false,
      masteropen: false,
      orderopen: false,
    };
  }

  getAdminMenu = () => {
    const { history, t } = this.props;
    if (this.context.user.type === USER_TYPE_ADMIN) {
      return [
        {
          id: 'owner-root',
          text: t('owners'),
          icon: 'OwnerIcon',
          children: [
            {
              id: 'owner-root-add',
              text: t('add'),
              icon: 'AddIcon',
              onClick: () => {
                this.setState({ openMenus: {} });
                history.push(Urls.OwnerAdd);
              },
            },
          ],
        },
        {
          id: 'order-root',
          text: t('order'),
          icon: 'AddShoppingCartIcon',
          children: [
            {
              id: 'order-root-add',
              text: t('order'),
              icon: 'AddIcon',
              onClick: () => {
                this.setState({ openMenus: {} });
                history.push(Urls.OrderAdd);
              },
            },
          ],
        },
        {
          id: 'vendor-root',
          text: t('vendor'),
          icon: 'VendorIcon',
          children: [
            {
              id: 'vendor-root-add',
              text: t('add'),
              icon: 'AddIcon',
              onClick: () => {
                this.setState({ openMenus: {} });

                history.push(Urls.StoreVendorAdd);
              },
            },
          ],
        },
      ];
    }

    return [];
  };

  getOwnerMenu = () => {
    const { t } = this.props;
    const contextState = this.context;
    const menuForOutSideFolders = [];


    const allOutSideFoldersName = {
      // products: {
      //   storeProducts: "productsMenuDetails"
      // },
      modules: {
        storeModules: "modulesMenuDetails"
      },
      newFeatures: {
        storeNewFeatures: "newFeaturesMenuDetails"
      },
      // features: {
      //   storeFeatures: "featuresMenuDetails"
      // },
      connectors: {
        storeConnectors: "connectorsMenuDetails"
      }
    };

    Object.keys(allOutSideFoldersName).forEach((index) => {
      let indexKey = allOutSideFoldersName[index]
      Object.keys(indexKey).forEach((item) => {
        if (Object.keys(contextState[index][item]).length) {
          Object.keys(contextState[index][item]).forEach((val) => {
            let extraSideBar = contextState[index][item][val]
            if (!!extraSideBar[indexKey[item]] && !!extraSideBar[indexKey[item]].length) {
              menuForOutSideFolders.push(...extraSideBar[indexKey[item]]);
            }
          })
        }
      })
    });


    if (this.context.user.type === USER_TYPE_OWNER) {
      return [
        {
          id: 'store-root',
          text: t('store'),
          icon: 'StoreIcon',
          children: [
            {
              id: 'store-root-list',
              text: t('list'),
              icon: 'ListIcon',
              url: Urls.Store,
            },
            {
              id: 'store-root-add',
              text: t('addNew'),
              icon: 'AddIcon',
              url: Urls.StoreAdd,
            },
          ],
        },
        {
          id: 'order-root',
          text: t('order'),
          icon: 'AddShoppingCartIcon',
          children: [
            {
              id: 'order-root-list',
              text: t('list'),
              icon: 'ListIcon',
              url: Urls.Order,
            },
            {
              id: 'order-root-add',
              text: t('addNew'),
              icon: 'AddIcon',
              url: Urls.OrderAdd,
            },
          ],
        },
        {
          id: 'vendor-root',
          text: t('vendor'),
          icon: 'PeopleAltIcon',
          children: [
            {
              id: 'vendor-root-list',
              text: t('list'),
              icon: 'ListIcon',
              url: Urls.StoreVendorPrefix,
            },
            {
              id: 'vendor-root-add',
              text: t('addNew'),
              icon: 'AddIcon',
              url: Urls.StoreVendorAdd,
            },
          ],
        },
        // ...Product.menu,
        ...NewProduct.menu,
        ...menuForOutSideFolders,
      ];
    }

    return [];
  };
  getVendorMenu = () => {
    const { t } = this.props;

    if (this.context.user.type === USER_TYPE_VENDOR) {
      return [
        {
          id: 'order-root',
          text: t('myOrders'),
          icon: 'AddShoppingCartIcon',
          children: [
            {
              id: 'order-root-list',
              text: t('list'),
              icon: 'ListIcon',
              url: Urls.MyOrder,
            },
          ],
        },
      ];
    }

    return [];
  };

  toggleMenuItem = (id) => {
    this.setState({
      openMenus: {
        ...this.state.openMenus,
        [id]: !this.state.openMenus[id],
      },
    });
  };

  getListItem = (item) => {
    const { history } = this.props;
    const Icons = IconPackages[item.icon];
    const ExpandMore = IconPackages['ExpandMore'];
    const ExpandLess = IconPackages['ExpandLess'];
    return (
      <ListItem
        button
        key={item.id}
        onClick={
          item.children
            ? () => this.toggleMenuItem(item.id)
            : () => {
                this.setState({ openMenus: {} });
                history.push(item.url);
              }
        }
      >
        {item.icon && (
          <ListItemIcon>
            <Icons />
          </ListItemIcon>
        )}
        <ListItemText primary={item.text} />
        {item.children ? (
          this.state.openMenus[item.id] ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItem>
    );
  };

  getItem = (item) => {
    const { classes } = this.props;

    return (
      <React.Fragment key={item.id}>
        {this.getListItem(item)}
        {item.children && this.state.openMenus[item.id] && (
          <Collapse in unmountOnExit>
            <List component="div" disablePadding className={classes.childMenu}>
              {item.children.map(this.getListItem)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const DashboardIcon = IconPackages['DashboardIcon'];
    return (
      <div className="sidebar-menu">
        <ListItem
          button
          onClick={(e) => {
            this.props.history.push('/dashboard');
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard')} />
        </ListItem>
        {this.getAdminMenu().map(this.getItem)}
        {this.getOwnerMenu().map(this.getItem)}
        {this.getVendorMenu().map(this.getItem)}
      </div>
    );
  }
}

Sidebar.contextType = GlobalContext;
export default withStyles(useStyles)(withRouter(withTranslation()(Sidebar)));
