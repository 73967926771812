/**
 * Holds routes for UI
 * @type {Object}
 */
const Urls = {};
Urls.base = '';

Urls.AuthBase = `${Urls.base}/auth`;
Urls.Login = `${Urls.AuthBase}/login`;
Urls.Register = `${Urls.AuthBase}/register`;
Urls.ForgotPassword = `${Urls.AuthBase}/forgotPassword`;
Urls.Logout = `${Urls.AuthBase}/logout`;

Urls.StoreBase = `${Urls.base}/store`;
Urls.StoreSelection = `${Urls.StoreBase}/select`;
Urls.StoreAddition = `${Urls.StoreBase}/new`;

Urls.Dashboard = '/Dashboard';

Urls.OwnerPrefix = `/owner`;
Urls.OwnerAdd = `${Urls.OwnerPrefix}/add`;
Urls.OwnerProfileEdit = `${Urls.OwnerPrefix}/profile/edit`;

Urls.VendorPrefix = `/vendor`;
Urls.VendorAdd = `${Urls.VendorPrefix}/add`;
Urls.VendorEdit = id => `${Urls.VendorPrefix}/${id}`;

Urls.Store = `/stores`;
Urls.StoreAdd = `${Urls.Store}/add`;
Urls.StoreEdit = id => `${Urls.Store}/${id}`;

Urls.StoreVendorPrefix = `/vendor`;
Urls.StoreVendorAdd = `${Urls.StoreVendorPrefix}/add`;
Urls.StoreVendorEdit = id => `${Urls.StoreVendorPrefix}/${id}`;

Urls.Order = `/orders`;
Urls.OrderAdd = `${Urls.Order}/add`;
Urls.OrderEdit = id => `${Urls.Order}/${id}`;
Urls.OrderItems = id => `${Urls.OrderEdit(id)}/items`;

Urls.MyOrder = `/my-orders`;
Urls.MyOrderAdd = `${Urls.MyOrder}/add`;
Urls.MyOrderEdit = id => `${Urls.MyOrder}/${id}`;
Urls.MyOrderItems = id => `${Urls.MyOrderEdit(id)}/items`;

Urls.Vendor = `/vendor`;
Urls.VendorAdd = `${Urls.Vendor}/add`;

Urls.VendorOrdersPrefix = `${Urls.Vendor}/orders`;
Urls.VendorOrdersAdd = `${Urls.VendorOrdersPrefix}/add`;

Urls.Category = `/category`;
Urls.CategoryAdd = `${Urls.Category}/add`;

Urls.SubCategory = `${Urls.Category}/sub`;
Urls.SubCategoryAdd = `${Urls.SubCategory}/add`;

export default Urls;
