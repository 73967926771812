import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';

import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Size',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ListProductSize extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      productSize: [],
    };
  }

  componentDidMount() {
    this.fetchProductSize();
  }

  fetchProductSize = async (ownerId) => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.PRODUCT_SIZE_LIST);
      if (res.data.success) {
        this.setState({
          inProgress: false,
          productSize: res.data.data,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error('Error while fetching product size');
    }

    this.setState({
      inProgress: false,
    });
  }

  deleteProductSize = async (id) => {
    const res = await axiosInstance.delete(Apiurls.PRODUCT_SIZE_EDIT(id));
    if (res.data.success) {
      this.fetchProductSize();
      window.mNotify.show('Deleted the size successfully.');
    } else {
      window.mNotify.show('Sorry!.. Could not delete the product size');
    }
  }

  render() {
    const { inProgress, productSize } = this.state;
    const { history } = this.props;

    return (
      <div className={this.props.classes.paper}>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        />
        <h2>Sizes</h2>
        <div className={this.props.classes.titleContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductSizeAdd);
            }}
          >
            Create New
          </Button>
        </div>
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell>Size Name</TableCell>
                  <TableCell>Size Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                productSize.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <CheckIcon
                        size={12}
                        style={{
                          display: 'inline-block',
                          color: row.isActive ? 'green' : 'grey',
                        }}
                      />
                      <span>{row.sizeName}</span>
                    </TableCell>
                    <TableCell>
                      {row.sizeDescription}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                        <Button
                          onClick={(e)=>{
                            history.push(Urls.ProductSizeEdit(row.id));
                          }}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                         onClick={(e)=>{
                          if (window.confirm(`Are you sure you want to delete ${row.productDescription} ?`)) {
                            this.deleteProductSize(row.id);
                          }
                        }}
                        >
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
          </Table>
          {
            inProgress && <CircularProgress />
          }
        </TableContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListProductSize));
