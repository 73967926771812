import React from 'react';
// import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// import Grid from '@mui/material/Grid';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// import Moment from 'react-moment';

import {
  USER_TYPE_OWNER,
  USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';

import NegotiationReject from './negotiationReject';

import styles from 'src/styles/negotiationBox';

const Histroy = ({QuestionId, negotiationId, negotiationHistory, negotiationQuestionList, negotiationResponse, fetchNegotiationHistory}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);

  // const [inProgress, setInProgress] = useState(true);
  const [formData, setFormData] = React.useState({
    hisId: '',
    status: '',
    answer: '',
    itemId: negotiationId,
    questionId: QuestionId,
  });

  const negotiationRejectedResponse = (datas) => {
    setFormData({
      ...formData,
      ...datas,
    })
    // setInProgress(false)
  }


  const onSave = async (data) => {
    const res = await axiosInstance.post(Apiurls.NEGOTIATION_RESPONSE(data.hisId), { status:data.status, answer: data.answer });
    if(res.data.success)
    {
      window.mNotify.error('Your Negotiation has been Responded');
      fetchNegotiationHistory();
    }
    else
    {
      window.mNotify.error('Please fill out this field');
    }
  };

    const Question = data => <b>{data}</b>
    const Option_1 = data => <b>{data.answer}</b>
    const Option_2 = data => <><b>{data.answer} </b></>
    const CONTENT_TYPE = data => {
      if(data.requestedBy === USER_TYPE_VENDOR)
      {
        if((JSON.parse(data.response).action === false) && (JSON.parse(data.response).action !== undefined))
          {
             return ' has been reject the ';
          }
           if(JSON.parse(data.response).action === undefined)
          {
             return ' want change the ';
          }
          if((JSON.parse(data.response).action === true) && (JSON.parse(data.response).action !== undefined))
          {
             return ' has been accepted the ';
          }
      }
      if(data.requestedBy === USER_TYPE_OWNER)
      {
        if((JSON.parse(data.response).action === false) && (JSON.parse(data.response).action !== undefined))
          {
             return ' has been reject the ';
          }
         if(JSON.parse(data.response).action === undefined)
          {
             return ' want change the ';
          }
          if((JSON.parse(data.response).action === true) && (JSON.parse(data.response).action !== undefined))
          {
             return ' has been accepted the ';
          }
      }
    }
    const Item = ({data}) => {

      return (
      <>
        {
          Object.keys(data).map((Vs, Ks) => (
              <TableRow keys={data[Vs].id}>
                <TableCell><b>{data[Vs].sequenceId}</b>) <b>{data[Vs].requestedBy}</b>
               {CONTENT_TYPE(data[Vs])} {Question(negotiationQuestionList[QuestionId].question)} is <mark>{(data[Vs].requestedBy === USER_TYPE_VENDOR) ? Option_1(data[Vs]) : Option_2(data[Vs])}</mark>
                  {formData.hisId===data[Vs].id ? <NegotiationReject data={formData} previousNegotiation={data[Vs]} QuestionId={QuestionId} negotiationQuestionList={negotiationQuestionList} onSave={onSave}/> : null }
                </TableCell>
                <TableCell >
                  {
                    (data[Vs].requestedBy !== USER_TYPE_VENDOR) && (Object.keys(data).length === data[Vs].sequenceId)? <ButtonGroup  disableElevation size="small" variant="outlined" color="secondary">
                    <Button  onClick={(e)=>{
                      negotiationResponse({
                        hisId: data[Vs].id,
                        status: true
                      })
                    }} className={classes.buttonsize} >  Accepted  </Button>
                    <Button  onClick={(e)=>{
                      negotiationRejectedResponse({
                          hisId: data[Vs].id,
                          status: false,
                          negotiation: {
                            answer: ''
                          }
                        })
                    }} className={classes.buttonsize} >  Rejected  </Button>
                  </ButtonGroup>: <>
                  {(JSON.parse(data[Vs].response).action === false) && (JSON.parse(data[Vs].response).action !== undefined)? <span className={classes.spanDanger}>Rejected</span> : null }
                  {(JSON.parse(data[Vs].response).action === true) && (JSON.parse(data[Vs].response).action !== undefined)? <span className={classes.spanSuccess}>Accepted</span> : null }
                  {(JSON.parse(data[Vs].response).action === undefined)? <span className={classes.spanPrimary}>{data[Vs].requestedBy} Initiated</span> : null }

                  </>
                  }
                </TableCell>
              </TableRow>
          ))
        }

      </>
    )
  }

    const List = () => {
    return (
      <>
        {
          Object.keys(negotiationHistory).map((V, K) => (
            <TableBody>
            <Item data={negotiationHistory[V]}/>
             </TableBody>
          ))
        }

      </>
    )
  };

    const NegoQuestList = () => {    return (
      <>
      <TableContainer >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Decription</TableCell>
              <TableCell >Action</TableCell>
            </TableRow>
          </TableHead>

          <List />
        </Table>
      </TableContainer>
      </>
    )
  };

  return (
      <>

        <NegoQuestList />
      </>
  );
}

export default Histroy;
