import React, { useCallback , useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import styles from 'src/styles/productBrandForm';
import { storeProductHierarchyFormSchema } from "src/formValidation/formValidation";

const ProductHierarchyForm = ({ data = {}, errorMessage, inProgress, onSave, hierarchy, errors }) => {
  const classes = styles();
  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  useEffect( () => {
    validationError({ errors });
  }, [errors])

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Store product Hierarchy error occurs!');
    }
  };

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeProductHierarchyFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={3}>

      <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Hierarchy Type</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              fullWidth
              error={!!(validationDataError.errors && validationDataError.errors.hierarchyType)}
              helperText={validationDataError.errors ? validationDataError.errors.hierarchyType : ''}
              id="demo-simple-select-outlined"
              value={formData.hierarchyType}
              className={classes.selectEmpty}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  hierarchyType: e.target.value,
                });
              }}
            >
              {
                Object.keys(hierarchy.feildsType).map((type, key) => (
                  <MenuItem key={key} value={type}>{hierarchy.feildsType[type]}</MenuItem>
                ))
              }
            </Select>
            { validationDataError.errors ? <FormHelperText id="my-helper-text" style={{ color: "red" }}>{validationDataError.errors.hierarchyType}</FormHelperText> : '' }
          </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
                <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Hierarchy Value"
                    error={!!(validationDataError.errors && validationDataError.errors.hierarchyValue)}
                    helperText={validationDataError.errors ? validationDataError.errors.hierarchyValue : ''}
                    value={formData.hierarchyValue}
                    variant="outlined"
                    onChange={(e)=>{
                      setFormData({
                        ...formData,
                        hierarchyValue: e.target.value,
                      });
                    }}
                />
            </div>
        </Grid>


        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
              <FormControl component="fieldset" fullWidth style={{ textAlign: 'left' }}>
                  <FormLabel component="legend"  >Status</FormLabel>
                  <RadioGroup row aria-label="position"
                      onChange={(e) => {
                          setFormData({
                              ...formData,
                              isActive: Number(e.target.value),
                          });
                      }}
                      value={formData.isActive}
                      name="isActive" defaultValue="top">
                      <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Active"
                      />
                      <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="Inactive"
                      />
                  </RadioGroup>
                </FormControl>
            </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} style={{color: "white"}}/>}
            {data.id ? 'Update' : 'Create'}
          </Button>
          {/* <Button */}
          {/*   variant="contained" */}
          {/*   color="secondary" */}
          {/*   style={{ margin: '5px' }} */}
          {/*   onClick={(e) => { */}
          {/*     setFormData({ ...data }); */}
          {/*   }} */}
          {/* > */}
          {/*   Reset */}
          {/* </Button> */}
          <p>{errorMessage}</p>
        </Grid>
      </Grid>
    </form>
  );
}

export default ProductHierarchyForm;
