import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';


import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';

//components
import NegoQuestList from './negotiationquestion';
import AnswerSection from './negotiationAnswer';
import NegotiationHistory from './negotiationHistory';

import styles from 'src/styles/negotiationBox';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const NegotiationBox = ({negotiation, changeStatus}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);


  const [inProgress, setInProgress] = useState(true);
  const [negotiationQuestionList, setNegotiationQuestionList] = useState({});
  const [formData, setFormData] = React.useState(
    {
      questionId: '',
      answer: ''
    });

  useEffect(() => {
      fetchNegotiationQuestion()
  }, [setInProgress])

  const fetchNegotiationQuestion = async () => {
      setInProgress(true);
      const res = await axiosInstance.get(Apiurls.STORE_NEGOTIATION_QUESTION_LIST);
      // const data = res.data;
      if (res.data.success) {
        setNegotiationQuestionList(res.data)
        setInProgress(false);
      }

  }

  const [open, setOpen] = useState(negotiation.status);

  const handleClose = () => {
    setOpen(false);
    changeStatus(false)
  };

  const selecteQuestion = (data) => {
    setFormData({
      ...formData,
      questionId: data,
      answer: ''
    })
  };

  const onSave = async (data) => {
    const res = await axiosInstance.post(Apiurls.NEGOTIATION, data);
    if(res.data.success)
    {
      handleClose()
      window.mNotify.error('Your Negotiation has been Raised');
    }
    else
    {
      window.mNotify.error('Please fill out this field');
    }
  };

  const HeaderBox = () => {
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">If any Negotiation? {negotiation.itemId}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  };

  const ContentBox = () => {
    return (
      <>
      <DialogContent dividers>
      {
        !inProgress ?
        <>
           <NegoQuestList QuestionList={negotiationQuestionList} selecteQuestion={selecteQuestion} Data={formData} />
        </>
        : <div className={classes.root}>  <CircularProgress />  </div>
      }

      </DialogContent>

      <DialogActions >
        {
          (formData.questionId>0) ? <>
            <AnswerSection QuestionList={negotiationQuestionList}  Data={formData} onSave={onSave} handleClose={handleClose} negotiationId={negotiation.itemId} />
          </>
          : null
        }

      </DialogActions>
      { (formData.questionId>0) ?
      <DialogContent dividers>
          <NegotiationHistory itemId={negotiation.itemId} questionId={formData.questionId} negotiationQuestionList={negotiationQuestionList.data}/>
      </DialogContent> : null
      }
      </>
    )
  };

  return (
    <div>
      <Dialog onClose={handleClose}  fullWidth={true}  maxWidth="md" aria-labelledby="customized-dialog-title" open={open}>
          <HeaderBox />
          <ContentBox />
      </Dialog>
    </div>
  );
}

export default NegotiationBox;
