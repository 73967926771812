import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from 'src/constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import ProductOrderForm from './partials/productOrderForm';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Order',
    url: Urls.Order,
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

class ManageProduct extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      searchableList: {},
      formData: [],
      order:{
        id:''
      },
      orginalData: {},
      inProgress: false,
      isLoadingData: true,
    }
  }

  componentDidMount() {
    this.getOrderItems();
  }

  getOrderItemSearchList = async () => {
    const res = await axiosInstance.get(Apiurls.ORDER_ITEMS_SEARCH_LIST);
    if (res.data.success) {
      return {
        searchableList: res.data.data.searchableList || {},
      }
    } else {
      window.mNotify.error('Error while fetching items list');
    }
  };

  getOrderItems = async () => {
    let newStates = {};
    if (this.props.match && this.props.match.params.id) {
      //this.setState({ isLoadingData: true });
      const res = await axiosInstance.get(Apiurls.ORDER_EDIT(this.props.match.params.id));
      if (res.data.success) {
        newStates = {
          orginalData: res.data.data,
          order: res.data.data.order,
          formData: [ ...res.data.data.items ],
        }
      }
    }
    newStates = {...newStates,
      ...(await this.getOrderItemSearchList()),
      isLoadingData: false,
    }
    if (this.props.match && this.props.match.params.id) {
      newStates.formData.forEach((values, key) => {
        values.proTitle = newStates.searchableList[values.priceId].title;
        values.vendorName = newStates.searchableList[values.priceId].vendorName;
      });
    }
    this.setState(newStates);
  }

  resetState = () => {
    this.setState({
      formData: {},
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        Products: data
      };

      let res;
      if (orginalData.order && orginalData.order.id) {
        res = await axiosInstance.put(Apiurls.ORDER_EDIT(orginalData.order.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.ORDER, body);
      }
      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.Order);
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });
      window.mNotify.error('Error while creating order. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const {
      order,
      formData,
      inProgress,
      isLoadingData,
      searchableList,
    } = this.state;

    return (
      <div className={this.props.classes.paper}>
        {/* <>
        <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        /> */}
        <Typography variant="h6">{(order && order.id) ? 'Edit' : 'Add'} Order </Typography>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <ProductOrderForm
              data={formData}
              order={order}
              searchableList={searchableList}
              inProgress={inProgress}
              onSave={this.onSave}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageProduct));
