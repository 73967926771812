const API_BASE = '/api';

const FILE_UPLOAD = `${API_BASE}/file-upload`;
const FILE_UPLOAD_EDIT = id => `${FILE_UPLOAD}/${id}`;
const FILE_UPLOAD_LIST = `${FILE_UPLOAD}/getall`;

const FILE_UPLOAD_MORPH = `${API_BASE}/file-upload-morph`;
// const FILE_UPLOAD_MORPH_EDIT = id => `${FILE_UPLOAD_MORPH}/${id}`;
// const FILE_UPLOAD_MORPH_LIST = `${FILE_UPLOAD_MORPH}/getall`;

const FILE_UPLOAD_MORPH_RELATED_EDIT = (relatedId, id) => `${FILE_UPLOAD_MORPH}/related/${relatedId}/image/${id}`;

const FILE_UPLOAD_ALL_IMAGE = `${FILE_UPLOAD}/image`;

const apiUrls = {
  FILE_UPLOAD,
  FILE_UPLOAD_EDIT,
  FILE_UPLOAD_LIST,

  FILE_UPLOAD_MORPH_RELATED_EDIT,
  FILE_UPLOAD_ALL_IMAGE,
};

export default apiUrls;
