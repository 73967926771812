import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CardMedia from '@mui/material/CardMedia';

import styles from '../styles/productDashboard';

const ImageList = ({ itemData = [], inProgress, onDelete  }) => {
  const classes = styles();
  const dataList = itemData;

  return (
  <>
    {Object.keys(dataList).map((item) => (
        <Grid key={dataList[item].id} item xs={12} sm={2} style={{marginTop: "10px"}}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
              className={classes.media}
              image={dataList[item].url}
              title={dataList[item].name}
              />
            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }} >
              <Button disabled={inProgress} variant="contained" size="small" color="secondary" endIcon={<DeleteForeverIcon />}
                   onClick={() => {
                      let idStatus = delete dataList[item];
                      if (idStatus) {
                        onDelete(Object.keys(dataList))
                      }
                  }}
             >Delete</Button>
            </CardActions>
          </Card>
        </div>
      </Grid>
    ))}
  </>
  );
}

export default ImageList;
