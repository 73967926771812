import React from 'react';
import Loader from 'src/components/loader';

import {
  USER_TYPE_OWNER,
  // USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import axiosInstance from 'src/http';

require('./pages/owner/store/select.scss');

function InitNewFeature(WrappedComponent) {
  class InitNewFeatures extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        newFeatureStatus: false,
        newFeature: {
          customer: {
            inProgress: false,
            newFeaturesRoutesDetails: [],
            newFeaturesMenuDetails: [],
          },
          billing: {
            inProgress: false,
            newFeaturesRoutesDetails: [],
            newFeaturesMenuDetails: [],
          },
        },
      };
    }

    componentDidMount() {
      const contextState = this.context;
      if (contextState.user.type === USER_TYPE_OWNER) {
        this.storeFeature();
      } else {
        this.context.setChangeStoreNewFeatures({
          newFeatures: {
            storeNewFeatures: {},
            storeNewFeaturesStatus: true,
          },
        });
      }
    }

    storeFeature = async () => {
      const contextState = this.context;
      // const res = await axiosInstance.get(Apiurls.STORE_FEATURE_LIST);
      // if (res.data.data.length !== 0) {
        await contextState.setChangeStoreNewFeatures({
          newFeatures: {
            ...contextState.newFeatures,
            storeNewFeaturesAccess: {

              /** remember We need to remove the code */

              // ...JSON.parse(res.data.data[0].featureSetting),

              customer: 1,
              billing: 1
            },
          },
        });
      // }
      await this.Feature();
    };

    Feature = async () => {
      const contextState = this.context;
      const states = this.state;
      let initFeatureCom = {}

      if (Object.keys(contextState.newFeatures.storeNewFeaturesAccess).length) {
        const appData = Object.keys(contextState.newFeatures.storeNewFeaturesAccess).map( async (val, key) => {
            if (states.newFeature[val] && states.newFeature[val].newFeaturesRoutesDetails.length === 0 && states.newFeature[val].newFeaturesMenuDetails.length === 0) {
            let featureData = await import('src/newFeatures/' + val + '/App')
            if (featureData.default.name === val) {
              return {
                [val] : {
                  inProgress: true,
                  newFeaturesRoutesDetails: [...featureData.default.routers],
                  newFeaturesMenuDetails: [...featureData.default.menu],
                }
              }
            }
          }
        })

        const originalAppData = await Promise.all(appData)
        originalAppData.forEach( (item) => {
          initFeatureCom = {
            ...initFeatureCom,
            ...item
          }
        })
        contextState.setChangeStoreNewFeatures({
          newFeatures: {
            ...contextState.newFeatures,
            storeNewFeatures: { ...initFeatureCom },
            storeNewFeaturesStatus: true,
          },
        });
      } else {
        contextState.setChangeStoreNewFeatures({
          newFeature: {
            ...contextState.newFeature,
            // storeModules: { ...this.state.module }
            storeNewFeaturesStatus: true,
          },
        });
      }
    };

    render() {
      const contextState = this.context;
      return !contextState.newFeatures.storeNewFeaturesStatus ? (
        <Loader title="Initializing features (New)" />
      ) : (
        <WrappedComponent {...this.props} state={this.state} />
      );
    }
  }
  InitNewFeatures.contextType = GlobalContext;
  return InitNewFeatures;
}

export default InitNewFeature;
