import {
  USER_TYPE_OWNER,
  USER_TYPE_VENDOR,
} from 'src/constants';
import Urls from 'src/constants/urls';

function init(contextRef, axiosInstance, ) {
  // Add a request interceptor
  axiosInstance.interceptors.request.use((config) => {
    config.headers['X-Client-Name'] = __CLIENT_NAME__;
    config.headers['X-Client-Version'] = __CLIENT_VERSION__;
    // If we have token, add it to every request
    if (contextRef.user && contextRef.user.token) {
      config.headers.Authorization = `Token ${contextRef.user.token}`;
      config.headers.mUserType = contextRef.user.type;

      // TODO: Move this below if to be generic. Save the current store in `contextRef.currentStore`
      if (contextRef.user.type === USER_TYPE_OWNER) {
        config.headers.currentStore = contextRef.currentStore;
      } else if(contextRef.user.type === USER_TYPE_VENDOR) {
        config.headers.currentStore = this.context[contextRef.user.type].id;
      }
    }

    return config;
  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // If we are already not in one of the loggout states, then do a logout
    if (!window.location.pathname.startsWith('auth') && error.response.status === 401) {
      contextRef.logout();
      window.location.href = Urls.Logout;
      return;
    }

    return Promise.reject(error);
  });
}

export default init;
