import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  Grid: {
    width: '100%',
    padding: '17px 0px',
    background: '#d7d6d6',
  },
  Active: {
    width: '100%',
    padding: '17px 0px',
    background: '#293889',
    color: 'white',
    "&:hover": {
      background: '#293889',
      color: 'white',
    },
  },
  saveButton: {
    fontSize:'15px',
    padding: '13px 5px',
    margin: '3px',
    color: '#8b8888',
    border: '1px solid #c4c4c4',
    background: '#ffffff',
    float:'right',
    "&:hover": {
      background: '#3f51b5',
      color: 'white',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '50%',
  },
  buttonsize: {
    fontSize:'10px',
    padding: '5px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  spanDanger: {
    color: '#fff',
    backgroundColor: '#dc3545',
    display: 'inline-block',
    padding: '.25em .4em',
    fontSize: '55%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '.25rem',
    margin: '2px'
  },
  spanSuccess: {
    color: '#fff',
    backgroundColor: '#28a745',
    display: 'inline-block',
    padding: '.25em .4em',
    fontSize: '55%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '.25rem',
    margin: '2px'
  },
  spanPrimary: {
    color: '#fff',
    backgroundColor: '#007bff',
    display: 'inline-block',
    padding: '.25em .4em',
    fontSize: '55%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '.25rem',
    margin: '2px'
  }
}));

export default styles;
