import TextileUrl from './constants/urls';

const FeatureSidebar = [
  {
    id: 'product-root',
    text: 'Product',
    icon: 'AppsIcon',
    url: TextileUrl.ProductDashboard,
  },
]

export default FeatureSidebar;

