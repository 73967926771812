export const LOCAL_STORAGE_LOGIN_OBJECT = 'loginUserObject';

export const USER_TYPE_OWNER = 'owner';
export const USER_TYPE_VENDOR = 'vendor';
export const USER_TYPE_OLD_VENDOR = 'old-vendor';
export const USER_TYPE_ADMIN = 'admin';

export const CUT_OFF_TIMES = [
  {
    text: '30 minutes',
    value: 1800,
  },
  {
    text: '1 hour',
    value: 3600,
  },
  {
    text: '1 hour 30 minutes',
    value: 5400,
  },
  {
    text: '2 hour',
    value: 7200,
  },
];
