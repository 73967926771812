import React, { Component } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import pagesIndexStyles from 'src/styles/pagesIndex';
import commonTableStyles from 'src/styles/commonTable';
import Apiurls from 'src/constants/apiUrls';

const RecentOrders = () => {
  const classes = commonTableStyles();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { t } = useTranslation();

  const getOrders = async () => {
    setRows([]);
    setIsLoading(true);
    const res = await axiosInstance.get(Apiurls.ORDER_RESPONSE_LIST);
    setRows(res.data.data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getOrders();
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <div className={classes.gridTitle}>
        <Typography variant="h6">
          {t('recentOrders')}
        </Typography>
        <IconButton color="primary" component="span" onClick={getOrders} size="large">
          <RefreshIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('orderId')}</TableCell>
              <TableCell>{t('status')}</TableCell>
              <TableCell>{t('responseStatus')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.orderId}>
                <TableCell align="right">{row.createdAt}</TableCell>
                <TableCell component="th" scope="row">
                  {row.orderId}
                </TableCell>
                <TableCell align="right">{row.orderStatus}</TableCell>
                <TableCell align="right">{row.orderResponseStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && <CircularProgress />}
      </TableContainer>
    </Grid>
  );
}

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user} = this.context;
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5">
          {t('welcome')} {user.firstname}
        </Typography>
         <Grid container spacing={2}>
          <RecentOrders />
        </Grid>
      </div>
    )
  }
}

Index.contextType = GlobalContext;

export default withStyles(pagesIndexStyles)(withTranslation()(Index));
