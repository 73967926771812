import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import ProductTypeForm from './partials/productTypeForm';
import Button from '@mui/material/Button';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Add Product Type',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: `1rem 0`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  h2: {
    margin: 0,
    marginBottom: '1rem',
  }
});

const EmptyValue = (props) => {
  return {
    productTypeDescription: '',
    productTypeName: '',
    isActive: 1,
  }
}

class ManageTypeProduct extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...EmptyValue(this.props)
      },
      orginalData: {},
      inProgress: false,
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      errors : {}
    }
  }

  componentDidMount() {
    this.getStoreData();
  }

  getStoreData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_TYPE_EDIT(this.props.match.params.id));
      //console.log(res);
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data },
          isLoadingData: false,
        });
      }
    }
  }

  resetState = () => {
    this.setState({
      formData: {
        ...EmptyValue(this.props)
      },
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        productTypeDescription: data.productTypeDescription,
        productTypeName: data.productTypeName,
        isActive: data.isActive,
      };

      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_TYPE_EDIT(orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_TYPE, body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.ProductType);
      }
      if (!res.data.success && res.data.errors) {
        this.setState({ errors: res.data.errors });
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        return;
      }
      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });
      window.mNotify.error('Error while creating product type. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData , errors } = this.state;

    return (
      <div className={this.props.classes.paper}>
        <div className={this.props.classes.pageHead}>
          <MyBreadCrumbs
            items={breadcrumbItems}
            history={history}
          />
          <div className={this.props.classes.titleContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(Urls.ProductType);
              }}
              style={{ marginRight: '5px' }}
            >
              View Products
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                history.push(Urls.ProductUnit);
              }}
            >
              View Unit
            </Button>
          </div>
        </div>
        <Paper className={this.props.classes.paper}>
          <h2 className={this.props.classes.h2}>{formData.id ? 'Edit' : 'Add'} Product Type</h2>
          {
            isLoadingData ?
              <CircularProgress color="inherit" size={14} />
              :
              <ProductTypeForm
                data={formData}
                inProgress={inProgress}
                onSave={this.onSave}
                errors={errors}
              />
          }
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageTypeProduct));
