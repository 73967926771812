import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


import styles from 'src/styles/productForm';
import { storeProductFormSchema } from "../validation/formValidation";

const ProductForm = ({ data = {}, errorMessage, inProgress, onSave }) => {
  const classes = styles();

  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Store Product error occurs!');
    }
  };

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
    storeProductFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);

  return (

    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Product Name"
              error={!!(validationDataError.errors && validationDataError.errors.productName)}
              helperText={validationDataError.errors ? validationDataError.errors.productName : ''}
              value={formData.productName}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productName: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Product Description"
              error={!!(validationDataError.errors && validationDataError.errors.productDescription)}
              helperText={validationDataError.errors ? validationDataError.errors.productDescription : ''}
              value={formData.productDescription}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productDescription: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: '5px' }}
            onClick={(e) => {
              setFormData({ ...data });
            }}
          >
            Reset
          </Button>

          <p>{errorMessage}</p>
        </Grid>
      </Grid>
      </form>

  );
}

export default ProductForm;
