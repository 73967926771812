const API_BASE = '/api';

const PRODUCT = `${API_BASE}/textile/product`;
const PRODUCT_EDIT = id => `${PRODUCT}/${id}`;
const PRODUCT_LIST = `${PRODUCT}/getall`;

const PRODUCT_BRAND = `${PRODUCT}/brand`;
const PRODUCT_BRAND_EDIT = id => `${PRODUCT_BRAND}/${id}`;
const PRODUCT_BRAND_LIST = `${PRODUCT_BRAND}/getall`;

const PRODUCT_SIZE = `${PRODUCT}/size`;
const PRODUCT_SIZE_EDIT = id => `${PRODUCT_SIZE}/${id}`;
const PRODUCT_SIZE_LIST = `${PRODUCT_SIZE}/getall`;

const PRODUCT_COLOUR = `${PRODUCT}/colour`;
const PRODUCT_COLOUR_EDIT = id => `${PRODUCT_COLOUR}/${id}`;
const PRODUCT_COLOUR_LIST = `${PRODUCT_COLOUR}/getall`;

const PRODUCT_FIT = `${PRODUCT}/fit`;
const PRODUCT_FIT_EDIT = id => `${PRODUCT_FIT}/${id}`;
const PRODUCT_FIT_LIST = `${PRODUCT_FIT}/getall`;

const PRODUCT_MATERIAL = `${PRODUCT}/material`;
const PRODUCT_MATERIAL_EDIT = id => `${PRODUCT_MATERIAL}/${id}`;
const PRODUCT_MATERIAL_LIST = `${PRODUCT_MATERIAL}/getall`;

const PRODUCT_TAGGING = `${PRODUCT}/tagging`;
const PRODUCT_TAGGING_EDIT = id => `${PRODUCT_TAGGING}/${id}`;
const PRODUCT_TAGGING_LIST = `${PRODUCT_TAGGING}/getall`;

const PRODUCT_MASTER_MAP = `${PRODUCT}/map`;
const PRODUCT_MASTER_MAP_EDIT = id => `${PRODUCT_MASTER_MAP}/${id}`;
const PRODUCT_MASTER_MAP_LIST = `${PRODUCT_MASTER_MAP}/getall`;
const PRODUCT_MASTER_MAP_ITEMS_LIST = `${PRODUCT_MASTER_MAP}/search/items`;

const PRODUCT_MASTER_PRICE = masterProductId => `${PRODUCT_EDIT(masterProductId)}/price`;
const PRODUCT_MASTER_PRICE_EDIT = (masterProductId, id) => `${PRODUCT_EDIT(masterProductId)}/price/${id}`;
const PRODUCT_MASTER_PRICE_LIST = masterProductId => `${PRODUCT_EDIT(masterProductId)}/price/getall`;
const PRODUCT_MASTER_PRICE_VENDOR_LIST = `${PRODUCT}/price/search/items`;

const PRODUCT_MASTER_PRODUCT_IMAGE = masterProductId => `${PRODUCT_EDIT(masterProductId)}/image`;
const PRODUCT_MASTER_PRODUCT_IMAGE_LIST = masterProductId => `${PRODUCT_EDIT(masterProductId)}/image/getall`;
const PRODUCT_MASTER_PRODUCT_IMAGE_EDIT = (masterProductId, id) => `${PRODUCT_EDIT(masterProductId)}/image/${id}`;

const ALL_MASTER_PRODUCT_IMAGE_LIST = `${PRODUCT}/image/getall`;
const MASTER_PRODUCT_IMAGE_MAP = `${PRODUCT}/image/map`;

const apiUrls = {
  PRODUCT,
  PRODUCT_LIST,
  PRODUCT_EDIT,

  PRODUCT_BRAND,
  PRODUCT_BRAND_EDIT,
  PRODUCT_BRAND_LIST,

  PRODUCT_SIZE,
  PRODUCT_SIZE_EDIT,
  PRODUCT_SIZE_LIST,

  PRODUCT_COLOUR,
  PRODUCT_COLOUR_EDIT,
  PRODUCT_COLOUR_LIST,

  PRODUCT_FIT,
  PRODUCT_FIT_EDIT,
  PRODUCT_FIT_LIST,

  PRODUCT_MATERIAL,
  PRODUCT_MATERIAL_EDIT,
  PRODUCT_MATERIAL_LIST,

  PRODUCT_TAGGING,
  PRODUCT_TAGGING_EDIT,
  PRODUCT_TAGGING_LIST,

  PRODUCT_MASTER_MAP,
  PRODUCT_MASTER_MAP_EDIT,
  PRODUCT_MASTER_MAP_LIST,
  PRODUCT_MASTER_MAP_ITEMS_LIST,

  PRODUCT_MASTER_PRICE,
  PRODUCT_MASTER_PRICE_EDIT,
  PRODUCT_MASTER_PRICE_LIST,
  PRODUCT_MASTER_PRICE_VENDOR_LIST,

  PRODUCT_MASTER_PRODUCT_IMAGE,
  PRODUCT_MASTER_PRODUCT_IMAGE_LIST,
  PRODUCT_MASTER_PRODUCT_IMAGE_EDIT,

  ALL_MASTER_PRODUCT_IMAGE_LIST,
  MASTER_PRODUCT_IMAGE_MAP
};

export default apiUrls;
