import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

/**
 * Renders Breadcrums. Every item is a link except the last one.
 * @param {Object} props
 * @param {Function} [props.t] `t` function provided by i18n
 * @param {Array} props.items list of items to be rendered
 * @param {Function} props.history `history` function provided by react router
 * @returns {JSX.Element}
 */
const MyBreadCrumbs = ({ t = null, items, history }) => (
  <Breadcrumbs aria-label="breadcrumb">
    {
      items.map(i => (
        i.url ?
        (
         <Link
           key={i.url}
           color="inherit"
           onClick={(e) => {
             history.push(i.url);
           }}
           underline="hover">
            {t ? t(i.title) : i.title}
          </Link>
         ) : (<Typography style={{ textTransform: 'capitalize' }} key={i.title}>{t ? t(i.title) : i.title}</Typography>)
      ))
    }
  </Breadcrumbs>
);

export default MyBreadCrumbs;
