import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  });

class AddOwner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message:'',
            action:'ADD',
            formData: {
                ownerName: '',
                ownerAddress: '',
                ownerCountry: '',
                ownerStatus: 1,
                ownerUpdateId: ''
              },
              listOwners:[],
            ownerNameError:false
        }
    }

    componentDidMount(){
        this.fetchListOwners();
    }

    fetchListOwners() {
        fetch('/api/owner/getall')
        .then((response) => response.json()).then((data) => {
           //console.log(data.values);
           this.setState({listOwners: data.values})
        });
    }

    editOwnerDetails(getId){
        //console.log(getId);
        fetch('/api/owner/'+getId ,{
            method : 'get'
        })
        .then((res) => {
            return res.json();
        }).then((json) => {
            this.setState({
                action:'UPDATE',
                formData: {
                    ownerName: json[0].ownerName,
                    ownerAddress: json[0].address,
                    ownerCountry: json[0].country,
                    ownerStatus: json[0].isActive,
                    ownerUpdateId: json[0].id,
                }
            })
            //console.log(json[0].id);
        });
    }

    deleteOwnerDetails(getId){
        //console.log(getId);
        fetch('/api/owner/'+getId ,{
            method : 'delete'
        })
        .then((res) => {
            return res.json();
        }).then((json) => {
            //console.log(json);
            if(json.success === true)
            {
                this.fetchListOwners();
                this.ResetStates();
                window.mNotify.show(json.message, 'Owners details has been deleted successfully');
                setTimeout(() => this.setState({message:''}), 4000);
            }
            else{
                window.mNotify.show('Sorry!.. Owners details has been deleted unsuccessfully');
            }
            //this.setState({productPriceOption: json.message});
        });
    }

    ResetStates = () => {
        this.setState({
            action:'ADD',
            formData: {
                ownerName: '',
                ownerAddress: '',
                ownerCountry: '',
                ownerStatus: 1,
                ownerUpdateId: ''
            },
        });
    }
    async onSubmit(e)
    {
        e.preventDefault();
        try {
                await fetch('/api/owner' ,{
                method : 'post',
                headers : {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                },
                body : JSON.stringify({
                    ownerId : "OWN0006",
                    ownerName : this.state.formData.ownerName,
                    address : this.state.formData.ownerAddress,
                    country : this.state.formData.ownerCountry,
                    isActive : this.state.formData.ownerStatus,
                    id : this.state.formData.ownerUpdateId
                })
            }).then((response) => response.json()).then((data) => {
                if(data.success === true){
                    window.mNotify.show(data.message, 'success');
                    this.setState({
                        message : data.message,
                    });
                    this.ResetStates();
                    this.fetchListOwners();
                }
                else{
                    window.mNotify.show('Sorry!.. Owner details has been added unsuccessfully');
                    this.setState({
                        message : 'Sorry!.. Owner details has been added unsuccessfully',
                    })
                }
            })
        }
        catch (error)
        {
            console.log(error);
        }
    }

    render()
    {
        return (
            <div>
            <Breadcrumbs aria-label="breadcrumb">
            <Link
                color="inherit"
                href="/"
                onClick={
                    (e) => {
                        this.props.history.push('/');
                    }
                }
                underline="hover">
            Home
            </Link>
            <Typography >Manage Owner</Typography>
            <Typography color="textPrimary">Add Owner</Typography>
            </Breadcrumbs>
              <h2>Add Owner</h2>
              <form onSubmit={this.onSubmit.bind(this)} className={this.props.classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Owner Name"
                            value={this.state.formData.ownerName}
                            variant="outlined"
                            onChange={(e)=>{
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        ownerName: e.target.value,
                                    },
                                })
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Owner Address"
                            value={this.state.formData.ownerAddress}
                            variant="outlined"
                            onChange={(e)=>{
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        ownerAddress: e.target.value,
                                    },
                                })
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box pl={2} pr={2} pt={2} pb={2}>
                        <FormControl variant="outlined" fullWidth className={this.props.classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.formData.ownerCountry}
                            label="Country"
                            onChange={(e) => {
                            this.setState({
                            formData: {
                            ...this.state.formData,
                            ownerCountry: e.target.value,
                            },
                            });
                            }}
                            >
                            <MenuItem value="">
                            <em>Choose Country</em>
                            </MenuItem>
                            <MenuItem value="INDIA">INDIA</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="UK">UK</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={this.props.classes.paper}>
                    <FormControl component="fieldset" fullWidth style={{textAlign: 'left'}}>
                        <FormLabel component="legend"  >Status</FormLabel>

                        <RadioGroup row aria-label="position"
                        onChange={(e)=>{
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    ownerStatus: e.target.value,
                                },
                            })
                        }}
                        value={Number(this.state.formData.ownerStatus)}
                         name="MCategoryStatus" defaultValue="top">
                            <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="Active"
                            />
                            <FormControlLabel
                            value={0}
                            control={<Radio color="primary" />}
                            label="Inactive"
                            />
                        </RadioGroup>
                    </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} align="center">
                        <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
                        {this.state.action}
                        </Button>
                        <Button variant="contained" color="secondary" style={{margin: "5px"}}
                        onClick={(e)=>{
                            this.ResetStates();
                        }}>
                        Reset
                        </Button>
                        <p>{this.state.message}</p>
                </Grid>

            </Grid>
            </form>
            <TableContainer >
                <Table className={this.props.classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Owner Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.listOwners.map((row, key) => (
                        <TableRow key={row.id}>
                        <TableCell>{key+Number(1)}</TableCell>
                        <TableCell>{row.ownerName}</TableCell>
                        <TableCell>{row.address} - {row.country}</TableCell>
                        <TableCell>{row.isActive}</TableCell>
                        <TableCell>
                            <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                            <Button
                            onClick={(e)=>{
                                this.editOwnerDetails(row.id);
                            }}
                            >
                                <EditIcon />
                            </Button>
                            <Button
                             onClick={(e)=>{
                                this.deleteOwnerDetails(row.id);
                            }}
                            >
                                <DeleteIcon />
                            </Button>
                            </ButtonGroup>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(AddOwner));
