import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import MyBreadCrumbs from 'src/components/Breadcrumbs';
import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import ProductMasterProductForm from './partials/productMasterMap';
import Button from '@mui/material/Button';
const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Add Map Product',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class ManageMasterProduct extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        proId: null,
        brandId: null,
        sizeId: null,
        colourId: null,
        materialId: null,
        fitId: null,
        tagId: []
      },
      orginalData: {},
      inProgress: false,
      productList: {},
      brandList: {},
      sizeList: {},
      colourList: {},
      fitList: {},
      materialList: {},
      tagList: {},
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      isbreadCrumb: props.isbreadCrumb===false ? false : true,
    }
  }

  async componentDidMount() {
    await this.getProductMaterialData();
    await this.getProductItems();
  }

  getProductMaterialData = async () => {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ isLoadingData: true, inProgress: false });
      const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_MAP_EDIT(this.props.match.params.id));
      if (res.data.success) {
        this.setState({
          orginalData: res.data.data,
          formData: { ...res.data.data, tagId: res.data.data.tagId && JSON.parse(res.data.data.tagId).length ? JSON.parse(res.data.data.tagId) : [] },
          isLoadingData: false,
        });
      }
    }
  }

  getProductItems = async () => {
    this.setState({ isLoadingData: true, inProgress: false });
    const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_MAP_ITEMS_LIST);
    if (res.data.success) {
      this.setState({
        productList: res.data.storeProducts || {},
        brandList: res.data.productBrands || {},
        sizeList: res.data.productSizes || {},
        colourList: res.data.productColours || {},
        fitList: res.data.productFits || {},
        materialList:res.data.productMaterials || {},
        tagList: res.data.productTagging || {},
        isLoadingData: false
      });
      // this.setState({  });
    } else {
      window.mNotify.error('Error while fetching items list');
    }
  };

  resetState = () => {
    this.setState({
      formData: {},
    });
  }

  onSave = async (data) => {
    try {
      this.setState({ inProgress: true });
      const { orginalData } = this.state;
      const body = {
        proId: data.proId,
        brandId: data.brandId,
        sizeId: data.sizeId,
        colourId: data.colourId,
        materialId: data.materialId,
        fitId: data.fitId,
        productName: data.productName,
        brandName: data.brandName,
        fitName: data.fitName,
        sizeName: data.sizeName,
        colourName: data.colourName,
        materialName: data.materialName,
        tagId: JSON.stringify(data.tagId),
        ownerPrice: data.ownerPrice,
        barcodeNumber : data.barcodeNumber,
      };
      let res;
      if (orginalData.id) {
        res = await axiosInstance.put(Apiurls.PRODUCT_MASTER_MAP_EDIT(orginalData.id), body);
      } else {
        res = await axiosInstance.post(Apiurls.PRODUCT_MASTER_MAP, body);
      }

      if (res.data.success) {
        window.mNotify.show(orginalData.id ? 'Updated successfully' : 'Created successfully', 'success');
        return this.props.history.push(Urls.ProductMasterProduct);
      }

      if (res.data.isexisting) {
        window.mNotify.show('Master Product already existing', 'danger');
        return this.props.history.push(Urls.ProductMasterProduct);
      }

      throw new Error(res.data.error);
    } catch (error) {
      console.log(error);
      this.setState({ inProgress: false });

      window.mNotify.error('Error while creating product master product. Try again.');
    }
  }

  render() {
    const { history } = this.props;
    const { formData, inProgress, isLoadingData , productList , brandList , sizeList , colourList , fitList , materialList , tagList, isbreadCrumb} = this.state;

    return (
      <div className={this.props.classes.paper}>
        {
          isbreadCrumb ?  <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
          /> : ''
        }

      {
        isbreadCrumb ? <div className={this.props.classes.titleContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(Urls.ProductMasterProduct);
            }}
            style={{ marginRight: '5px' }}
          >
            View Master Products
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push(Urls.Product);
            }}
          >
            View Products
          </Button>
        </div>
      : ''
      }
        <h2>{formData.id ? 'Edit' : 'Add'} Master Product</h2>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <ProductMasterProductForm
              data={formData}
              inProgress={inProgress}
              onSave={this.onSave}
              productList={productList}
              brandList={brandList}
              sizeList={sizeList}
              colourList={colourList}
              fitList={fitList}
              materialList={materialList}
              tagList={tagList}
            />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ManageMasterProduct));
