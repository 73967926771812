import FeatureSidebar from './sidebar';
import TextileUrl from './constants/urls';
import ApiUrls from './constants/apiUrls';

import ListProduct from './listProduct';
import ManageProduct from './manageProduct'
import ListProductBrands from './listProductBrand';
import ManageProductBrand from './manageProductBrand';
import ManageProductSize from './manageProductSize';
import ListProductSize from './listProductSize';
import ManageProductColour from './manageProductColour';
import ListProductColour from './listProductColour';
import ManageProductFit from './manageProductFit';
import ListProductFit from './listProductFit';
import ManageProductMaterial from './manageProductMaterial';
import ListProductMaterial from './listProductMaterial';
import ListTagging from './listTagging';
import ManageTagging from './manageTagging';
import ManageMasterProduct from './manageMasterProduct';
import ListProductMasterMap from './ListProductMasterMap';
import ManageProductDashboard from './manageProductDashboard';
import ManageMasterProductPrice from './manageMasterProductPrice';
import ListMasterProductPrice from './listMasterProductPrice';
import MasterProductImage from './masterProductImage';

const TextileRoutes = [
  {
    url: TextileUrl.ProductDashboard,
    component: ManageProductDashboard,
  },
  {
    url: TextileUrl.Product,
    component: ListProduct,
  },
  {
    url: TextileUrl.ProductAdd,
    component: ManageProduct,
  },
  {
    url: TextileUrl.ProductEdit(':id'),
    component: ManageProduct,
  },
  {
    url: TextileUrl.ProductBrand,
    component: ListProductBrands,
  },
  {
    url: TextileUrl.ProductBrandAdd,
    component: ManageProductBrand,
  },
  {
    url: TextileUrl.ProductBrandEdit(':id'),
    component: ManageProductBrand,
  },
  {
    url: TextileUrl.ProductSize,
    component: ListProductSize,
  },
  {
    url: TextileUrl.ProductSizeAdd,
    component: ManageProductSize,
  },
  {
    url: TextileUrl.ProductSizeEdit(':id'),
    component: ManageProductSize,
  },
  {
    url: TextileUrl.ProductColour,
    component: ListProductColour,
  },
  {
    url: TextileUrl.ProductColourAdd,
    component: ManageProductColour,
  },
  {
    url: TextileUrl.ProductColourEdit(':id'),
    component: ManageProductColour,
  },
  {
    url: TextileUrl.ProductFit,
    component: ListProductFit,
  },
  {
    url: TextileUrl.ProductFitAdd,
    component: ManageProductFit,
  },
  {
    url: TextileUrl.ProductFitEdit(':id'),
    component: ManageProductFit,
  },
  {
    url: TextileUrl.ProductMaterial,
    component: ListProductMaterial,
  },
  {
    url: TextileUrl.ProductMaterialAdd,
    component: ManageProductMaterial,
  },
  {
    url: TextileUrl.ProductMaterialEdit(':id'),
    component: ManageProductMaterial,
  },
  {
    url: TextileUrl.ProductTagging,
    component: ListTagging,
  },
  {
    url: TextileUrl.ProductTaggingAdd,
    component: ManageTagging,
  },
  {
    url: TextileUrl.ProductTaggingEdit(':id'),
    component: ManageTagging,
  },
  {
    url: TextileUrl.ProductMasterProduct,
    component: ListProductMasterMap,
  },
  {
    url: TextileUrl.ProductMasterProductAdd,
    component: ManageMasterProduct,
  },
  {
    url: TextileUrl.ProductMasterProductEdit(':id'),
    component: ManageMasterProduct,
  },
  {
    url: TextileUrl.ProductMasterProductPrice(':masterProductId'),
    component: ListMasterProductPrice,
  },
  {
    url: TextileUrl.ProductMasterProductPriceAdd(':masterProductId'),
    component: ManageMasterProductPrice,
  },
  {
    url: TextileUrl.ProductMasterProductPriceEdit(':masterProductId',':id'),
    component: ManageMasterProductPrice,
  },
  {
    url: TextileUrl.ProductMasterProductImage(':masterProductId'),
    component: MasterProductImage,
  },
]

const App = {
  name: 'textile',
  routers: TextileRoutes,
  apiUrls: ApiUrls,
  menu: FeatureSidebar,
};

export default App;
