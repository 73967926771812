import React from 'react';
import Loader from 'src/components/loader';

import {
  USER_TYPE_OWNER,
  // USER_TYPE_VENDOR,
  // USER_TYPE_ADMIN,
} from 'src/constants/index';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import axiosInstance from 'src/http';

require('./pages/owner/store/select.scss');

function InitFeature(WrappedComponent) {
  class InitFeatures extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        featureStatus: false,
        feature: {
          billing: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },
          employee: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },
          inventory: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },
          deliveryOrder: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },
          accounts: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },
          customer: {
            inProgress: false,
            featuresRoutesDetails: [],
            featuresMenuDetails: [],
          },

        },
      };
    }

    async componentDidMount() {
      const contextState = this.context;
      if (contextState.user.type === USER_TYPE_OWNER) {
        await this.storeFeature();
      } else {
        this.context.setChangeStoreFeatures({
          features: {
            storeFeatures: {},
            storeFeaturesStatus: true,
          },
        });
      }
    }

    storeFeature = async () => {
      const contextState = this.context;
      const res = await axiosInstance.get(Apiurls.STORE_FEATURE_LIST);
      if (res.data.data.length !== 0) {
        this.context.setChangeStoreFeatures({
          features: {
            ...contextState.features,
            storeFeaturesAccess: {
              ...JSON.parse(res.data.data[0].featureSetting),
            },
          },
        });
      }
      this.Feature();
    };

    Feature = () => {
      const contextState = this.context;

      if (Object.keys(contextState.features.storeFeaturesAccess).length) {
        Object.keys(contextState.features.storeFeaturesAccess).map( async (val, key) => {
            if (this.state.feature[val] && this.state.feature[val].featuresRoutesDetails.length === 0 && this.state.feature[val].featuresMenuDetails.length === 0) {
              let featureData = await import('src/features/' + val + '/App')
              if (featureData.default.name === val) {
                this.setState({
                  feature: {
                    ...this.state.feature,
                    [val]: {
                      inProgress: true,
                      featuresRoutesDetails: [...featureData.default.routers],
                      featuresMenuDetails: [...featureData.default.menu],
                    },
                  },
                  featureStatus: true,
                });
                //this.forceUpdate();
              }

              this.context.setChangeStoreFeatures({
                features: {
                  ...contextState.features,
                  storeFeatures: { ...this.state.feature },
                  storeFeaturesStatus: true,
                },
              });
            }
          });
      } else {
        this.context.setChangeStoreFeatures({
          features: {
            ...contextState.features,
            storeFeaturesStatus: true,
          },
        });
      }
    };

    render() {
      const contextState = this.context;
      return !contextState.features.storeFeaturesStatus ? (
        <Loader title="Initializing features" />
      ) : (
        <WrappedComponent {...this.props} state={this.state} />
      );
    }
  }
  InitFeatures.contextType = GlobalContext;
  return InitFeatures;
}

export default InitFeature;
