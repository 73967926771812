import React, { useCallback, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import styles from 'src/styles/productBrandForm';

import HierarchyFields from './hierarchys/hierarchyFields';

import { storeProductMapFormSchema } from "src/formValidation/formValidation";

const ProductMasterMap = ({ data = {}, getSearchRcords, productTypeList, inProgress, onSave, productUnitList, productHierarchyList , errors}) => {

  const classes = styles();
  const [formData, setFormData] = React.useState({ ...data });
  const [validationDataError, validationError] = React.useState({});

    useEffect( () => {
      validationError({ errors });
    }, [errors])

    useEffect(() => {

      if (formData.productType && formData.productTypes && formData.unitId && formData.productUnits) {

        setFormData({
          ...formData,
          productType: formData.productType,
          productTypeName: formData.productTypes.productTypeName,
          unitId: formData.unitId,
          unitName: formData.productUnits.unitName,
        });
      }

      // eslint-disable-next-line
    }, [formData.id]);

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Store Master Product error occurs!');
    }
  };

  const onHierarchySetData = (data) => {
    setFormData({
      ...formData,
      ...data
    });
    return
  }

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      storeProductMapFormSchema.validateSync(formData, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(element => errors[element.path] = element.message);
    formIsValid = false;
  }
  return [formIsValid, errors];
  }, []);


  return (
    <form
      onSubmit={onSubmit}
      className={classes.root}
      disabled={inProgress}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              disabled={!!formData.id}
              label="Product Id"
              error={!!(validationDataError.errors && validationDataError.errors.productId)}
              helperText={validationDataError.errors ? validationDataError.errors.productId : ''}
              value={formData.productId}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productId: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Barcode Number"
              error={!!(validationDataError.errors && validationDataError.errors.barcodeNumber)}
              helperText={validationDataError.errors ? validationDataError.errors.barcodeNumber : ''}
              value={formData.barcodeNumber}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  barcodeNumber: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-productTypeName-search"

              // TO DO NEED TO CHANGE STATE
              onKeyUp={(e) => {
                 setFormData({
                   ...formData,
                   productType: null,
                   productTypeName: e.target.value,
                 });
                getSearchRcords({
                  type: 'productType',
                  search: e.target.value,
                  fields: ["productTypeName"],
                  state: 'productTypeList'
                });
              }}
              value={productTypeList && productTypeList[formData.productType] ? productTypeList[formData.productType].productTypeName  || '' : formData.productTypeName  || ''}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(productTypeList)[e.target.dataset.optionIndex];

                  if (obj && !obj.isActive) {
                      setFormData({
                        ...formData,
                        productType: '',
                        productTypeName: '',
                      })
                      return window.mNotify.error('This Product was blocked!');
                    }

                  setFormData({
                    ...formData,
                    productType: obj.id,
                    productTypeName: obj.productTypeName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    productType: null,
                    productTypeName: '',
                  });
                }

              }}
              options={Object.values(productTypeList).map((option) => option.productTypeName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search Product Type"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.productTypeName)}
                  helperText={validationDataError.errors ? validationDataError.errors.productTypeName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Product Name"
              error={!!(validationDataError.errors && validationDataError.errors.productName)}
              helperText={validationDataError.errors ? validationDataError.errors.productName : ''}
              value={formData.productName}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productName: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Owner Price"
              error={!!(validationDataError.errors && validationDataError.errors.ownerPrice)}
              helperText={validationDataError.errors ? validationDataError.errors.ownerPrice : ''}
              value={formData.ownerPrice}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  ownerPrice: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Moving Average Price"
              error={!!(validationDataError.errors && validationDataError.errors.movingAvgPrice)}
              helperText={validationDataError.errors ? validationDataError.errors.movingAvgPrice : ''}
              value={formData.movingAvgPrice}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  movingAvgPrice: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="HSN Code"
              error={!!(validationDataError.errors && validationDataError.errors.HSNCode)}
              helperText={validationDataError.errors ? validationDataError.errors.HSNCode : ''}
              value={formData.HSNCode}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  HSNCode: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              label="Tax Category"
              error={!!(validationDataError.errors && validationDataError.errors.taxCategory)}
              helperText={validationDataError.errors ? validationDataError.errors.taxCategory : ''}
              value={formData.taxCategory}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  taxCategory: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
            <Autocomplete
              freeSolo
              disableClearable
              id="free-solo-2-productTypeName-search"
              onKeyUp={(e) => {
                setFormData({
                  ...formData,
                  unitId: null,
                  unitName: e.target.value,
                });

                getSearchRcords({
                  type: 'productUnit',
                  search: e.target.value,
                  fields: ["unitName"],
                  state: 'productUnitList'
                });

              }}
              value={formData.unitId && productUnitList[formData.unitId] ? productUnitList[formData.unitId].unitName || '' : formData.unitName || ''}
              onChange={(e, value) => {
                if(e.target.dataset.optionIndex) {
                  let obj = Object.values(productUnitList)[e.target.dataset.optionIndex];

                  if (obj && !obj.isActive) {
                      setFormData({
                        ...formData,
                        unitId: '',
                        unitName: '',
                      })
                      return window.mNotify.error('This Unit was blocked!');
                    }

                  setFormData({
                    ...formData,
                    unitId: obj.id,
                    unitName: obj.unitName,
                  });
                } else {
                  setFormData({
                    ...formData,
                    unitId: null,
                    unitName: '',
                  });
                }
              }}
              options={Object.values(productUnitList).map((option) => option.unitName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search Product Unit"
                  variant="outlined"
                  error={!!(validationDataError.errors && validationDataError.errors.unitName)}
                  helperText={validationDataError.errors ? validationDataError.errors.unitName : ''}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
          <TextField
              id="outlined-basic"
              fullWidth
              rows={2}
              multiline
              label="Product Description"
              error={!!(validationDataError.errors && validationDataError.errors.productDescription)}
              helperText={validationDataError.errors ? validationDataError.errors.productDescription : ''}
              value={formData.productDescription}
              variant="outlined"
              onChange={(e)=>{
                setFormData({
                  ...formData,
                  productDescription: e.target.value,
                });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <HierarchyFields productHierarchyList={productHierarchyList} data={formData} onHierarchySetData={onHierarchySetData} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.paper}>
              <FormControl component="fieldset" fullWidth style={{ textAlign: 'left' }}>
                  <FormLabel component="legend"  >Status</FormLabel>
                  <RadioGroup row aria-label="position"
                      onChange={(e) => {
                          setFormData({
                              ...formData,
                              isActive: Number(e.target.value),
                          });
                      }}
                      value={formData.isActive}
                      name="isActive" defaultValue="top">
                      <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Active"
                      />
                      <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="Inactive"
                      />
                  </RadioGroup>
                </FormControl>
            </div>
        </Grid>

        <Grid item xs={12} sm={12} align="center">
          <Button variant="contained" type="submit" color="primary" style={{margin: "5px"}}>
            {inProgress && <CircularProgress size={12} />}
            {data.id ? 'Update' : 'Create'}
          </Button>
          {/* <Button */}
          {/*   variant="contained" */}
          {/*   color="secondary" */}
          {/*   style={{ margin: '5px' }} */}
          {/*   onClick={(e) => { */}
          {/*     setFormData({ ...data }); */}
          {/*   }} */}
          {/* > */}
          {/*   Reset */}
          {/* </Button> */}

        </Grid>

      </Grid>
    </form>
  );
}

export default ProductMasterMap;
