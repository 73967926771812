import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';

import LanguageSelector from 'src/components/languageSelector';
import Urls from 'src/constants/urls';

import ForgotPassword from './forgotPassword';
import Login from './login';
import Register from './register';

require('./authentication.scss');

// All backround available for authentication. Select one for a instance
const allBgs = ['bg-1.jpg', 'bg-2.jpg', 'bg-3.jpg', 'bg-4.jpg'];
const idx = Math.floor((Math.random() * 10) % allBgs.length);
const currentBG = allBgs[idx];

/**
 * Layout for authenticaion related routes.
 * @param {Object} props
 * @returns {JSX.Element}
 */
const AuthenticationLayout = ({ children }) => {
  const { t, i18n } = useTranslation();

  let redirectTo = '';
  if (!window.location.pathname.startsWith('auth')) {
    redirectTo = window.location.pathname;
  }

  return (
    <Grid container className="authentication-container">
      <Hidden lgDown>
      <Grid item xs={false} md={7} className="authentication-left">
        <img loading="lazy" src={`/images/${currentBG}`} alt="Background" />
      </Grid>
      </Hidden>
      <Grid item xs={12} md={5} className="authentication-right">
        <div className="title-container">
          <div className="title-logo">
            mPower.
          </div>
          <LanguageSelector
            t={t}
            i18n={i18n}
          />
        </div>
        {
          children ? children :
          <Switch>
            <Route path={Urls.Register} component={Register} />
            <Route path={Urls.Login} component={Login} />
            <Route path={Urls.ForgotPassword} component={ForgotPassword} />
            <Redirect to={`${Urls.Login}${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} />
          </Switch>
        }
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
