import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';

import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

import ManageUploadFiles from 'src/uploadsFile/manageUploadFiles';

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: 'Master Products',
    url: Urls.ProductMasterProduct,
  },
  {
    title: 'Product Images',
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

class MasterProductImage extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      orginalData: {},
      isLoadingData: !!(this.props.match && this.props.match.params.id),
      masterProductImage: {},
      productData: {},
    };
  }

  async componentDidMount() {
    await this.fetchMasterProductImage();
  }

  fetchMasterProductImage = async () => {
    try {
      this.setState({
        isLoadingData: true,
      });

      const res = await axiosInstance.get(Apiurls.PRODUCT_MASTER_PRODUCT_IMAGE_LIST(this.props.match.params.masterProductId));
      if (res.data.success) {
        this.setState({
          isLoadingData: false,
          masterProductImage: res.data.data,
          productData: res.data.productData,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error('Error while fetching master products');
    }

    this.setState({
      isLoadingData: false,
    });
   }

  onUpdate = async (imageById) => {
    try {
      let data = {
        selectedImage: imageById,
        masterProductId: this.props.match.params.masterProductId
      }
      const res = await axiosInstance.post(Apiurls.MASTER_PRODUCT_IMAGE_MAP, data);

      if (res.data.success) {
        await this.fetchMasterProductImage();
        return res
      }
    } catch (e) {
      await this.fetchMasterProductImage();
      console.error(e)
    }
  }


  render() {
    const { isLoadingData , masterProductImage, productData } = this.state;
    const { history } = this.props;
    return (
      <div className={this.props.classes.paper}>
       <MyBreadCrumbs
          items={breadcrumbItems}
          history={history}
        / >
        <h2>Product Image </h2>
        <h3>{productData.productName} - {productData.brandName} - {productData.fitName}</h3>
        {
          isLoadingData ?
            <CircularProgress color="inherit" size={14} />
            :
            <>
              <ManageUploadFiles
                relatedImage={masterProductImage}
                onUpdate={this.onUpdate}
              />
            </>
        }
       </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(MasterProductImage));
