import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import Urls from './constants/urls';
import axiosInstance from 'src/http';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from './constants/apiUrls';
import MyBreadCrumbs from 'src/components/Breadcrumbs';

// page Title
const pageTitle = 'Product Type'

const breadcrumbItems = [
  {
    url: Urls.Dashboard,
    title: 'Dashboard',
  },
  {
    title: 'Product Dashboard',
    url: Urls.ProductDashboard,
  },
  {
    title: pageTitle,
  },
];

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: `1rem 0`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  h2: {
    margin: 0,
    marginBottom: '1rem',
  }
});



class ListProduct extends Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      products: [],
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList = async (ownerId) => {
    try {
      this.setState({
        inProgress: true,
      });
      const res = await axiosInstance.get(Apiurls.PRODUCT_TYPE_LIST);
      if (res.data.success) {
        this.setState({
          inProgress: false,
          products: res.data.data,
        });
        return;
      }
      throw new Error(res.data.message);
    } catch (ex) {
      console.error(ex);
      window.mNotify.error(`Error while fetching ${pageTitle}`);
    }

    this.setState({
      inProgress: false,
    });
  }

  deleteandRestoreItem = async (data) => {

    let res;
    let status;
    if (data.isActive) {
      res = await axiosInstance.patch(Apiurls.PRODUCT_TYPE_EDIT(data.id));
      status = "Restore"
    } else {
      res = await axiosInstance.delete(Apiurls.PRODUCT_TYPE_EDIT(data.id));
      status = "Delete"
    }

    if (res.data.success) {
      this.fetchList();
      window.mNotify.show(`${status} the ${pageTitle} successfully.`);
    } else {
      window.mNotify.show(`Sorry!.. Could not ${status} the ${pageTitle}`);
    }
  }

  render() {
    const { inProgress, products } = this.state;
    const { history } = this.props;

    return (
      <div className={this.props.classes.paper}>
        <div className={this.props.classes.pageHead}>
          <MyBreadCrumbs
            items={breadcrumbItems}
            history={history}
          />
          <div className={this.props.classes.titleContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(Urls.ProductTypeAdd);
              }}
            >
              Create New
            </Button>
          </div>
        </div>
        <Paper className={this.props.classes.paper}>
          <h2 className={this.props.classes.h2}>{pageTitle}</h2>
          <TableContainer >
            <Table>
              <TableHead>
                  <TableRow>
                    <TableCell>{pageTitle} Name</TableCell>
                    <TableCell>{pageTitle} Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  products.map((row, key) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <CheckIcon
                          size={12}
                          style={{
                            display: 'inline-block',
                            color: row.isActive ? 'green' : 'grey',
                          }}
                        />
                        <span>{row.productTypeName}</span>
                      </TableCell>
                      <TableCell>
                        {row.productTypeDescription}
                      </TableCell>
                      <TableCell>

                        {row.isActive ?
                          <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                            <Button
                              onClick={(e)=>{
                                history.push(Urls.ProductTypeEdit(row.id));
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Tooltip title="Block">
                            <Button
                            onClick={(e)=>{
                              if (window.confirm(`Are you sure you want to delete ${row.productTypeName} ?`)) {
                                this.deleteandRestoreItem({
                                  id: row.id,
                                  isActive: false,
                                });
                              }
                            }}
                            >
                            <BlockIcon />
                            </Button>
                            </Tooltip>
                          </ButtonGroup>
                          :
                           <ButtonGroup disableElevation size="small" variant="outlined" color="secondary">
                            <Button
                              onClick={(e)=>{
                                if (window.confirm(`Are you sure you want to Restore ${row.productTypeName} ?`)) {
                                  this.deleteandRestoreItem({
                                    id: row.id,
                                    isActive: true,
                                  });
                                }
                              }}
                            >
                              <RestoreIcon />
                            </Button>
                          </ButtonGroup>
                        }

                      </TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
            </Table>
            {
              inProgress && <CircularProgress />
            }
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ListProduct));
