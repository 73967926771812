import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import NotiStackWrapper from './notistackWrapper';

import App from './App';
import GlobalContextProvider from './GlobalContextProvider';
import Loader from 'src/components/loader';
import theme from './theme';

require('./i18n');

/**
 * Root component
 *
 * Loads the below
 *  - Material UI Theme
 *  - Material CSSBaseLine
 *  - Global Context Provider - Holds the app wide state in a context
 *    - BrowserRouter
 *    - App - Entry point for our app
 *  - NotiStackWrapper - For notifications
 */
const component = (
      <ThemeProviderV5 theme={theme}>
      <CssBaseline />
      <GlobalContextProvider>
        <BrowserRouter>
          <React.Suspense fallback={<Loader title="Loading..!" />}>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </GlobalContextProvider>
      <NotiStackWrapper />
    </ThemeProviderV5>
);

ReactDOM.render(component, document.getElementById('root'));
