import React, { useState, useEffect } from 'react';
// import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';


import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';

import styles from 'src/styles/negotiationBox';

const NegotiationAnswerSection = ({QuestionList, Data, onSave, handleClose, negotiationId}) => {
  const classes = styles();

  const [inProgress, setInProgress] = useState(true);
  const [VendorProductBrands, setVendorProductBrands] = useState({});
  const [formData, setFormData] = React.useState(Data);


  useEffect(() => {
    fetchVendorProductBrands();
    // eslint-disable-next-line
  }, [setInProgress]);

  const fetchVendorProductBrands = async () => {
      setInProgress(true);
      const res = await axiosInstance.post(Apiurls.STORE_NEGOTIATION_BRAND, {
        itemId: negotiationId
      });
      // const data = res.data;
      if (res.data.success) {
        setVendorProductBrands(res.data)
        setInProgress(false);
      }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if(formData.questionId && formData.answer)
    {
      onSave({
        ...formData,
        itemId: negotiationId
      });
    }
    else{
      window.mNotify.error('Please fill out this field');
    }
  };

  const NegotiationAnswer= () => {

    return (
      <>
        <form  onSubmit={onSubmit} className={classes.root}>
          <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
        { formData.questionId !== 2 ?<TextField
          id="datetime-local"
          // label="Next appointment"
          type={QuestionList.data[formData.questionId].dataType}
          label={QuestionList.data[formData.questionId].question} variant="outlined"
          className={classes.textField}
          value={formData.answer}
          onChange={(e)=>{
            setFormData({
              ...formData,
              answer: e.target.value,
            })
          }}

          InputLabelProps={{
            shrink: true,
          }}
        /> : <>{
          !inProgress ? <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Choose Brands</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.answer}
          onChange={(e)=>{
            setFormData({
              ...formData,
              answer: e.target.value,
            })
          }}
          label="Choose Brands"
        >
          <MenuItem value="">
            <em>Choose Brands</em>
          </MenuItem>
          {
            Object.keys(VendorProductBrands.data).map((vals, keys) => (
              <MenuItem value={VendorProductBrands.data[vals].brandId} key={vals}>{VendorProductBrands.data[vals].title}</MenuItem>
            ))
          }

        </Select>
      </FormControl> : <div className={classes.root}>  <CircularProgress />  </div>
        }
        </>
      }
        <Button type="submit" className={classes.saveButton} color="primary"> Save </Button>
            <Button onClick={handleClose} className={classes.saveButton} color="primary">
              Cancel
            </Button>
          </Grid>
        </Grid>
          </form>
      </>
    )
  };

  return (
    <>
      <NegotiationAnswer />
    </>
  );
}

export default NegotiationAnswerSection;
