import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import LoadingIndicator from './indicator';

require('./index.scss');

const Loader = ({ title }) => {
    return (
      <Grid className="loader-page-container">
        {title && <Typography color="primary" variant="h5">{title}</Typography>}
        <LoadingIndicator size={25} className="loader-page-progress" />
      </Grid>
    );
  }

export default Loader;
