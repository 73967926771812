import React, { useState } from 'react';
// import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';


import axiosInstance from 'src/http';
// import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import History from './history';

import styles from 'src/styles/negotiationBox';

const NegotiationHistroy = ({negotiationHistory, negotiationId, negotiationQuestionList, fetchNegotiationHistory}) => {
  const classes = styles();
  // const contextType = useContext(GlobalContext);
  const [QuestionId, setQuestionId] = useState();


  // const [inProgress, setInProgress] = useState(true);


  const negotiationResponse = async (data) => {
    // setInProgress(true);
    const res = await axiosInstance.post(Apiurls.NEGOTIATION_RESPONSE(data.hisId), { status:data.status });

    if (res.data.success) {
        window.mNotify.error('Your Negotiation has been Accepted');
        // setInProgress(false);
        fetchNegotiationHistory()
      }
      else{
        window.mNotify.error('Your Negotiation has been Not Accepted');
      }
  }

    const NegoQuestList = () => {
     return (
      <>
        {
          negotiationQuestionList.success ? <div className={classes.root}>
          <Grid container spacing={3}>
            {
              Object.keys(negotiationHistory.data).map((vals, keys) => (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Button
                    className={ QuestionId === negotiationQuestionList.data[vals].id ? classes.Active : classes.Grid }
                    onClick={(e)=>{
                    setQuestionId(negotiationQuestionList.data[vals].id)
                    }}>{negotiationQuestionList.data[vals].question}</Button>
                </Grid>
                )
              )
            }
          </Grid>
        </div> : null
      }
      </>
    )
  };

  return (
      <>
        <NegoQuestList />
        {QuestionId?<History QuestionId={QuestionId} negotiationId={negotiationId} negotiationHistory={negotiationHistory.data[QuestionId]} negotiationQuestionList={negotiationQuestionList.data} negotiationResponse={negotiationResponse} fetchNegotiationHistory={fetchNegotiationHistory}/>:null}
      </>
  );
}

export default NegotiationHistroy;
