import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { userFormSchema } from '../validation/formValidation';
import LoadingIndicator from 'src/components/loader/indicator';

import styles from 'src/styles/vendorForm';

const UserForm = ({
  data = {},
  errorMessage,
  inProgress,
  onSave,
}) => {
  const classes = styles();
  const [formData, setFormData] = React.useState(data);
  const [validationDataError, validationError] = React.useState({});

  const validateForm = useCallback((formData) => {
    let errors = {};
    let formIsValid = true;
    try {
      userFormSchema.validateSync(formData, {
        abortEarly: false,
      });
    } catch (err) {
      err.inner.forEach(element => errors[element.path] = element.message);
      formIsValid = false;
    }
    return [formIsValid, errors];
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm(formData);
    validationError({ errors });
    if (isValid) {
      onSave({
        ...data,
        ...formData
      });
    } else {
      window.mNotify.error('Validation error occurs while updating owner!');
    }
  };

  return (
    <>
      <Typography variant="h5">Profile</Typography>
      <br/>
      <form
        onSubmit={onSubmit}
        className={classes.root}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
              <TextField
                error={!!(validationDataError.errors && validationDataError.errors.firstname)}
                helperText={validationDataError.errors ? validationDataError.errors.firstname : ''}
                fullWidth
                label="First Name"
                value={formData.firstname}
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    firstname: e.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
              <TextField
                fullWidth
                label="Last Name"
                error={!!(validationDataError.errors && validationDataError.errors.lastname)}
                helperText={validationDataError.errors ? validationDataError.errors.lastname : ''}
                value={formData.lastname}
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lastname: e.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
              <TextField
                error={!!(validationDataError.errors && validationDataError.errors.email)}
                helperText={validationDataError.errors ? validationDataError.errors.email : ''}
                fullWidth
                label="Email"
                value={formData.email}
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>
              <TextField
                error={!!(validationDataError.errors && validationDataError.errors.phone)}
                helperText={validationDataError.errors ? validationDataError.errors.phone : ''}
                fullWidth
                label="Phone"
                value={formData.phone}
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phone: e.target.value,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" disabled={!!inProgress} type="submit" color="primary" style={{ margin: "5px" }}>
              {inProgress && <LoadingIndicator />}
              {'Update'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={(e) => {
                setFormData({ ...data });
              }}
            >
              Reset
            </Button>
            <p>{errorMessage}</p>
          </Grid>

        </Grid>
      </form>
    </>
  );
}
export default UserForm;
