import React from 'react';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import styles from '../styles/productDashboard';
import Urls from '../constants/urls';

const ProductDashboard = ({ history }) => {

  const classes = styles();

  return (

    <Grid container spacing={0.5}>
        <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Master Product"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMasterProductAdd);
              }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
              onClick={(e) => {
                history.push(Urls.ProductMasterProduct);
              }}
              >
                View
              </Button>

            </CardActions>
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Product Type"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductTypeAdd);
                }}
              >
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductType);
                }}>
                View
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Product Unit"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductUnitAdd);
                }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductUnit);
                }}>
                View
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardHeader
                title="Master Hierarchy"
                subheader=""
              />
            </CardActionArea>
            <CardActions style={{justifyContent: 'center'}}>
              <Button variant="contained" size="small" color="primary" endIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductHierarchyAdd);
                }}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" size="small" endIcon={<VisibilityIcon />}
                onClick={(e) => {
                  history.push(Urls.ProductHierarchy);
                }}>
                View
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>

    </Grid>
  );
}

export default ProductDashboard;
