import React from 'react';
import AuthenticationLayout from './layout';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { GlobalContext } from 'src/GlobalContextProvider';
import Urls from 'src/constants/urls';
import LoadingIndicator from 'src/components/loader/indicator';

function Logout() {
  const { t } = useTranslation();
  const history = useHistory();
  const context = React.useContext(GlobalContext);

  React.useEffect(() => {

    setTimeout(() => {
      context.logout();
      history.push(Urls.Login);
      // window.location = Urls.Login;
    }, 3000);
  }, []); // eslint-disable-line

  return (
    <AuthenticationLayout>
      <div className="logout-container">
        {t('pleaseWait')},{t('whileWeLogout')}&nbsp;&nbsp;
        <LoadingIndicator />
      </div>
    </AuthenticationLayout>
  );
}

export default Logout;
