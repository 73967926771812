import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  formControl: {
    textAlign: 'left',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  danger: {
    color: '#fff',
    backgroundColor: '#c82333',
    borderColor: '#bd2130',
    "&:hover": {
      color: '#fff',
      backgroundColor: '#bd2130',
      borderColor: '#c82333',
    },
  },
  selected: {
    width: '100%',
    border: '2px solid #2037b5',
    boxShadow: '6px 5px 12px -2px #535252',
    padding: '5px',
    cursor: "pointer"
  },
  success: {
    color: '#fff',
    backgroundColor: '#1ca250',
    borderColor: '#1ca250',
    "&:hover": {
      color: '#fff',
      backgroundColor: '#158641',
      borderColor: '#158641',
    },
  },
  images: {
    width: '100%',
    cursor: "pointer",
    "&:hover": {
      border: '2px solid #2037b5',
      boxShadow: '6px 5px 12px -2px #535252',
      padding: '5px',
    }
  }
}));

export default styles;
