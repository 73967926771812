import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

//UI Language
import { withTranslation } from 'react-i18next';
import { USER_TYPE_OWNER, USER_TYPE_VENDOR } from 'src/constants';
import { GlobalContext } from 'src/GlobalContextProvider';
import Apiurls from 'src/constants/apiUrls';
import axiosInstance from 'src/http';

import Urls from 'src/constants/urls';

import { loginFormSchema } from 'src/formValidation/formValidation';
import LoadingIndicator from 'src/components/loader/indicator';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      userCredentials: false,
      formErrors: {},
      formData: {
        username: '',
        password: '',
        userType: 'owner',
      },
    };
  }

  onValidateForm = (formData) => {
    let errors = {};
    let formIsValid = true;

    try {
      loginFormSchema.validateSync(formData, {
        abortEarly: false,
      });
    } catch (err) {
      err.inner.forEach(element => errors[element.path] = element.message);
      formIsValid = false;
    }
    return [formIsValid, errors];
  };

  onSubmit = async (e, contextState) => {
    e.preventDefault();

    // is submit already in progress..?? Then get out
    if (this.state.inProgress) {
      return;
    }

    try {

      const [isValid, errors] = await this.onValidateForm( this.state.formData );

      // Form Schema error set in state
      this.setState({ formErrors: errors });

      if (isValid) {
        this.setState({ inProgress: true , userCredentials : false });
        const res = await axiosInstance.post(Apiurls.Login, this.state.formData);
        if (res.data.success) {
          window.mNotify.show('Login Success', { variant: 'success' });
          contextState.loginUser(res.data);
        } else {
          if (!res.data.success && res.data.errors) {
            this.setState({ formErrors: res.data.errors , inProgress: false , userCredentials: true });
            window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
            return;
          }
          window.mNotify.show('Invalid username/password combination.', { variant: 'error' });
          this.setState({ inProgress: false, userCredentials: true });
        }
      } else {
        window.mNotify.show(this.props.t('fixFormErrors'), { variant: 'error' });
        this.setState({ inProgress: false });
      }
    } catch (ex) {
      console.error(ex);
      this.setState({ inProgress: false });
      window.mNotify.show('Something went wrong. Please try again', { variant: 'error' });
    }
  };

  render() {
    const { t } = this.props;
    const { inProgress, formErrors , userCredentials } = this.state;

    return (
      <Grid className="login-container">
        <Typography variant="h6">{t('loginForm')} 🎉</Typography>
        <GlobalContext.Consumer>
          {(contextState) => (
            <form
              onSubmit={(e) => this.onSubmit(e, contextState)}
              className="login-form"
            >
              <FormControl component="fieldset" className="user-type">
                <FormLabel component="legend">{t('iam')}</FormLabel>
                <RadioGroup
                  aria-label="user type"
                  name="login-selection"
                  value={this.state.formData.userType}
                  onChange={(e) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        userType: e.target.value,
                      },
                    });
                  }}
                >
                  <FormControlLabel value={USER_TYPE_OWNER} control={<Radio />} label={t('owner')} />
                  <FormControlLabel value={USER_TYPE_VENDOR} control={<Radio />} label={t('vendor')} />
                  {/* <FormControlLabel value={USER_TYPE_OLD_VENDOR} control={<Radio />} label={t('oldvendor')} /> */}
                </RadioGroup>
              </FormControl>
              <TextField
                error={
                  !!(userCredentials || (formErrors && formErrors.username))
                }
                helperText={formErrors && formErrors.username ? t(formErrors.username) : ''}
                type="text"
                onChange={(e) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      username: e.target.value,
                    },
                  });
                }}
                fullWidth
                value={this.state.username}
                id="email"
                name="username"
                autoComplete="email"
                label={t('emailOrPhone')}
              />
              <TextField
                type="password"
                fullWidth
                id="password"
                name="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={(e) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      password: e.target.value,
                    },
                  });
                }}
                label={t('password')}
                error={
                  !!(userCredentials || (formErrors && formErrors.password))
                }
                helperText={formErrors && formErrors.password ? t(formErrors.password) : ''}
              />
              <div className="actions-row">
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                  disabled={inProgress}
                  endIcon={inProgress && <LoadingIndicator />}
                >
                  {t('login')}
                </Button>
                <Link component={RouterLink} to={Urls.ForgotPassword} underline="hover">{t('forgotPassword')} ?</Link>
              </div>
              <div className="register-link">{t('dontHaveAnAccount')} ? <Link component={RouterLink} to={Urls.Register} underline="hover">{t('register')}</Link></div>
            </form>
          )}


          </GlobalContext.Consumer>
      </Grid>
    );
  }
}

export default withTranslation()(Login);
